import Checkbox from '@components/base/checkbox/checkbox';
import {
  DataGrid,
  DeletePrompt,
  DocumentRow,
  ListPlaceholder,
  TableSkeleton
} from '@components/common';
import { useContext, useEffect, useMemo, useState } from 'react';
import { CellProps } from 'react-table';
import css from './index.module.scss';
import { Button, ToolTip, Typography } from '@components/base';
import {
  getCommaSeperatedCurrencyAmount,
  getFormattedDate,
  getPermissionFromLocalStorage
} from '@helpers/utils';
import { deleteRequestedPayment, paymentAlert } from '@services/finance.service';
import notify from '@helpers/toastify-helper';
import { AppContext } from '@helpers/hooks/AppContext';
import { useNavigate } from 'react-router-dom';
import IconNode from '@components/base/icon-node';
import Images from '@assets/images';
import { PAYMENT_ORDER_BY, PaymentsTabStateProps } from '..';
import { CURRENCY } from '@helpers/constants';
import RequestPayment from '@pages/finance/request-payment';

const PAYMENT_ACTION_TYPE = {
  SINGLE: 'single',
  MULTIPLE: 'multiple',
  ALL: 'all'
};

interface IPaginationState {
  isLoading: boolean;
  requestPaymentModalOpen: boolean;
  currentStatus: number;
  requestPaymentEditData: any;
  deleteDisclaimer: boolean;
  deletePaymentId: string;
}

const PaymentRequested = (props: any) => {
  const { data, dataLoading, setPaymentsTabState, fetchPaymentTableData, handleChipClick } = props;
  const [selectedPayments, setSelectedPayments] = useState<any[]>([]);
  const [paginationState, setPaginationState] = useState<IPaginationState>({
    isLoading: false,
    requestPaymentModalOpen: false,
    currentStatus: 0,
    requestPaymentEditData: null,
    deleteDisclaimer: false,
    deletePaymentId: ''
  });

  const { appDispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const {
    isLoading,
    currentStatus,
    requestPaymentModalOpen,
    requestPaymentEditData,
    deleteDisclaimer,
    deletePaymentId
  } = paginationState;

  const is_finance_team_member = useMemo(
    () => getPermissionFromLocalStorage()?.is_finance_team_member,
    []
  );

  const isAllCurrenciesSame = useMemo(() => {
    if (!data?.length) return false;
    const firstCurrency = data[0].currency;
    return data.every((item: any) => item.currency === firstCurrency);
  }, [data]);

  const sortDocument = (status: number) => {
    switch (status) {
      case 0:
        setPaymentsTabState((prev: PaymentsTabStateProps) => ({
          ...prev,
          payment_order_by: null,
          currentPageNumber: 1
        }));
        break;
      case 1:
        setPaymentsTabState((prev: PaymentsTabStateProps) => ({
          ...prev,
          payment_order_by: PAYMENT_ORDER_BY.ascDueDate,
          currentPageNumber: 1
        }));
        break;
      case 2:
        setPaymentsTabState((prev: PaymentsTabStateProps) => ({
          ...prev,
          payment_order_by: PAYMENT_ORDER_BY.desDueDate,
          currentPageNumber: 1
        }));
        break;
      default:
        setPaymentsTabState((prev: PaymentsTabStateProps) => ({
          ...prev,
          payment_order_by: null,
          currentPageNumber: 1
        }));
    }

    setPaginationState((prevState: IPaginationState) => ({
      ...prevState,
      currentStatus: status
    }));
  };

  const onPaymentSelection = (e: any, value: any) => {
    setSelectedPayments((prevSelectedPayments) => {
      if (e.target.checked) {
        const isAlreadySelected = prevSelectedPayments.some((payment) => payment.id === value.id);

        if (isAlreadySelected) {
          return prevSelectedPayments;
        } else {
          return [...prevSelectedPayments, value];
        }
      } else {
        return prevSelectedPayments.filter((item) => item.id !== value.id);
      }
    });
  };
  const deletePayment = async (payment_info_id: string) => {
    const response = await deleteRequestedPayment(payment_info_id);
    if (response?.success) {
      notify({
        message: 'Payment deleted successfully',
        severity: 'success'
      });
      fetchPaymentTableData();
    } else {
      notify({
        severity: 'error',
        message: response?.error ?? 'Something went wrong'
      });
    }
  };

  const handleDelete = (payment_info_id: string) => {
    setPaginationState((prev: IPaginationState) => ({
      ...prev,
      deleteDisclaimer: true,
      deletePaymentId: payment_info_id
    }));
  };

  const [columnData, rowData] = useMemo(() => {
    const column: Array<any> = [
      {
        Header: '',
        accessor: 'check_box',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          return (
            <Checkbox
              checked={selectedPayments.some((payment) => payment.id === value.id)}
              disabled={
                !is_finance_team_member ||
                (selectedPayments.length > 0 && selectedPayments[0].currency !== value.currency)
              }
              onChange={(e) => onPaymentSelection(e, value)}
            />
          );
        }
      },
      {
        Header: 'Payment ID',
        accessor: 'payment_id_display'
      },
      {
        Header: 'Order ID',
        accessor: 'readable_order_id'
      },
      {
        Header: 'Supplier',
        accessor: 'supplier'
      },
      {
        Header: 'Request Amount',
        accessor: 'total_invoice_payable'
      },
      {
        Header: 'Gross Amount Payable',
        accessor: 'total_invoice_amount'
      },
      {
        Header: () => (
          <div className={css.sortTableHeader}>
            <ToolTip
              title={
                <div>
                  <div>Invoice Number</div>
                  <div>Invoice Date</div>
                </div>
              }>
              <div>
                <IconNode src={Images.alertGrey} alt="eye icon" />
              </div>
            </ToolTip>
            <span>Invoice Details</span>
          </div>
        ),
        // Header: 'Invoice Details',
        accessor: 'invoice',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          return (
            <>
              <Typography variant="p">{value?.invoice_number}</Typography>
              <Typography variant="p">{getFormattedDate(value?.invoice_date ?? '')}</Typography>
            </>
          );
        }
      },
      {
        Header: () => (
          <div
            className={css.sortTableHeader}
            onClick={() => {
              const newStatus = currentStatus === 0 ? 1 : currentStatus === 1 ? 2 : 1;
              sortDocument(newStatus);
            }}>
            <span>Due Date</span>
            <IconNode src={Images.sort} alt="document" width={16} height={16} />
          </div>
        ),
        accessor: 'due_date'
      },
      {
        Header: 'Requested By',
        accessor: 'requested_by'
      },
      {
        Header: '',
        accessor: 'action',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          return (
            <span className={css.buttonWrapper}>
              <ToolTip
                title={value?.requester_remarks ?? 'Requester Remarks not available'}
                placement="left">
                <div>
                  <IconNode src={Images.infoGrey} className={css.infoIcon} alt="info icon" />
                </div>
              </ToolTip>
              <Button
                variant="text"
                onClick={() => actionButtonHandler(PAYMENT_ACTION_TYPE.SINGLE, value?.id)}>
                {is_finance_team_member ? 'Make Payment' : 'Send Alert'}
              </Button>
            </span>
          );
        }
      },
      {
        Header: '',
        accessor: 'view',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          const { is_editable } = value;
          const editData = {
            invoice_info_id: value.invoice_info,
            amount_requested: value.amount_to_pay,
            due_date: value.due_date,
            requester_remarks: value.requester_remarks,
            invoice_number: value.invoice_number,
            payment_id: value.id
          };
          return (
            <div className={css.viewIconWrapper}>
              <DocumentRow.View
                showEyeIconWhenDocumentMissing={true}
                document={value.invoice_document}
                title=""
                openViaSlug
              />
              <IconNode
                src={is_editable ? Images.editRed : Images.editGrey}
                className={css.infoIcon}
                alt="Edit icon"
                onClick={() => {
                  is_editable
                    ? setPaginationState((prev: IPaginationState) => ({
                        ...prev,
                        requestPaymentModalOpen: true,
                        requestPaymentEditData: editData
                      }))
                    : null;
                }}
              />
              <IconNode
                src={is_editable ? Images.deleteRed : Images.delete}
                className={css.infoIcon}
                alt="Delete icon"
                onClick={() => (is_editable ? handleDelete(value.id) : null)}
              />
            </div>
          );
        }
      }
    ];

    const row: Array<any> = data?.length
      ? data?.map((item: any) => {
          const formattedRequestedAmount = getCommaSeperatedCurrencyAmount(
            item.currency,
            item?.amount_to_pay
          );
          const formattedGrossAmount = getCommaSeperatedCurrencyAmount(
            item.currency,
            item?.total_payable_amount
          );

          return {
            check_box: item,
            readable_order_id: item?.order_readable_ids?.length
              ? item?.order_readable_ids?.join(', ')
              : item?.order_readable_ids ?? '-',
            supplier: item?.supplier_name,
            total_invoice_amount: formattedGrossAmount,
            total_invoice_payable: formattedRequestedAmount,
            invoice: item ?? null,
            due_date: getFormattedDate(item?.due_date) ?? '-',
            action: item ?? null,
            payment_id_display: item?.payment_id_display,
            view: item ?? null,
            requested_by: item?.requested_by
          };
        })
      : [];

    return [column, row];
  }, [data, selectedPayments]);

  const actionButtonHandler = (
    paymentActionType = PAYMENT_ACTION_TYPE.SINGLE,
    selectedPayment?: string
  ) => {
    if (is_finance_team_member) {
      let payment_id;
      switch (paymentActionType) {
        case PAYMENT_ACTION_TYPE.SINGLE:
          payment_id = [selectedPayment];
          break;
        case PAYMENT_ACTION_TYPE.MULTIPLE:
          payment_id = selectedPayments?.map((payment) => payment.id);
          break;
        default:
          payment_id = data.map((payment: any) => payment.id);
      }
      appDispatch({
        type: 'finance',
        payload: {
          data: {
            payment_id: payment_id
          }
        }
      });
      navigate('/finance/make-payment');
    } else {
      sendPaymentAlert(selectedPayment ?? null);
    }
  };

  const sendPaymentAlert = async (id: string | null) => {
    setPaginationState((prev: IPaginationState) => ({ ...prev, isLoading: true }));
    const response = await paymentAlert(id);
    setPaginationState((prev: IPaginationState) => ({ ...prev, isLoading: false }));
    if (response?.success) {
      notify({
        message: 'Alert added successfully'
      });
    } else {
      notify({
        severity: 'error',
        message: response?.error ?? 'Something went wrong'
      });
    }
  };

  return (
    <div className={css.tableWrapper}>
      {dataLoading || isLoading ? (
        <TableSkeleton colsNum={6} rowsNum={10} />
      ) : rowData.length ? (
        <>
          {is_finance_team_member && (
            <>
              {selectedPayments?.length > 0 ? (
                <Button
                  variant="text"
                  onClick={() => actionButtonHandler(PAYMENT_ACTION_TYPE.MULTIPLE)}>
                  {is_finance_team_member ? `Make Payment` : `Send Alert`} for{' '}
                  {selectedPayments?.length}{' '}
                  {selectedPayments?.length == 1 ? `Payment` : `Payments`}
                </Button>
              ) : (
                <Button
                  variant="text"
                  disabled={!isAllCurrenciesSame}
                  onClick={() => actionButtonHandler(PAYMENT_ACTION_TYPE.ALL)}>
                  {is_finance_team_member ? `Make Payment` : `Send Alert`} for all Payments
                </Button>
              )}
            </>
          )}
          {requestPaymentModalOpen && (
            <RequestPayment
              open={requestPaymentModalOpen}
              handleFetchPaymentData={fetchPaymentTableData}
              handlePaymentChipClick={handleChipClick}
              onClose={() => {
                setPaginationState((prev: IPaginationState) => ({
                  ...prev,
                  requestPaymentModalOpen: false
                }));
              }}
              editData={requestPaymentEditData}
            />
          )}
          {deleteDisclaimer && (
            <DeletePrompt
              open={deleteDisclaimer}
              onClose={() =>
                setPaginationState((prev: IPaginationState) => ({
                  ...prev,
                  deleteDisclaimer: false
                }))
              }
              onConfirm={() => {
                deletePayment(deletePaymentId);
                setPaginationState((prev: IPaginationState) => ({
                  ...prev,
                  deleteDisclaimer: false
                }));
              }}
              message="Are you sure you want to delete the Requested Payment?"
            />
          )}
          <DataGrid columns={columnData} data={rowData} tableStyle={{ width: '100%' }} />
        </>
      ) : (
        <ListPlaceholder
          title="No Payment Initiated"
          supportingText="You have not initiated any payment yet."
        />
      )}
    </div>
  );
};

export default PaymentRequested;
