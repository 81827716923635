import { useEffect, useState } from 'react';
import css from './index.module.scss';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Button, Checkbox, CheckboxLabel, IconNode, ToolTip } from '@components/base';
import SelectLabel from '@components/common/select-label';
import Typography from '@components/base/typography';
import PaymentTerms from '@components/common/payment-terms';
import { InputDatePicker, TextField } from '@components/common';
import Images from '@assets/images';
import { CURRENCY, PAYMENT_STATUS } from '@helpers/constants';
import { IPaymentTermsFilter } from '@helpers/types/add-order';
import { IPIPaymentInfo, ISupplierBankDetail } from '../..';
import { useNavigate } from 'react-router-dom';

interface IInvoiceUploadProps {
  onFormSubmit: (data: any) => void;
  onClose: () => void;
  editMode: string | null | undefined;
  piInvoiceDetails: IPIPaymentInfo[];
  isTaxInvoice: boolean;
  paymentTermsOptions: IPaymentTermsFilter[];
  handleBackClick: () => void;
  getPIDetails: () => void;
  supplierBankOptions: ISupplierBankDetail[];
  showNextForm: boolean;
  handleNext: (data: any) => void;
  uploadFinanceFlow: boolean;
  supplierId?: string;
  fetchSupplierBankDetails: (showNotification?: boolean) => Promise<void>;
}

interface IInvoiceState {
  paymentInfo: Array<{
    id: number | null;
    payment_display_id: string | null;
    amount_paid: number | null;
    payment_date: Date | null;
    payment_reference_number: string | null;
    amount_adjusted: number | null;
    is_checked: boolean;
  }> | null;
  disableCurrency: boolean;
}

const IS_PAYMENT_MODE = [
  {
    label: 'Full',
    value: true
  },
  {
    label: 'Partial',
    value: true
  },
  {
    label: 'No',
    value: false
  }
];

const InvoiceUpload = (props: IInvoiceUploadProps) => {
  const {
    onFormSubmit,
    onClose,
    editMode,
    piInvoiceDetails,
    isTaxInvoice = false,
    paymentTermsOptions,
    handleBackClick,
    getPIDetails,
    supplierBankOptions,
    showNextForm,
    handleNext,
    uploadFinanceFlow,
    fetchSupplierBankDetails
  } = props;

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { isSubmitting, errors }
  } = useFormContext();

  const navigate = useNavigate();

  const [invoiceState, setInvoiceState] = useState<IInvoiceState>({
    paymentInfo: null,
    disableCurrency: false
  });

  useEffect(() => {
    const piPaymentInfoDetails = getValues('pi_invoice_details.pi_invoice_payment_info');
    if (piPaymentInfoDetails.length > 0) {
      setInvoiceState((prev) => {
        return {
          ...prev,
          paymentInfo: piPaymentInfoDetails,
          disableCurrency: true
        };
      });
    }
  }, []);

  const { paymentInfo, disableCurrency } = invoiceState;

  const paymentInfoWatch = useWatch({
    name: 'pi_invoice_details.pi_invoice_payment_info',
    control
  });

  const piDocumentWatch = useWatch({
    name: 'pi_invoice_details.pi_document',
    control
  });

  const amountPaidWatch = useWatch({
    name: 'amount_paid',
    control
  });

  useEffect(() => {
    isTaxInvoice && getPIDetails();
  }, []);

  const [filteredInvoicePaymentTermsOptions, setFilteredInvoicePaymentTermsOptions] = useState<
    IPaymentTermsFilter[]
  >([]);

  const invoicePaymentTermWatchField = useWatch({
    name: 'payment_terms',
    control
  });

  const piPaymentTermWatchField = useWatch({
    name: 'pi_invoice_details.pi_invoice_payment_terms',
    control
  });

  const currencyWatch = useWatch({
    name: 'invoice_currency',
    control
  });

  useEffect(() => {
    if (!getValues('invoice_gst')) {
      if (currencyWatch?.value !== 'INR') {
        setValue('invoice_gst', 0);
      } else {
        setValue('invoice_gst', '');
      }
    }
  }, [currencyWatch]);

  useEffect(() => {
    const selectedPaymentTerms = invoicePaymentTermWatchField?.map(
      (item: any) => item?.payment_term?.payment_term ?? ''
    );
    const filteredPaymentTermsOptionsCopy = paymentTermsOptions?.filter((item: any) => {
      return !selectedPaymentTerms?.includes(item.payment_term);
    });
    setFilteredInvoicePaymentTermsOptions(filteredPaymentTermsOptionsCopy);
  }, [invoicePaymentTermWatchField]);

  const {
    fields: invoicePaymentTermFields,
    append: invoicePaymentTermAppend,
    remove: invoicePaymentTermRemove
  } = useFieldArray({
    name: 'payment_terms',
    control
  });

  const handleAddInvoicePaymentTermField = () => {
    invoicePaymentTermAppend({
      payment_term: null,
      percentage: '',
      days: ''
    });
  };

  const invoiceAmountWatch = useWatch({
    name: 'invoice_amount',
    control
  });

  const invoiceGSTWatch = useWatch({
    name: 'invoice_gst',
    control
  });

  useEffect(() => {
    const totalInvoiceAmount = invoiceAmountWatch ? Number(invoiceAmountWatch) : 0;
    const invoiceGST = invoiceGSTWatch ? Number(invoiceGSTWatch) : 0;
    const totalAmount = totalInvoiceAmount + invoiceGST;
    setValue('total_invoice_amount', totalAmount);
  }, [invoiceGSTWatch, invoiceAmountWatch]);

  const isPaymentModeWatch = useWatch({
    name: 'is_payment_mode',
    control: control
  });

  const multipleTaxInvoiceWatch = useWatch({
    name: 'multiple_tax_invoice',
    control: control
  });

  const onPISelection = (selectedOption: any) => {
    const selectedPaymentInfo = selectedOption?.payment_infos;
    const paidPaymentInfo = selectedPaymentInfo
      ?.filter((item: any) => item.payment_status === PAYMENT_STATUS.PAID)
      .map((item: any) => {
        return {
          id: item?.id,
          payment_display_id: item?.payment_id_display,
          amount_paid: item?.adjusted_amount,
          payment_date: item?.payment_confirmation_details?.payment_date,
          payment_reference_number: item?.payment_confirmation_details?.reference_number,
          amount_adjusted: null,
          is_checked: false
        };
      });
    setValue(
      'invoice_currency',
      selectedOption
        ? {
            label: selectedOption?.currency,
            value: selectedOption?.currency
          }
        : null
    );
    setValue(
      'pi_invoice_details.pi_child_invoice_gross_amount',
      selectedOption ? selectedOption?.total_child_invoices_gross_amount : null
    );
    setValue(
      'pi_invoice_details.pi_remaining_invoice_amount',
      selectedOption
        ? parseFloat(selectedOption?.gross_invoice_amount) -
            parseFloat(selectedOption?.total_child_invoices_gross_amount)
        : null
    );
    setValue(
      'multiple_tax_invoice',
      selectedOption ? selectedOption?.has_multiple_tax_invoice : null
    );
    setValue(
      'pi_invoice_details.pi_invoice_number',
      selectedOption ? selectedOption?.invoice_number : null
    );
    setValue(
      'pi_invoice_details.pi_invoice_date',
      selectedOption ? new Date(selectedOption?.invoice_date) : null
    );
    setValue(
      'pi_invoice_details.pi_invoice_amount',
      selectedOption ? selectedOption?.gross_invoice_amount : null
    );
    setValue('pi_invoice_details.pi_invoice_payment_info', selectedOption ? paidPaymentInfo : null);
    setValue(
      'pi_invoice_details.pi_invoice_payment_terms',
      selectedOption ? selectedOption?.payment_terms_display : null
    );
    setInvoiceState((prev) => {
      return {
        ...prev,
        disableCurrency: selectedOption ? !!selectedOption : false,
        paymentInfo: selectedOption ? paidPaymentInfo : null
      };
    });
  };
  return (
    <form
      noValidate
      className={css.formWrapper}
      onSubmit={showNextForm ? handleSubmit(handleNext) : handleSubmit(onFormSubmit)}>
      <section className={css.modalContent}>
        {isTaxInvoice && (
          <Controller
            name="is_payment_mode"
            control={control}
            render={({ field }) => (
              <SelectLabel label="Payment Done" options={IS_PAYMENT_MODE} {...field} />
            )}
          />
        )}
        {isTaxInvoice && (
          <div className={css.piInvoiceDetails}>
            <Controller
              name="pi_invoice_details.pi_document"
              control={control}
              render={({ field, fieldState }) => (
                <SelectLabel
                  {...field}
                  isClearable={!isPaymentModeWatch?.value}
                  label="PI Invoice Details"
                  placeholder="Select one"
                  required={isPaymentModeWatch?.value}
                  isSearchable
                  options={piInvoiceDetails}
                  rootClassName={css.typeField}
                  getOptionLabel={(option: any) => `${option.invoice_number}`}
                  getOptionValue={(option: any) => option.invoice_info_id}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  onChange={(e: any) => {
                    field.onChange(e);
                    onPISelection(e);
                  }}
                />
              )}
            />
            {piDocumentWatch && (
              <>
                <div className={css.rowWrapper}>
                  <Controller
                    name="pi_invoice_details.pi_invoice_number"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        required={isPaymentModeWatch?.value}
                        label="PI Invoice Number"
                        disabled
                        placeholder="PI Invoice Number"
                        error={fieldState.invalid}
                        rootClassName={css.typeField}
                        endIcon={
                          fieldState.invalid && (
                            <IconNode src={Images.alertError} alt="Error Icon" />
                          )
                        }
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                  <Controller
                    name={`pi_invoice_details.pi_invoice_date`}
                    control={control}
                    render={({ field, fieldState }) => (
                      <InputDatePicker
                        {...field}
                        required={isPaymentModeWatch?.value}
                        disabled
                        label="PI Invoice Date"
                        placeholder="Select Date"
                        error={fieldState.invalid}
                        value={new Date(`${field?.value ?? ''}`) ?? ''}
                        helperText={fieldState.error?.message}
                        rootClassName={css.fieldSpacing}
                        onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                      />
                    )}
                  />
                </div>
                <div className={css.rowWrapper}>
                  <Controller
                    name="pi_invoice_details.pi_invoice_amount"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        required={isPaymentModeWatch?.value}
                        label="Gross Invoice Amount"
                        disabled
                        placeholder="PI Invoice Amount"
                        error={fieldState.invalid}
                        rootClassName={css.typeField}
                        endIcon={
                          fieldState.invalid && (
                            <IconNode src={Images.alertError} alt="Error Icon" />
                          )
                        }
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                  <Controller
                    name="pi_invoice_details.pi_child_invoice_gross_amount"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        required={isPaymentModeWatch?.value}
                        label="Previously Attached Tax Invoice Amount"
                        disabled
                        placeholder="Paid Invoice Amount"
                        error={fieldState.invalid}
                        rootClassName={css.typeField}
                        endIcon={
                          fieldState.invalid && (
                            <IconNode src={Images.alertError} alt="Error Icon" />
                          )
                        }
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </div>

                <Controller
                  name="pi_invoice_details.pi_remaining_invoice_amount"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required={isPaymentModeWatch?.value}
                      label="Gross Amount Payable"
                      disabled
                      placeholder="Remaining Invoice Amount"
                      error={fieldState.invalid}
                      rootClassName={css.typeField}
                      endIcon={
                        fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                      }
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
                {piPaymentTermWatchField && (
                  <div className={css.paymentTerms}>
                    <Typography variant="inherit" className={css.paymentTermsLabel}>
                      Payment Terms
                    </Typography>
                    <Typography variant="body">{piPaymentTermWatchField}</Typography>
                  </div>
                )}
              </>
            )}
          </div>
        )}
        {isTaxInvoice && piPaymentTermWatchField && (
          <Controller
            name="multiple_tax_invoice"
            control={control}
            render={({ field, fieldState }) => (
              <CheckboxLabel
                {...field}
                disabled={piDocumentWatch?.has_multiple_tax_invoice === true ? true : false}
                label="Multiple Tax Invoices"
                value={`${field.value}`}
                checked={field.value}
              />
            )}
          />
        )}
        <Controller
          name={`invoice_date`}
          control={control}
          render={({ field, fieldState }) => (
            <InputDatePicker
              {...field}
              required
              label="Invoice Date"
              placeholder="Select Date"
              error={fieldState.invalid}
              value={new Date(`${field?.value ?? ''}`) ?? ''}
              helperText={fieldState.error?.message}
              rootClassName={css.fieldSpacing}
              onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
            />
          )}
        />
        <div className={css.rowWrapper}>
          <Controller
            name="invoice_number"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required={true}
                label="Invoice Number"
                placeholder="Invoice Number"
                error={fieldState.invalid}
                rootClassName={css.typeField}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                }
                helperText={fieldState.error?.message}
              />
            )}
          />

          <Controller
            name="invoice_currency"
            control={control}
            render={({ field, fieldState }) => (
              <SelectLabel
                {...field}
                isClearable
                required
                isDisabled={disableCurrency}
                isSearchable
                placeholder="Select Currency"
                label="Currency"
                options={CURRENCY}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                rootClassName={css.selectWrapper}
              />
            )}
          />
        </div>
        <div className={css.rowWrapper}>
          <Controller
            name="invoice_amount"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required={true}
                label="Invoice Amount"
                placeholder="Invoice Amount"
                error={fieldState.invalid}
                rootClassName={css.typeField}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                }
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="invoice_gst"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required={true}
                label="Invoice GST"
                disabled={currencyWatch?.value !== 'INR'}
                placeholder="Invoice GST"
                error={fieldState.invalid}
                rootClassName={css.typeField}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                }
                helperText={fieldState.error?.message}
              />
            )}
          />
        </div>
        <div className={css.rowWrapper}>
          <Controller
            name="total_invoice_amount"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required={true}
                label="Total Invoice Amount"
                disabled
                placeholder="Total Invoice Amount"
                error={fieldState.invalid}
                rootClassName={css.typeField}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                }
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="supplier_bank_detail"
            control={control}
            render={({ field, fieldState }) => (
              <SelectLabel
                {...field}
                required={true}
                labelJSX={
                  <div className={css.plusRefreshWrapper}>
                    <ToolTip title={`Add Supplier Bank Details`} placement="right">
                      <div className={css.plusRed}>
                        <IconNode
                          src={Images.plusRed}
                          alt="plus icon"
                          width={12}
                          height={12}
                          onClick={() => {
                            window.open(`/supplier/${props.supplierId}`, '_blank');
                          }}
                        />
                      </div>
                    </ToolTip>
                    <ToolTip title={`Refresh Data`} placement="right">
                      <div className={css.refreshRed}>
                        <IconNode
                          src={Images.refreshRed}
                          alt="Refresh icon"
                          width={14}
                          height={14}
                          onClick={() => {
                            fetchSupplierBankDetails(true);
                          }}
                        />
                      </div>
                    </ToolTip>
                  </div>
                }
                label="Supplier Bank"
                options={supplierBankOptions}
                getOptionLabel={(option: any) => `${option?.bank_name} - ${option?.account_number}`}
                placeholder="Select one"
                error={fieldState.invalid}
                rootClassName={css.typeField}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </div>

        {isTaxInvoice && multipleTaxInvoiceWatch && isPaymentModeWatch?.value && (
          <>
            <div className={css.rowWrapper}>
              <Controller
                name="tds"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required={true}
                    label={
                      <div className={css.financeTDSTCS}>
                        TDS
                        <ToolTip title={`Check with Finance Team`} placement="right">
                          <div className={css.alertgrey}>
                            <IconNode src={Images.alertGrey} alt="eye icon" />
                          </div>
                        </ToolTip>
                      </div>
                    }
                    labelClassName={css.financeTDSTCS}
                    placeholder="TDS"
                    error={fieldState.invalid}
                    rootClassName={css.typeField}
                    endIcon={
                      fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                    }
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              <Controller
                name="tcs"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required={true}
                    label={
                      <div className={css.financeTDSTCS}>
                        TCS
                        <ToolTip title={`Check with Finance Team`} placement="right">
                          <div className={css.alertgrey}>
                            <IconNode src={Images.alertGrey} alt="eye icon" />
                          </div>
                        </ToolTip>
                      </div>
                    }
                    labelClassName={css.financeTDSTCS}
                    placeholder="TCS"
                    error={fieldState.invalid}
                    rootClassName={css.typeField}
                    endIcon={
                      fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                    }
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </div>
            <div className={css.rowWrapper}>
              <Controller
                name="amount_paid"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required={true}
                    label="Amount Paid"
                    placeholder="Amount Paid"
                    error={fieldState.invalid}
                    rootClassName={css.typeField}
                    endIcon={
                      fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                    }
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </div>
            {isPaymentModeWatch?.value &&
              parseFloat(amountPaidWatch) > 0 &&
              paymentInfo &&
              paymentInfo.length > 0 && (
                <div className={css.piInvoiceDetails}>
                  {paymentInfo.map((item: any, index: number) => {
                    return (
                      <div key={index} className={css.rowWrapper}>
                        <Controller
                          name={`pi_invoice_details.pi_invoice_payment_info[${index}].is_checked`}
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              className={css.checkbox}
                              value={`${field.value}`}
                              checked={field.value}
                              onChange={(e) => {
                                field.onChange(e.target.checked);
                                if (!e.target.checked) {
                                  setValue(
                                    `pi_invoice_details.pi_invoice_payment_info[${index}].amount_adjusted`,
                                    null
                                  );
                                }
                              }}
                            />
                          )}
                        />
                        <Controller
                          name={`pi_invoice_details.pi_invoice_payment_info[${index}].payment_display_id`}
                          control={control}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              required={paymentInfoWatch?.[index]?.is_checked}
                              disabled
                              label="Payment Id"
                              placeholder="Payment Id"
                              error={fieldState.invalid}
                              rootClassName={css.typeField}
                              endIcon={
                                fieldState.invalid && (
                                  <IconNode src={Images.alertError} alt="Error Icon" />
                                )
                              }
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                        <Controller
                          name={`pi_invoice_details.pi_invoice_payment_info[${index}].payment_date`}
                          control={control}
                          render={({ field, fieldState }) => (
                            <InputDatePicker
                              {...field}
                              required={paymentInfoWatch?.[index]?.is_checked}
                              disabled
                              label="Payment Date"
                              placeholder="Select Date"
                              error={fieldState.invalid}
                              value={new Date(`${field?.value ?? ''}`) ?? ''}
                              helperText={fieldState.error?.message}
                              rootClassName={css.fieldSpacing}
                              onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                            />
                          )}
                        />
                        <Controller
                          name={`pi_invoice_details.pi_invoice_payment_info[${index}].payment_reference_number`}
                          control={control}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              required={paymentInfoWatch?.[index]?.is_checked}
                              disabled
                              label="Reference Number"
                              placeholder="Payment Reference Number"
                              error={fieldState.invalid}
                              rootClassName={css.typeField}
                              endIcon={
                                fieldState.invalid && (
                                  <IconNode src={Images.alertError} alt="Error Icon" />
                                )
                              }
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                        <Controller
                          name={`pi_invoice_details.pi_invoice_payment_info[${index}].amount_paid`}
                          control={control}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              required={paymentInfoWatch?.[index]?.is_checked}
                              label="Amount Paid"
                              disabled
                              placeholder="Amount Paid"
                              error={fieldState.invalid}
                              rootClassName={css.typeField}
                              endIcon={
                                fieldState.invalid && (
                                  <IconNode src={Images.alertError} alt="Error Icon" />
                                )
                              }
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                        <Controller
                          name={`pi_invoice_details.pi_invoice_payment_info[${index}].amount_adjusted`}
                          control={control}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              required={paymentInfoWatch?.[index]?.is_checked}
                              disabled={!paymentInfoWatch?.[index]?.is_checked}
                              label="Amount"
                              placeholder="Amount"
                              error={fieldState.invalid}
                              rootClassName={css.typeField}
                              endIcon={
                                fieldState.invalid && (
                                  <IconNode src={Images.alertError} alt="Error Icon" />
                                )
                              }
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
          </>
        )}
        <PaymentTerms
          paymentTermFields={invoicePaymentTermFields}
          paymentTermRemove={invoicePaymentTermRemove}
          paymentTermsOptions={paymentTermsOptions}
          filteredPaymentTermsOptions={
            filteredInvoicePaymentTermsOptions?.length
              ? filteredInvoicePaymentTermsOptions
              : paymentTermsOptions
          }
          handleAddPaymentTermField={handleAddInvoicePaymentTermField}
        />
      </section>
      <section className={css.modalFooter}>
        <Button variant="outlined-secondary" onClick={handleBackClick} disabled={isSubmitting}>
          Back
        </Button>
        <Button type="submit" disabled={isSubmitting}>
          {showNextForm
            ? 'Next'
            : editMode
            ? isSubmitting
              ? 'Updating'
              : 'Update'
            : isSubmitting
            ? 'Saving'
            : 'Save'}
        </Button>
      </section>
    </form>
  );
};

export default InvoiceUpload;
