/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import {
  NoDocumentPlaceholder,
  DocumentRow,
  DataGrid,
  PopOverButton,
  MultiplePlaceholder
} from '@components/common';
import { CellProps, Column } from 'react-table';
import { cropValues, getFormattedDate } from '@helpers/utils';
import css from './index.module.scss';
import { DEFAULT_CROP_LIMIT, TAG_LIMIT_VIEW } from '@helpers/constants';
import { Divider, IconNode, Chip, Button, ToolTip, Checkbox } from '@components/base';
import Images from '@assets/images';
import { TablePopOver } from './components/table-popover';
import { IOrderDocument } from '@helpers/types/document';
import { FormProvider, useForm } from 'react-hook-form';
import ResolveInconsistencyModal from './components/resolve-inconsistency-modal';
import { resolveInconsistency } from '@services/order.service';
import { yupResolver } from '@hookform/resolvers/yup';
import { resolveInconsistencySchema } from '@helpers/yup/add-document.schema';
import notify from '@helpers/toastify-helper';

interface Document {
  doc_name: string;
  doc_link: any;
  doc_type: string;
  primary_key: string;
}

interface DocumentRowType {
  document_name: string;
  document_object: Document;
}

interface ProductTableDocumentsPropType {
  data: Array<any>;
  orderId: string;
  openUpdateTagsModal: (data: any) => void;
  onDocSelection: (e: any, value: string) => void;
  getDocumentData: () => Promise<void>;
  sortDocument: (status: number) => void;
  currentStatus: number;
}

interface ResolveInconsistencyStates {
  open: boolean;
  currentDoc: any;
  isLoading: boolean;
}

const DocumentTable = (props: ProductTableDocumentsPropType) => {
  const {
    data: initialData,
    orderId,
    openUpdateTagsModal,
    onDocSelection,
    getDocumentData,
    sortDocument,
    currentStatus
  } = props;

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [sortedData, setSortedData] = useState(initialData);
  const [resolveInconsistencyStates, setResolveInconsistencyStates] =
    useState<ResolveInconsistencyStates>({
      open: false,
      currentDoc: null,
      isLoading: false
    });
  const { open, currentDoc, isLoading } = resolveInconsistencyStates;

  const getDocumentType = (doc: IOrderDocument) => {
    if (doc.is_inconsistent) {
      const MisMatchDocuments = {
        systemGeneratedDocument: doc?.system_generated_documents?.pdf_document,
        manuallyUploadedDocument: doc?.document
      };
      return {
        documents: MisMatchDocuments,
        heading: '',
        title: 'Documents are inconsistent',
        uploadedOutsideWorkflow: {
          value: doc.uploaded_outside_workflow,
          order_id: orderId,
          order_item_id: doc.order_document_id
        }
      };
    } else if (!doc.is_system_generated && !doc.is_inconsistent) {
      const ManualDocuments = [];
      ManualDocuments.push({
        pdfLink: doc.document
      });
      return {
        documents: ManualDocuments,
        uploadedOutsideWorkflow: {
          value: doc.uploaded_outside_workflow,
          order_id: orderId,
          order_item_id: doc.order_document_id
        }
      };
    } else if (doc.is_system_generated) {
      const additionalDocument = doc.additional_documents;
      const signedDocument = {
        text: 'With letterhead and signed',
        pdfLink: additionalDocument?.signed_pdf,
        docLink: additionalDocument?.signed_docx
      };
      const unsignedDocument = {
        text: 'With letterhead and unsigned',
        pdfLink: additionalDocument?.unsigned_pdf,
        docLink: additionalDocument?.unsigned_docx
      };
      const unsignedAndHeaderLessDocument = {
        text: 'Without letterhead and unsigned',
        pdfLink: additionalDocument?.unsigned_and_headerless_pdf,
        docLink: additionalDocument?.unsigned_and_headerless_docx
      };
      const systemGeneratedDocument = [
        signedDocument,
        unsignedDocument,
        unsignedAndHeaderLessDocument
      ];
      return {
        documents: systemGeneratedDocument,
        uploadedOutsideWorkflow: {
          value: doc.uploaded_outside_workflow,
          order_id: orderId,
          order_item_id: doc.order_document_id
        }
      };
    }
  };

  const [documentColumn, documentData] = useMemo(() => {
    const column: Column<any>[] = [
      {
        Header: '',
        accessor: 'check_box',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          return (
            <>
              {value?.document_url?.length ? (
                <Checkbox
                  checked={value.check_box}
                  onChange={(e) => onDocSelection(e, value?.order_document_id)}
                />
              ) : (
                <></>
              )}
            </>
          );
        }
      },
      {
        Header: 'Document Name',
        accessor: 'display_name',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          const isEditable = value?.doc?.is_editable && !value?.doc?.is_payable_invoice;
          return (
            <div className={css.display_name}>
              {value.name?.length > DEFAULT_CROP_LIMIT ? (
                <ToolTip title={value.name}>
                  <div
                    className={css.docName}
                    onClick={() => isEditable && openUpdateTagsModal(value.doc)}>
                    {cropValues(value.name)}
                  </div>
                </ToolTip>
              ) : (
                <div
                  className={css.docName}
                  onClick={() => isEditable && openUpdateTagsModal(value.doc)}>
                  {cropValues(value.name)}
                </div>
              )}
              {value.system_generated && (
                <ToolTip title="System Generated" placement="right">
                  <div>
                    <IconNode
                      className={css.systemGenerated}
                      src={Images.settings}
                      alt="system generated"
                    />
                  </div>
                </ToolTip>
              )}

              {value?.doc?.is_inconsistent && (
                <ToolTip placement="right" title="Document is inconsistent, please resolve it">
                  <div>
                    <IconNode src={Images.alertWarning} alt="Warning" />
                  </div>
                </ToolTip>
              )}
            </div>
          );
        }
      },
      {
        Header: () => (
          <div
            className={css.DocumentNameTableHeader}
            onClick={() => {
              const newStatus = currentStatus === 0 ? 1 : currentStatus === 1 ? 2 : 0;
              sortDocument(newStatus);
            }}>
            <span>Document Type</span>
            <IconNode src={Images.sort} alt="document" width={16} height={16} />
          </div>
        ),
        accessor: 'document_type_display_value'
      },
      {
        Header: 'Product',
        accessor: 'order_item_name',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          return (
            <div className={css.DocumentTabProducts}>
              <MultiplePlaceholder names={value} label="Multiple" placement="top" />
            </div>
          );
        }
      },
      {
        Header: 'Supplier',
        accessor: 'supplier',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          return value?.length > DEFAULT_CROP_LIMIT ? (
            <ToolTip title={value}>
              <div>{cropValues(value)}</div>
            </ToolTip>
          ) : (
            <div>{cropValues(value)}</div>
          );
        }
      },
      {
        Header: 'Uploaded By',
        accessor: 'created_by',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          return value?.length > DEFAULT_CROP_LIMIT ? (
            <ToolTip title={value}>
              <div>{cropValues(value)}</div>
            </ToolTip>
          ) : (
            <div>{cropValues(value)}</div>
          );
        }
      },
      {
        Header: () => (
          <div
            className={css.UploadedOnTableHeader}
            onClick={() => {
              const newStatus =
                currentStatus === 3 ? 4 : currentStatus === 4 ? 0 : currentStatus === 0 ? 3 : 3;
              sortDocument(newStatus);
            }}>
            <span>Uploaded On</span>
            <IconNode src={Images.sort} alt="document" width={16} height={16} />
          </div>
        ),
        accessor: 'created_at'
      },
      {
        Header: 'Tags',
        accessor: 'tags',
        Cell: (props: CellProps<any>) => {
          const { value }: any = props;
          const { internal_tags = [] } = value;
          const isEditable = value?.is_editable && !value?.is_payable_invoice;
          return (
            <div className={css.chipGroup}>
              {internal_tags?.length ? (
                internal_tags?.length > TAG_LIMIT_VIEW ? (
                  <>
                    {internal_tags.slice(0, TAG_LIMIT_VIEW)?.map((tag: any) => (
                      <Chip
                        key={tag}
                        label={tag}
                        onClick={() => isEditable && openUpdateTagsModal(value)}
                        className={css.chip}
                      />
                    ))}
                    <ToolTip
                      title={internal_tags.slice(TAG_LIMIT_VIEW).join(', ')}
                      placement="right">
                      <div>
                        <Chip
                          label={`+ ${internal_tags.length - TAG_LIMIT_VIEW}`}
                          onClick={() => isEditable && openUpdateTagsModal(value)}
                          className={css.chip}
                        />
                      </div>
                    </ToolTip>
                  </>
                ) : (
                  internal_tags?.map((tag: any) => (
                    <Chip
                      key={tag}
                      label={tag}
                      onClick={() => isEditable && openUpdateTagsModal(value)}
                      className={css.chip}
                    />
                  ))
                )
              ) : (
                <>-</>
              )}
            </div>
          );
        }
      },
      {
        Header: '',
        accessor: 'document',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          const isEditable = value?.doc?.is_editable && !value?.is_payable_invoice;
          return (
            <>
              {/* Change negation */}
              {value?.doc?.is_inconsistent ? (
                <div style={{ display: 'flex' }}>
                  <Button
                    variant="outlined-warning"
                    onClick={() => openResolveInconsistencyModalHandler(value)}
                    className={css.resolveInconsistencyBtn}>
                    Resolve
                  </Button>
                </div>
              ) : (
                <div className={css.tableIcons}>
                  <div>
                    <ToolTip title={value.doc.remarks ?? `Remarks Not Available`}>
                      <div>
                        <IconNode src={Images.alertGrey} alt="eye icon" />
                      </div>
                    </ToolTip>
                  </div>
                  <DocumentRow.View
                    showEyeIconWhenDocumentMissing={true}
                    document={value.doc.document}
                    openViaSlug
                    title=""
                  />
                  <Divider className={css.divider} />
                  <div className={css.popover}>
                    <PopOverButton
                      openButton={<IconNode src={Images.threeDots} alt="three dots" />}
                      visible={isPopoverOpen}
                      disabled={!isEditable}
                      setIsPopoverOpen={setIsPopoverOpen}>
                      <TablePopOver
                        documents={value.documents}
                        heading={value.heading}
                        title={value.title}
                        handleEdit={() => isEditable && openUpdateTagsModal(value.doc)}
                        uploadedOutsideWorkflow={value.uploadedOutsideWorkflow}
                        getDocumentData={getDocumentData}
                        isEditable={isEditable}
                        isPaymentInvoice={value.is_payable_invoice}
                      />
                    </PopOverButton>
                  </div>
                </div>
              )}
            </>
          );
        }
      }
    ];
    const row: any[] = sortedData?.map((doc: any) => ({
      document_type_display_value: doc.document_type_display_value ?? '-',
      order_item_name: doc.products.map((item: any) => {
        return item.product_name;
      }),
      supplier: doc.supplier_name ?? '-',
      created_at: doc.created_at ? getFormattedDate(doc.created_at) : '-',
      created_by: doc.created_by ?? '-',
      document: {
        ...getDocumentType(doc),
        doc: doc
      },
      display_name: {
        name: doc.display_name,
        system_generated: doc.is_system_generated,
        doc: doc
      },
      tags: doc,
      check_box: doc
    }));
    return [column, row];
  }, [initialData, sortedData, currentStatus]);

  const resolveInconsistencyForm = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(resolveInconsistencySchema),
    defaultValues: {
      valid_document_type: null,
      valid_document_object: null,
      reason_for_update: null
    }
  });

  const openResolveInconsistencyModalHandler = (data: any) => {
    setResolveInconsistencyStates((prevState: any) => ({
      ...prevState,
      open: true,
      currentDoc: data
    }));
  };

  const onFormSubmit = async (data: any) => {
    setResolveInconsistencyStates((prevState: any) => ({ ...prevState, isLoading: true }));
    const response = await resolveInconsistency(
      orderId,
      currentDoc?.doc?.order_document_id ?? null,
      data
    );
    setResolveInconsistencyStates((prevState: any) => ({ ...prevState, isLoading: false }));
    if (response?.success) {
      const { reset } = resolveInconsistencyForm;
      notify({
        message: 'Document inconsistency resolved successfully'
      });
      setResolveInconsistencyStates((prevState: any) => ({
        ...prevState,
        open: false
      }));
      getDocumentData();
      reset();
    } else {
      notify({
        message: response?.error ?? 'Something went wrong!',
        severity: 'error'
      });
    }
  };

  useEffect(() => {
    setSortedData(initialData);
  }, [initialData]);

  return (
    <>
      {sortedData.length ? (
        <DataGrid columns={documentColumn} data={documentData} />
      ) : (
        !isLoading && <NoDocumentPlaceholder title="No Document Added/Generated Yet" />
      )}
      <FormProvider {...resolveInconsistencyForm}>
        {open && (
          <ResolveInconsistencyModal
            open={open}
            onClose={() =>
              setResolveInconsistencyStates((prevState: any) => ({
                ...prevState,
                open: false
              }))
            }
            onFormSubmit={onFormSubmit}
            currentDoc={currentDoc}
          />
        )}
      </FormProvider>
    </>
  );
};

export default DocumentTable;
