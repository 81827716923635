import { useEffect, useMemo, useState } from 'react';
import css from './index.module.scss';
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  useFieldArray,
  useFormContext,
  UseFormGetValues,
  UseFormReset,
  UseFormSetValue,
  useWatch
} from 'react-hook-form';
import { Button, Chip, IconNode, Typography } from '@components/base';
import { InputDatePicker, SelectLabel, TextField } from '@components/common';
import Images from '@assets/images';
import { IInvoice, IMakePaymentState, IPaymentInfo } from '../..';
import { getFormattedDate } from '@helpers/utils';
import { useBeforeUnloadAndNavigate } from '@helpers/hooks';
import { fetchEntityBankList } from '@services/order.service';
import notify from '@helpers/toastify-helper';

interface IInvoiceDetailsProps {
  onFormSubmit: () => void;
  onCancelClick: () => void;
  invoiceData: IInvoice[] | null;
  paymentData: any;
  entity_id: string | null;
  setMakePaymentState: React.Dispatch<React.SetStateAction<IMakePaymentState>>;
}

const PaymentDetails = (props: IInvoiceDetailsProps) => {
  const { onFormSubmit, onCancelClick, invoiceData, setMakePaymentState, paymentData, entity_id } =
    props;

  const [entityBankList, setEntityBankList] = useState([]);

  const {
    control,
    handleSubmit,
    getValues,
    setError,
    reset,
    setValue,
    clearErrors,
    formState: { errors }
  } = useFormContext();

  const getPayerBankDetails = async () => {
    const response = await fetchEntityBankList(entity_id ?? '');
    if (response.success) {
      const bankDetails = response.data.results;
      setEntityBankList(bankDetails);
    } else {
      notify({
        title: 'Error',
        message: response.message,
        severity: 'error'
      });
    }
  };

  useBeforeUnloadAndNavigate();

  const { fields: invoiceFields } = useFieldArray({
    control,
    name: 'invoice'
  });

  const validateInvoice = (itemIndex: number) => {
    const invoiceValues = invoiceData && invoiceData[itemIndex];
    const remaining_amount_to_pay =
      parseFloat(invoiceData ? invoiceData[itemIndex]?.amount : '0') +
      parseFloat(invoiceData ? invoiceData[itemIndex]?.gst_amount : '0') -
      parseFloat(invoiceData ? invoiceData[itemIndex]?.total_amount_paid : '0') -
      parseFloat(invoiceData ? invoiceData[itemIndex]?.total_amount_initiated : '0') -
      parseFloat(invoiceData ? invoiceData[itemIndex]?.total_child_invoices_gross_amount : '0') -
      parseFloat(invoiceValues?.tds_amount ?? '0') +
      parseFloat(invoiceValues?.tcs_amount ?? '0');

    const requested_remaining_amount =
      parseFloat(invoiceData ? invoiceData[itemIndex]?.amount : '0') +
      parseFloat(invoiceData ? invoiceData[itemIndex]?.gst_amount : '0') -
      parseFloat(invoiceData ? invoiceData[itemIndex]?.total_amount_paid : '0') -
      parseFloat(invoiceData ? invoiceData[itemIndex]?.total_amount_initiated : '0') -
      parseFloat(invoiceData ? invoiceData[itemIndex]?.total_child_invoices_gross_amount : '0');

    if (
      parseFloat(invoiceValues?.tds_amount ?? '0') > 0 &&
      parseFloat(invoiceValues?.tcs_amount ?? '0') > 0
    ) {
      setError(`invoice[${itemIndex}].invoice_info_id`, {
        type: 'manual',
        message: 'Please enter either TDS or TCS, not both.'
      });
      return false;
    }

    const paymentInfos = getValues(`invoice[${itemIndex}].payment_infos`);
    let totalRequestedAmount = 0;
    paymentInfos.forEach((payment: IPaymentInfo) => {
      totalRequestedAmount += parseFloat(payment.amount_requested ?? '0');
    });

    let totalTdsTcsAmount = 0;
    let totalTdsAmount = 0;
    let totalTcsAmount = 0;
    paymentInfos.forEach((payment: IPaymentInfo) => {
      totalTdsTcsAmount +=
        parseFloat(payment.tds_amount ?? '0') + parseFloat(payment.tcs_amount ?? '0');
      totalTdsAmount += parseFloat(payment.tds_amount ?? '0');
      totalTcsAmount += parseFloat(payment.tcs_amount ?? '0');
    });

    if (
      parseFloat(invoiceValues?.tds_amount ?? '0') +
        parseFloat(invoiceValues?.tcs_amount ?? '0') -
        totalTdsTcsAmount <
      0
    ) {
      setError(`invoice[${itemIndex}].invoice_info_id`, {
        type: 'manual',
        message:
          'The total TDS/TCS amount must not exceed the TDS/TCS amount specified on the invoice.'
      });
      return false;
    }
    if (totalRequestedAmount > requested_remaining_amount) {
      setError(`invoice[${itemIndex}].invoice_info_id`, {
        type: 'manual',
        message: 'Total requested amount is greater than total amount eligible for payment'
      });
      return false;
    } else {
      clearErrors(`invoice[${itemIndex}].invoice_info_id`);
    }
    if (totalRequestedAmount - totalTdsAmount + totalTcsAmount > remaining_amount_to_pay) {
      setError(`invoice[${itemIndex}].invoice_info_id`, {
        type: 'manual',
        message: 'Total Amount to Pay is greater than total amount eligible for payment'
      });
      return false;
    } else {
      clearErrors(`invoice[${itemIndex}].invoice_info_id`);
    }
    return true;
  };

  const validateInvoices = () => {
    let isValid = true;
    invoiceFields.forEach((invoice: any, index: number) => {
      if (!validateInvoice(index)) {
        isValid = false;
      }
    });
    return isValid;
  };

  useEffect(() => {
    getPayerBankDetails();
  }, [entity_id]);

  return (
    <form
      noValidate
      onSubmit={handleSubmit((data) => {
        if (validateInvoices()) {
          onFormSubmit();
        }
      })}
      className={css.partiesFormWrapper}>
      <div className={css.entityBank}>
        <Controller
          name="payer_bank_detail"
          control={control}
          render={({ field, fieldState }) => (
            <SelectLabel
              {...field}
              rootClassName={css.selectLabel}
              label="Entity Bank"
              required
              placeholder="Select Entity Bank"
              isSearchable
              options={entityBankList}
              isClearable
              getOptionLabel={(options: any) => options?.bank_display_name}
              getOptionValue={(options: any) => options?.bank_id}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
            />
          )}
        />
        {invoiceFields.map((invoice: any, index: number) => {
          return (
            <InvoiceComponent
              key={index}
              invoice={invoice}
              itemIndex={index}
              control={control}
              invoiceData={invoiceData}
              getValues={getValues}
              errors={errors}
              validateInvoice={validateInvoice}
              setValue={setValue}
              setMakePaymentState={setMakePaymentState}
              paymentData={paymentData}
              reset={reset}
            />
          );
        })}
      </div>
      <div className={css.formActionWrapper}>
        <Button onClick={onCancelClick} variant="text">
          Cancel
        </Button>
        <Button type="submit" variant="outlined-secondary" className={css.saveAndProceedButton}>
          Save and Proceed
        </Button>
      </div>
    </form>
  );
};

export default PaymentDetails;

interface InvoiceComponentProps {
  invoice: any;
  itemIndex: number;
  control: Control<FieldValues, any>;
  invoiceData: IInvoice[] | null;
  getValues: UseFormGetValues<FieldValues>;
  errors: FieldErrors<FieldValues>;
  validateInvoice: (itemIndex: number) => boolean;
  setValue: UseFormSetValue<FieldValues>;
  setMakePaymentState: React.Dispatch<React.SetStateAction<IMakePaymentState>>;
  reset: UseFormReset<FieldValues>;
  paymentData: any;
}

const InvoiceComponent = (props: InvoiceComponentProps) => {
  const {
    invoice,
    itemIndex,
    control,
    invoiceData,
    getValues,
    errors,
    validateInvoice,
    setValue,
    setMakePaymentState,
    reset,
    paymentData
  } = props;

  const [totalRequestedAmount, setTotalRequestedAmount] = useState(0);
  const [totalAmountToPay, setTotalAmountToPay] = useState(0);
  const [showAmountDetails, setShowAmountDetails] = useState(false);

  const { fields: paymentFields } = useFieldArray({
    control,
    name: `invoice[${itemIndex}].payment_infos`
  });

  const [isExpanded, setIsExpanded] = useState(itemIndex === 0);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const calculateTotalRequestedAmount = () => {
    const paymentInfo = getValues(`invoice[${itemIndex}].payment_infos`);
    let totalAmount = 0;
    paymentInfo.forEach((payment: IPaymentInfo) => {
      totalAmount += parseFloat(payment.amount_requested ?? '0');
    });
    setTotalRequestedAmount(totalAmount);
  };

  const calculateTotalAmounToPay = () => {
    const paymentInfo = getValues(`invoice[${itemIndex}].payment_infos`);
    let totalAmount = 0;
    paymentInfo.forEach((payment: IPaymentInfo) => {
      totalAmount +=
        parseFloat(payment.amount_requested ?? '0') -
        parseFloat(payment.tds_amount ?? '0') +
        parseFloat(payment.tcs_amount ?? '0') +
        parseFloat(payment.additional_charges_or_discounts ?? '0');
    });
    setTotalAmountToPay(totalAmount);
  };

  const invoiceCancel = () => {
    const currentFormData = getValues(`invoice[${itemIndex}]`);
    const paymentInfos = paymentData?.[itemIndex]?.payment_infos;
    setValue(`invoice[${itemIndex}]`, { ...currentFormData, payment_infos: paymentInfos });
    handleExpand();
  };

  const saveInvoice = () => {
    if (validateInvoice(itemIndex)) {
      const paymentInfos = getValues(`invoice[${itemIndex}].payment_infos`);
      const paymentDataCopy = [...paymentData];
      paymentDataCopy[itemIndex].payment_infos = paymentInfos;
      setMakePaymentState((prevState) => ({
        ...prevState,
        paymentData
      }));
      calculateTotalRequestedAmount();
      calculateTotalAmounToPay();
      handleExpand();
    }
  };

  const tdsAmount = invoiceData ? invoiceData[itemIndex]?.tds_amount : '0';
  const tcsAmount = invoiceData ? invoiceData[itemIndex]?.tcs_amount : '0';

  useEffect(() => {
    calculateTotalRequestedAmount();
  }, [invoice]);

  const grossAmount = useMemo(() => {
    return (
      parseFloat(invoiceData ? invoiceData[itemIndex]?.amount : '0') +
      parseFloat(invoiceData ? invoiceData[itemIndex]?.gst_amount : '0')
    );
  }, [invoiceData, itemIndex]);

  const amountRemainingToPay = useMemo(() => {
    return (
      parseFloat(invoiceData ? invoiceData[itemIndex]?.amount : '0') +
      parseFloat(invoiceData ? invoiceData[itemIndex]?.gst_amount : '0') -
      parseFloat(invoiceData ? invoiceData[itemIndex]?.total_amount_paid : '0') -
      parseFloat(invoiceData ? invoiceData[itemIndex]?.total_amount_initiated : '0') -
      parseFloat(invoiceData ? invoiceData[itemIndex]?.total_child_invoices_gross_amount : '0') -
      parseFloat(tdsAmount) +
      parseFloat(tcsAmount)
    );
  }, [invoiceData, itemIndex]);

  return (
    <div
      className={(errors?.invoice as any)?.[itemIndex] ? css.accordianError : css.accordian}
      key={itemIndex}>
      <div className={css.accordianHeader} onClick={handleExpand}>
        <div className={css.accordianHeaderDetails}>
          <div className={css.invoiceNumber}>
            {(errors?.invoice as any)?.[itemIndex] && (
              <IconNode src={Images.exclamation} alt="invoice icon" />
            )}
            <Typography
              variant="h4"
              className={
                (errors?.invoice as any)?.[itemIndex] ? css.error : css.invoiceNumberHeader
              }>
              Invoice Number : {invoiceData && invoiceData[itemIndex]?.invoice_number}
            </Typography>
          </div>
          <Typography variant="label">
            Total Requested Amount: {invoiceData && invoiceData[itemIndex]?.currency?.value}{' '}
            {totalRequestedAmount}
          </Typography>
          {totalAmountToPay > 0 && (
            <Typography variant="label">
              Total Amount To Pay: {invoiceData && invoiceData[itemIndex]?.currency?.value}{' '}
              {totalAmountToPay}
            </Typography>
          )}
        </div>

        <div className={css.dateButton}>
          <Chip
            variant="filled-blue"
            label={getFormattedDate(invoiceData && invoiceData[itemIndex]?.invoice_date)}
          />
          <Button variant="text" onClick={handleExpand}>
            <IconNode src={isExpanded ? Images.arrowUpBig : Images.arrowDownBig} alt="arrow down" />
          </Button>
        </div>
      </div>
      {isExpanded && (
        <>
          <SelectLabel
            label="Order Ids"
            options={[]}
            value={invoiceData && invoiceData[itemIndex].order_ids}
            isDisabled={true}
            isMulti
            rootClassName={css.selectWrapper}
            placeholder="Select Tag(s)"
          />
          <div className={css.rowWrapper}>
            <TextField
              value={
                invoiceData && invoiceData[itemIndex].document_type_display
                  ? invoiceData[itemIndex].document_type_display
                  : ''
              }
              disabled
              enterKeyHint="next"
              autoComplete="off"
              label="Document Type"
              placeholder="Start typing"
            />
            <TextField
              value={
                invoiceData && invoiceData[itemIndex].supplier_name
                  ? invoiceData[itemIndex].supplier_name
                  : ''
              }
              disabled
              enterKeyHint="next"
              autoComplete="off"
              label="Supplier Name"
              placeholder="Start typing"
            />
          </div>
          <div className={css.rowWrapper}>
            <SelectLabel
              value={invoiceData && invoiceData[itemIndex].currency}
              label="Currency"
              isDisabled
              placeholder="Select"
            />
            <TextField
              value={invoiceData ? invoiceData[itemIndex].amount : ' '}
              disabled
              enterKeyHint="next"
              autoComplete="off"
              label="Invoice Amount"
              placeholder="Start typing"
            />
            <TextField
              value={invoiceData ? invoiceData[itemIndex].gst_amount : ' '}
              disabled
              enterKeyHint="next"
              autoComplete="off"
              label="GST Amount"
              placeholder="Start typing"
            />
          </div>
          <div className={css.rowWrapper}>
            {parseInt(tdsAmount) > 0 && (
              <TextField
                value={invoiceData ? invoiceData[itemIndex].tds_amount : ' '}
                disabled
                enterKeyHint="next"
                autoComplete="off"
                label="TDS Amount"
                placeholder="Start typing"
              />
            )}
            {parseInt(tcsAmount) > 0 && (
              <TextField
                value={invoiceData ? invoiceData[itemIndex].tcs_amount : ' '}
                disabled
                enterKeyHint="next"
                autoComplete="off"
                label="TCS Amount"
                placeholder="Start typing"
              />
            )}
            <TextField
              value={invoiceData ? invoiceData[itemIndex].payment_terms : ' '}
              disabled
              enterKeyHint="next"
              autoComplete="off"
              label="Payment Terms"
              placeholder="Start typing"
            />
          </div>
          <Button variant="text" onClick={() => setShowAmountDetails(!showAmountDetails)}>
            See how these amounts are calculated?
          </Button>
          {showAmountDetails && (
            <div className={css.amountBreakdown}>
              {parseFloat(invoiceData ? invoiceData[itemIndex]?.gst_amount : '0') > 0 && (
                <div>
                  <b>
                    Gross Amount ({invoiceData && invoiceData[itemIndex].currency?.value}{' '}
                    {grossAmount}){' '}
                  </b>
                  = Invoice Amount ({invoiceData && invoiceData[itemIndex].currency?.value}{' '}
                  {invoiceData && invoiceData[itemIndex]?.amount})
                  {`+ GST Amount (
                  ${invoiceData && invoiceData[itemIndex].currency?.value} 
                  ${invoiceData && parseFloat(invoiceData[itemIndex]?.gst_amount)} )`}
                </div>
              )}
              <div>
                <b>
                  Amount Remaining To Pay ({invoiceData && invoiceData[itemIndex].currency?.value}{' '}
                  {amountRemainingToPay} ){' '}
                </b>
                = Gross Amount ( {invoiceData && invoiceData[itemIndex].currency?.value}{' '}
                {grossAmount} )
                {parseFloat(invoiceData ? invoiceData[itemIndex]?.total_amount_paid : '0') > 0 && (
                  <>
                    {' '}
                    - Total Amount Paid ( {invoiceData &&
                      invoiceData[itemIndex].currency?.value}{' '}
                    {invoiceData ? invoiceData[itemIndex]?.total_amount_paid : '0'} )
                  </>
                )}
                {parseFloat(invoiceData ? invoiceData[itemIndex]?.total_amount_initiated : '0') >
                  0 && (
                  <>
                    {' '}
                    - Total Amount Initiated ({' '}
                    {invoiceData && invoiceData[itemIndex].currency?.value}{' '}
                    {invoiceData ? invoiceData[itemIndex]?.total_amount_initiated : '0'} )
                  </>
                )}
                {parseFloat(
                  invoiceData ? invoiceData[itemIndex]?.total_child_invoices_gross_amount : '0'
                ) > 0 && (
                  <>
                    {' '}
                    - Attached Tax Invoice Amount ({' '}
                    {invoiceData && invoiceData[itemIndex].currency?.value}{' '}
                    {invoiceData ? invoiceData[itemIndex]?.total_child_invoices_gross_amount : '0'}{' '}
                    )
                  </>
                )}
                {parseInt(tcsAmount) > 0 &&
                  ` + TCS Amount ( ${
                    invoiceData && invoiceData[itemIndex].currency?.value
                  } ${tcsAmount} )`}
                {parseInt(tdsAmount) > 0 &&
                  ` - TDS Amount ( ${
                    invoiceData && invoiceData[itemIndex].currency?.value
                  } ${tdsAmount} )`}
              </div>
            </div>
          )}

          <div className={css.paymentData}>
            {paymentFields.map((payment: any, index: number) => {
              return (
                <InvoicePaymentWrapper
                  amount_requested={`invoice.${itemIndex}.payment_infos[${index}].amount_requested`}
                  due_date={`invoice.${itemIndex}.payment_infos[${index}].due_date`}
                  requester_remarks={`invoice.${itemIndex}.payment_infos[${index}].requester_remarks`}
                  payment_display_id={`invoice.${itemIndex}.payment_infos[${index}].payment_display_id`}
                  payer_remarks={`invoice.${itemIndex}.payment_infos[${index}].payer_remarks`}
                  tds_amount={`invoice.${itemIndex}.payment_infos[${index}].tds_amount`}
                  tcs_amount={`invoice.${itemIndex}.payment_infos[${index}].tcs_amount`}
                  additional_charges_or_discounts={`invoice.${itemIndex}.payment_infos[${index}].additional_charges_or_discounts`}
                  itemIndex={itemIndex}
                  invoiceData={invoiceData}
                  key={index}
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                />
              );
            })}
          </div>
          <div className={css.itemErrors}>
            {(errors?.invoice as any)?.[itemIndex]?.invoice_info_id?.message && (
              <IconNode src={Images.exclamation} alt="invoice icon" />
            )}
            <span className={css.helperTextClass}>
              {/* @ts-ignore: Unreachable code error */}
              {errors?.invoice?.[itemIndex]?.invoice_info_id?.message}
            </span>
          </div>

          <div className={css.formActionWrapper}>
            <Button onClick={invoiceCancel} variant="text">
              Cancel
            </Button>
            <Button onClick={saveInvoice}>Save Invoice</Button>
          </div>
        </>
      )}
    </div>
  );
};

interface InvoicePaymentWrapperProps {
  amount_requested: string;
  due_date: string;
  requester_remarks: string;
  payment_display_id: string;
  payer_remarks: string;
  tds_amount: string;
  tcs_amount: string;
  additional_charges_or_discounts: string;
  control: Control<FieldValues, any>;
  getValues: UseFormGetValues<FieldValues>;
  itemIndex: number;
  invoiceData: IInvoice[] | null;
  setValue: UseFormSetValue<FieldValues>;
}

const InvoicePaymentWrapper = (props: InvoicePaymentWrapperProps) => {
  const {
    amount_requested,
    due_date,
    requester_remarks,
    control,
    payer_remarks,
    tds_amount,
    tcs_amount,
    additional_charges_or_discounts,
    payment_display_id,
    getValues,
    itemIndex,
    invoiceData,
    setValue
  } = props;
  const tdsAmount = invoiceData ? invoiceData[itemIndex]?.tds_amount : '0';
  const tcsAmount = invoiceData ? invoiceData[itemIndex]?.tcs_amount : '0';
  const requestorRemarks = getValues(requester_remarks);
  const [amountToPay, setAmountToPay] = useState(0);
  const amountRequestedWatch = useWatch({
    control,
    name: amount_requested
  });

  const tdsAmountWatch = useWatch({
    control,
    name: tds_amount
  });
  const tcsAmountWatch = useWatch({
    control,
    name: tcs_amount
  });
  const additionalChargesOrDiscountsWatch = useWatch({
    control,
    name: additional_charges_or_discounts
  });
  useEffect(() => {
    if (parseFloat(tdsAmount) > 0) {
      if (tdsAmountWatch != null || undefined) {
        return;
      }
    } else if (parseFloat(tcsAmount) > 0) {
      if (tcsAmountWatch != null || undefined) {
        return;
      }
    }
    const remaining_amount =
      parseFloat(invoiceData ? invoiceData[itemIndex]?.amount : '0') +
      parseFloat(invoiceData ? invoiceData[itemIndex]?.gst_amount : '0') -
      parseFloat(invoiceData ? invoiceData[itemIndex]?.total_amount_paid : '0') -
      parseFloat(invoiceData ? invoiceData[itemIndex]?.total_amount_initiated : '0') -
      parseFloat(invoiceData ? invoiceData[itemIndex]?.total_child_invoices_gross_amount : '0');

    const remaining_tds_tcs_amount =
      parseFloat(tdsAmount) > 0
        ? parseFloat(tdsAmount) -
          parseFloat(invoiceData ? invoiceData[itemIndex]?.total_tds_paid : '0') -
          parseFloat(invoiceData ? invoiceData[itemIndex]?.total_tds_initiated : '0')
        : parseFloat(tcsAmount) -
          parseFloat(invoiceData ? invoiceData[itemIndex]?.total_tcs_paid : '0') -
          parseFloat(invoiceData ? invoiceData[itemIndex]?.total_tcs_initiated : '0');

    const TDSorTCS = (
      (parseFloat(amountRequestedWatch) * remaining_tds_tcs_amount) /
      (remaining_amount == 0 ? 1 : remaining_amount)
    ).toFixed(2);

    parseFloat(tdsAmount) > 0 ? setValue(tds_amount, TDSorTCS) : setValue(tcs_amount, TDSorTCS);
    setAmountToPay(
      parseFloat(amountRequestedWatch || '0') -
        parseFloat(tdsAmountWatch || '0') +
        parseFloat(tcsAmountWatch || '0') +
        parseFloat(additionalChargesOrDiscountsWatch || '0')
    );
  }, []);

  useEffect(() => {
    setAmountToPay(
      parseFloat(amountRequestedWatch || '0') -
        parseFloat(tdsAmountWatch || '0') +
        parseFloat(tcsAmountWatch || '0') +
        parseFloat(additionalChargesOrDiscountsWatch || '0')
    );
  }, [amountRequestedWatch, tdsAmountWatch, tcsAmountWatch, additionalChargesOrDiscountsWatch]);

  return (
    <div className={css.invoicePaymentWrapper}>
      <div className={css.paymentHeader}>
        <Typography variant="label">Payment Id</Typography>
        <Typography variant="p" className={css.payment}>
          {getValues(payment_display_id)}
        </Typography>
      </div>
      <div className={css.rowWrapper}>
        <Controller
          name={amount_requested}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              enterKeyHint="next"
              required
              value={field.value || ''}
              autoComplete="off"
              label="Amount Requested"
              placeholder="Start typing"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />}
            />
          )}
        />
        {parseInt(tdsAmount) > 0 && (
          <Controller
            name={tds_amount}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                enterKeyHint="next"
                value={field.value || ''}
                autoComplete="off"
                label="TDS Amount"
                placeholder="Start typing"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                }
              />
            )}
          />
        )}
        {parseInt(tcsAmount) > 0 && (
          <Controller
            name={tcs_amount}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                enterKeyHint="next"
                value={field.value || ''}
                autoComplete="off"
                label="TCS Amount"
                placeholder="Start typing"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                }
              />
            )}
          />
        )}
        <Controller
          name={additional_charges_or_discounts}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              enterKeyHint="next"
              value={field.value || ''}
              autoComplete="off"
              label="Additional Charges or Discounts"
              placeholder="Start typing"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />}
            />
          )}
        />
      </div>
      <TextField
        enterKeyHint="next"
        disabled
        value={amountToPay}
        autoComplete="off"
        label="Amount To Pay"
        placeholder="Start typing"
      />
      <div className={css.rowWrapper}>
        <Controller
          name={due_date}
          control={control}
          render={({ field, fieldState }) => (
            <InputDatePicker
              {...field}
              placeholder="Select date"
              label="Due Date"
              disabled
              onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              rootClassName={css.fieldSpacing}
            />
          )}
        />
        {requestorRemarks && requestorRemarks.length > 0 && (
          <Controller
            name={requester_remarks}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                disabled
                enterKeyHint="next"
                autoComplete="off"
                label="Requester Remarks"
                placeholder="Start typing"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                }
              />
            )}
          />
        )}
        <Controller
          name={payer_remarks}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              enterKeyHint="next"
              autoComplete="off"
              label="Payer Remarks"
              placeholder="Start typing"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />}
            />
          )}
        />
      </div>
    </div>
  );
};
