import { Chip, Modal, ToolTip } from '@components/base';
import css from './index.module.scss';
import Typography from '@components/base/typography';
import IconNode from '@components/base/icon-node';
import Images from '@assets/images';
import Button from '@components/base/button';
import { getFormattedDate, openDocumentSignedLink, sanitizeValue } from '@helpers/utils';
import { useCallback, useMemo } from 'react';
import { DragDropUpload, InputDatePicker, TextField } from '@components/common';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useEffect } from 'react';
import notify from '@helpers/toastify-helper';
import { completePayment, deleteInitiatedPayment } from '@services/finance.service';
import { yupResolver } from '@hookform/resolvers/yup';
import { completePaymentSchema } from '@helpers/yup/finance.schema';
import moment from 'moment';

const groupByBank = (invoices: any) => {
  const transformedData: any = {};
  try {
    invoices.forEach((invoice: any) => {
      const { supplier_bank_detail, supplier_name, currency, ...invoiceData } = invoice;
      const { bank_id = '', account_number = '' } = supplier_bank_detail;

      const key = `${bank_id}-${account_number}`;

      if (!transformedData[key]) {
        transformedData[key] = {
          supplier_bank_id: bank_id,
          supplier_bank_account_number: account_number,
          supplier_name: supplier_name,
          currency: currency,
          invoice_data: []
        };
      }
      transformedData[key].invoice_data.push(invoiceData);
    });
  } catch (err) {
    console.log(err);
  }

  return Object.values(transformedData);
};

const CompletePayment = (props: any) => {
  const { open, onClose, data, contentType, fetchPaymentTableData, handleChipClick, setData } =
    props;

  const groupedBankData = useMemo(() => groupByBank(data?.invoice_details), [data]);

  const bankForm = useForm<any>({
    resolver: yupResolver(completePaymentSchema),
    defaultValues: {
      bank_details: []
    }
  });

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { isSubmitting }
  } = bankForm;

  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'bank_details'
  });

  useEffect(() => {
    if (groupedBankData && fields?.length === 0) {
      const formDataCopy: any = [];
      groupedBankData.forEach((bank: any) => {
        formDataCopy.push({
          supplier_bank_id: bank?.supplier_bank_id,
          supplier_bank_account_number: bank?.supplier_bank_account_number,
          supplier_name: bank?.supplier_name,
          invoice_details: bank?.invoice_data,
          payment_date: null,
          remarks: '',
          reference_number: '',
          currency: bank?.currency,
          payment_info_ids: bank?.invoice_data.map((invoice: any) => invoice?.payment_id),
          document: ''
        });
      });
      append(formDataCopy);
    }
  }, [groupByBank]);

  const formSubmit = async (formData: any) => {
    const data = new FormData();
    const { bank_details } = formData;
    const payment_confirmation_data: any = [];
    bank_details.forEach((item: any) => {
      payment_confirmation_data.push({
        amount_paid: item?.invoice_details?.reduce((acc: any, obj: any) => {
          return acc + parseFloat(obj.amount_to_pay); // Convert the string to a number
        }, 0),
        payment_date: moment(item?.payment_date).format('YYYY-MM-DD'),
        reference_number: item?.reference_number,
        remarks: item?.remarks,
        payment_info_ids: item?.payment_info_ids
      });
    });
    const body = {
      payment_confirmation_data
    };
    data.append('payment_confirmation_data', JSON.stringify(payment_confirmation_data));
    bank_details.forEach((item: any) => {
      if (item?.document) {
        data.append(`${item.supplier_bank_account_number}`, item?.document);
      }
    });
    const response = await completePayment(data);
    if (response?.success) {
      notify({
        severity: 'success',
        message: 'Payment Completed Successfully'
      });
      onClose();
      handleChipClick(2);
    } else {
      notify({
        severity: 'error',
        message: response?.error
      });
    }
  };

  const handleInvoicePaymentDelete = async (paymentId: any, index: number) => {
    const response = await deleteInitiatedPayment(paymentId);
    if (response?.success) {
      notify({
        severity: 'success',
        message: 'Invoice Deleted Successfully'
      });

      const currentBankDetails = getValues('bank_details');
      const updatedInvoiceDetails = currentBankDetails[index].invoice_details.filter(
        (invoice: any) => invoice?.payment_id !== paymentId
      );

      update(index, {
        ...currentBankDetails[index],
        invoice_details: updatedInvoiceDetails,
        payment_info_ids: updatedInvoiceDetails.map((inv: any) => inv.payment_id)
      });

      if (data?.invoice_details.length == 1) {
        onClose();
        fetchPaymentTableData();
      }
      if (updatedInvoiceDetails.length === 0) {
        remove(index);
      }

      setData((prevData: any) => ({
        ...prevData,
        invoice_details: prevData.invoice_details.filter(
          (invoice: any) => invoice?.payment_id !== paymentId
        )
      }));
    } else {
      notify({
        severity: 'error',
        message: response?.error ?? 'Something went wrong, Contact Tech Team'
      });
    }
  };

  const paymentDateHandler = (date: Date | undefined, index: number) => {
    fields?.forEach((field: any, i: number) => {
      // Update the first field or other fields without a payment_date
      if (i != 0 && !field?.payment_date) setValue(`bank_details.${i}.payment_date`, date);
    });
  };

  const handleFileDrop = useCallback((file: any[], index) => {
    if (file.length) {
      setValue(`bank_details.${index}.document`, file[0]);
    }
  }, []);

  const handleDeleteFile = useCallback((index) => {
    setValue(`bank_details.${index}.document`, null);
  }, []);

  return (
    <Modal open={open} onClose={onClose}>
      <div className={css.modalWrapper}>
        <section className={css.modalHeader}>
          <Typography variant="h4">
            {contentType === 'form' && `Complete Payment for ${data?.internal_payment_cart_id}`}
            {contentType === 'view' && `View information for ${data?.internal_payment_cart_id} `}
          </Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="button"
            className={css.closeButton}
            onClick={onClose}
          />
        </section>

        <form noValidate onSubmit={handleSubmit(formSubmit)}>
          <div className={css.modalContent}>
            {fields?.map((field: any, index: number) => {
              const documentWatch = watch(`bank_details.${index}.document`);
              return (
                <div className={css.contentWrapper} key={field?.supplier_bank_account_number}>
                  <Typography variant="p">
                    <strong>Bank Account</strong>: {field?.supplier_bank_account_number}
                  </Typography>
                  {contentType == 'form' && (
                    <>
                      <div className={css.rowWrapper1}>
                        <Controller
                          control={control}
                          name={`bank_details.${index}.reference_number`}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              placeholder="Reference Number"
                              label="Reference Number"
                              required
                              error={fieldState.invalid}
                              endIcon={
                                fieldState.invalid && (
                                  <IconNode src={Images.alertError} alt="Error Icon" />
                                )
                              }
                              helperText={fieldState.error?.message}
                              rootClassName={css.inputWrapper}
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name={`bank_details.${index}.payment_date`}
                          render={({ field, fieldState }) => (
                            <InputDatePicker
                              {...field}
                              required
                              label="Payment Date"
                              onSelect={(day: Date | undefined) => {
                                field.onChange(day ?? null);
                                paymentDateHandler(day, index);
                              }}
                              error={fieldState.invalid}
                              helperText={fieldState.error?.message}
                              rootClassName={css.inputWrapper}
                            />
                          )}
                        />
                      </div>
                      <div className={css.rowWrapper1}>
                        <Controller
                          control={control}
                          name={`bank_details.${index}.remarks`}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              placeholder="Enter Remarks"
                              label="Remarks"
                              error={fieldState.invalid}
                              endIcon={
                                fieldState.invalid && (
                                  <IconNode src={Images.alertError} alt="Error Icon" />
                                )
                              }
                              helperText={fieldState.error?.message}
                              rootClassName={css.inputWrapper}
                            />
                          )}
                        />
                      </div>
                      <div className={css.documentRowWrapper}>
                        <Typography variant="pdoc">
                          {documentWatch ? 'Reupload Document' : 'Upload Document'}
                        </Typography>
                        <Controller
                          name={`bank_details.${index}.document`}
                          control={control}
                          render={({ fieldState }) => (
                            <DragDropUpload
                              onDrop={(file) => handleFileDrop(file, index)}
                              error={fieldState.invalid}
                              helperText={fieldState.error?.message}
                              reupload={documentWatch}
                            />
                          )}
                        />
                        {documentWatch && (
                          <div className={css.fileUploadState}>
                            <Typography variant="p" className={css.fileName}>
                              {documentWatch.name}
                            </Typography>
                            <IconNode
                              alt="delete icon"
                              component="button"
                              src={Images.deleteDark}
                              className={css.deleteButton}
                              onClick={() => handleDeleteFile(index)}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  <div className={css.dataWrapper} style={{ marginBottom: 10 }}>
                    <Typography variant="label">Total Amount to pay</Typography>
                    <Typography variant="p">
                      {field?.currency}{' '}
                      {field?.invoice_details?.reduce((acc: any, obj: any) => {
                        return acc + parseFloat(obj.amount_to_pay); // Convert the string to a number
                      }, 0)}
                    </Typography>
                  </div>
                  {field?.invoice_details?.map((data: any) => {
                    return (
                      <div className={css.contentWrapper} key={data?.payment_id}>
                        <div className={css.rowWrapper}>
                          <div className={css.dataWrapper}>
                            <Typography variant="label">Payment ID</Typography>
                            <Typography variant="subheading2">
                              {data?.readable_payment_id}
                            </Typography>
                          </div>
                          <div className={css.dataWrapper}>
                            <Typography variant="label">Invoice Date</Typography>
                            <Typography variant="subheading2">
                              {getFormattedDate(data?.invoice_date) ?? '-'}
                            </Typography>
                          </div>
                          <div className={css.dataWrapper}>
                            <Typography variant="label">Invoice Number</Typography>
                            <Typography variant="subheading2">
                              {sanitizeValue(data?.invoice_number)}
                            </Typography>
                          </div>
                          <div className={css.dataWrapper}>
                            <Typography variant="label">Supplier Name</Typography>
                            <Typography variant="subheading2">
                              {sanitizeValue(field?.supplier_name)}
                            </Typography>
                          </div>
                          <div className={css.dataWrapper}>
                            {/* <Typography variant="label">Invoice Link</Typography> <br /> */}
                            {data?.invoice_document ? (
                              <div className={css.viewDelete}>
                                <Button
                                  variant="text"
                                  onClick={() => openDocumentSignedLink(data?.invoice_document)}>
                                  View
                                </Button>
                                <IconNode
                                  src={Images.deleteRed}
                                  alt="Delete Icon"
                                  onClick={() => {
                                    handleInvoicePaymentDelete(data.payment_id, index);
                                  }}
                                />
                              </div>
                            ) : (
                              <Button
                                variant="text"
                                disabled={!data?.invoice_document}
                                onClick={() => openDocumentSignedLink(data?.invoice_document)}>
                                Invoice N.A.
                              </Button>
                            )}
                          </div>
                        </div>
                        <div className={css.rowWrapper}>
                          <div className={css.dataWrapper}>
                            <Typography variant="label">Due Date</Typography>
                            <Typography variant="subheading2">
                              {getFormattedDate(data?.due_date) ?? '-'}
                            </Typography>
                          </div>
                          <div className={css.dataWrapper}>
                            <Typography variant="label">Amount To Pay</Typography>
                            <Typography variant="subheading2">
                              {field?.currency} {data?.amount_to_pay}
                            </Typography>
                          </div>
                          <div className={css.dataWrapper}>
                            <Typography variant="label">Requester Remarks</Typography>
                            <Typography variant="subheading2">
                              {data?.requester_remarks?.length ? (
                                <ToolTip title={data?.requester_remarks ?? 'Not Available'}>
                                  <div>
                                    <Chip label="View" />
                                  </div>
                                </ToolTip>
                              ) : (
                                '-'
                              )}
                            </Typography>
                          </div>
                          <div className={css.dataWrapper}>
                            <Typography variant="label">Payer Remarks</Typography>
                            <Typography variant="subheading2">
                              {data?.payer_remarks?.length ? (
                                <ToolTip title={data?.payer_remarks ?? 'Not Available'}>
                                  <div>
                                    <Chip label="View" />
                                  </div>
                                </ToolTip>
                              ) : (
                                '-'
                              )}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          {contentType == 'form' && (
            <>
              <section className={css.modalFooter}>
                <Button variant="outlined-secondary" onClick={onClose}>
                  Close
                </Button>
                <Button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Saving' : 'Save'}
                </Button>
              </section>
            </>
          )}
        </form>
      </div>
    </Modal>
  );
};

export default CompletePayment;
