import React, { useEffect, useState } from 'react';
import css from './index.module.scss';
import { BoxContainer, SelectLabel, TextAreaLabel } from '@components/common';
import Typography from '@components/base/typography';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { generateQuantityFromPackingDetails } from '@helpers/utils';
import { IPackageSchema } from '@helpers/types/packing';
import Button from '@components/base/button';
import IconNode from '@components/base/icon-node';
import Images from '@assets/images';
import TextField from '@components/common/text-field';
import { ISelect } from '@helpers/types';
import { Divider, ToolTip } from '@components/base';
import { OrderItem } from '@helpers/types/order';
import { DEFAULT_AMOUNT_ROUND_OFF } from '@helpers/constants';

interface ItemProps {
  packageTypes: ISelect[];
  unitOfWeights: ISelect[];
  editable: any;
  currency: string;
  orderUnitOfWeight: string;
  buyingPrice: string | null;
  sellingPrice: string | null;
  itemDetails: OrderItem[];
  setDirtyFields: (fields: any) => void;
  dirtyFields: any;
}

const Item = (props: ItemProps) => {
  const {
    packageTypes,
    unitOfWeights,
    editable,
    currency,
    orderUnitOfWeight,
    itemDetails,
    setDirtyFields,
    dirtyFields
  } = props;
  const { packages: packageEditable, custom_items: customItemEditable } = editable;
  const {
    control,
    getValues,
    formState: { dirtyFields: formDirtyFields }
  } = useFormContext();

  const [totalBuyingAmount, setTotalBuyingAmount] = useState('0');
  const [totalSellingAmount, setTotalSellingAmount] = useState('0');

  const { fields: itemFields } = useFieldArray({
    name: `order_item`,
    control
  });

  const {
    fields: customItemFields,
    append: customItemAppend,
    remove: customItemRemove
  } = useFieldArray({
    name: `custom_items`,
    control
  });

  const customItemWatch = useWatch({
    name: `custom_items`,
    control
  });

  useEffect(() => {
    if (formDirtyFields?.custom_items) {
      onCustomItemValueChangeHandler('packages', customItemWatch);
    }
  }, [customItemWatch]);

  const handleAddCustomItemField = () => {
    customItemAppend({
      name: null,
      description: null,
      rate: null,
      quantity: null,
      amount: null
    });
  };

  const watchField = useWatch({
    name: 'order_item',
    control
  });

  const onValueChangeHandler = (type: any, itemId: any, value: any) => {
    if (!type || !itemId) return;
    const existingItem = dirtyFields?.order_item?.find(
      (item: any) => item.order_item_id === itemId
    );
    const modifiedDirtyFields = dirtyFields;
    if (existingItem) {
      const itemIndex = dirtyFields?.order_item?.findIndex(
        (item: any) => item.order_item_id === itemId
      );
      if (type === 'selling_price') {
        modifiedDirtyFields.order_item[itemIndex].selling_price = value;
      } else if (type === 'buying_price') {
        modifiedDirtyFields.order_item[itemIndex].buying_price = value;
      } else if (type === 'packages') {
        if (value?.length) {
          value = value?.map((p: any) => {
            return {
              ...p,
              type: p?.type?.value,
              unit: p?.unit?.value
            };
          });
        }
        modifiedDirtyFields.order_item[itemIndex].packages = value;
      }
      setDirtyFields(modifiedDirtyFields);
    } else {
      if (type === 'selling_price') {
        modifiedDirtyFields.order_item.push({
          order_item_id: itemId,
          selling_price: value
        });
      } else if (type === 'buying_price') {
        modifiedDirtyFields.order_item.push({
          order_item_id: itemId,
          buying_price: value
        });
      } else if (type === 'packages') {
        if (value?.length) {
          value = value?.map((p: any) => {
            return {
              ...p,
              type: p?.type?.value,
              unit: p?.unit?.value
            };
          });
        }
        modifiedDirtyFields.order_item.push({
          order_item_id: itemId,
          packages: value
        });
      }
      setDirtyFields(modifiedDirtyFields);
    }
  };

  const onCustomItemValueChangeHandler = (type: any, value: any) => {
    if (!type || !value) return;
    setDirtyFields((prev: any) => ({
      ...prev,
      custom_items: value
    }));
  };

  useEffect(() => {
    let totalSellingCopy = 0,
      totalBuyingCopy = 0;

    if (watchField?.length) {
      watchField.forEach((item: any, index: number) => {
        totalSellingCopy +=
          Number(item.selling_price ?? 0) * getValues(`order_item.${index}.quantity`);
        totalBuyingCopy +=
          Number(item.buying_price ?? 0) * getValues(`order_item.${index}.quantity`);
      });
    }

    if (customItemWatch?.length) {
      customItemWatch.forEach((item: any) => {
        totalSellingCopy += Number(item.amount ?? 0);
      });
    }
    setTotalBuyingAmount(totalBuyingCopy.toFixed(DEFAULT_AMOUNT_ROUND_OFF).toString());
    setTotalSellingAmount(totalSellingCopy.toFixed(DEFAULT_AMOUNT_ROUND_OFF).toString());
  }, [watchField, customItemWatch]);

  return (
    <BoxContainer title="Item Details" className={css.mainWrapper}>
      <div>
        {itemFields.map((field: any, index: number) => {
          const currentItemDetails = itemDetails[index];
          const currentWatchData = watchField[index];
          return (
            <div key={field?.id} className={css.itemWrapper}>
              <Typography variant="h5" className={css.titleWrapper}>
                {field?.product_name ?? `Item ${index}`} Details
                <span>
                  <ToolTip
                    title="Packaging Details will not be editable once post shipment documents are generated"
                    placement="right">
                    <div>
                      <IconNode src={Images.infoGrey} alt="info icon" />
                    </div>
                  </ToolTip>
                </span>
              </Typography>
              <PackageFields
                index={index}
                quantity={`order_item.${index}.quantity`}
                itemIndex={index}
                unitOfWeights={unitOfWeights}
                packageTypes={packageTypes}
                packageEditable={packageEditable}
                onValueChangeHandler={onValueChangeHandler}
                itemId={currentItemDetails?.order_item_id}
                orderUnitOfWeight={orderUnitOfWeight}
              />
              <Divider />
              <div className={css.rateWrapper}>
                {currentItemDetails?.selling_price && (
                  <Controller
                    name={`order_item.${index}.selling_price`}
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <TextField
                          {...field}
                          required
                          onChange={(e) => {
                            field.onChange(e);
                            onValueChangeHandler(
                              'selling_price',
                              currentItemDetails?.order_item_id,
                              e.target.value
                            );
                          }}
                          type="text"
                          label={`Selling price per ${currentItemDetails?.selling_quantity_unit} (${currentItemDetails?.selling_price_currency})`}
                          disabled={!editable?.selling_price}
                          placeholder="Enter number"
                          rootClassName={`${css.fieldWrapper}`}
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                          endIcon={
                            fieldState.invalid && (
                              <IconNode src={Images.alertError} alt="error icon" />
                            )
                          }
                        />
                      );
                    }}
                  />
                )}

                {currentItemDetails?.buying_price && (
                  <Controller
                    name={`order_item.${index}.buying_price`}
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        required
                        type="text"
                        onChange={(e) => {
                          field.onChange(e);
                          onValueChangeHandler(
                            'buying_price',
                            currentItemDetails?.order_item_id,
                            e.target.value
                          );
                        }}
                        label={`Buying price per ${currentItemDetails?.buying_quantity_unit} (${currentItemDetails?.buying_price_currency})`}
                        disabled={!editable?.buying_price}
                        placeholder="Enter number"
                        rootClassName={`${css.fieldWrapper}`}
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                        endIcon={
                          fieldState.invalid && (
                            <IconNode src={Images.alertError} alt="error icon" />
                          )
                        }
                      />
                    )}
                  />
                )}
                <Controller
                  name={`order_item.${index}.quantity`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      inputMode="decimal"
                      enterKeyHint="next"
                      autoComplete="off"
                      disabled={true}
                      label={`Quantity ${orderUnitOfWeight ? `in ${orderUnitOfWeight}` : ``}`}
                      placeholder="Enter"
                      rootClassName={`${css.fieldWrapper}`}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                      endIcon={
                        fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                      }
                    />
                  )}
                />
                {currentItemDetails?.selling_price && (
                  <TextField
                    label={`Selling Amount (${currentItemDetails?.selling_price_currency})`}
                    disabled={true}
                    rootClassName={`${css.fieldWrapper}`}
                    value={(
                      Number(currentWatchData?.selling_price) *
                      getValues(`order_item.${index}.quantity`)
                    )
                      .toFixed(DEFAULT_AMOUNT_ROUND_OFF)
                      .toString()}
                  />
                )}
                {currentItemDetails?.buying_price && (
                  <TextField
                    label={`Buying Amount (${currentItemDetails?.buying_price_currency})`}
                    disabled={true}
                    rootClassName={`${css.fieldWrapper}`}
                    value={(
                      Number(currentWatchData?.buying_price) *
                      getValues(`order_item.${index}.quantity`)
                    )
                      .toFixed(DEFAULT_AMOUNT_ROUND_OFF)
                      .toString()}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
      {customItemEditable && (
        <div className={css.itemWrapper}>
          <Typography variant="h5" className={css.titleWrapper}>
            Custom Item Details
          </Typography>
          {customItemFields.map((field: any, index: number) => {
            const handleDeleteClick = () => customItemRemove(index);
            return (
              <div key={field?.id}>
                <div className={css.deleteWrapper}>
                  <Typography variant="p" className={css.titleWrapper}>
                    Custom Item {index + 1}
                  </Typography>
                  {
                    <Button
                      variant="text"
                      title="Delete Item"
                      disabled={!customItemEditable}
                      onClick={handleDeleteClick}
                      startIcon={<IconNode src={Images.deleteRed} alt="delete icon" />}>
                      Delete
                    </Button>
                  }
                </div>

                <div className={css.rowWrapper}>
                  <Controller
                    name={`custom_items.${index}.name`}
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        required
                        type="text"
                        label={`Name`}
                        disabled={!customItemEditable}
                        placeholder="Enter Name"
                        rootClassName={`${css.fieldWrapper}`}
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                        endIcon={
                          fieldState.invalid && (
                            <IconNode src={Images.alertError} alt="error icon" />
                          )
                        }
                      />
                    )}
                  />
                </div>
                <div className={css.rowWrapper1}>
                  <Controller
                    name={`custom_items.${index}.quantity`}
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        type="text"
                        label={`Quantity (${orderUnitOfWeight})`}
                        disabled={!customItemEditable}
                        placeholder="Enter Quantity"
                        rootClassName={`${css.fieldWrapper}`}
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                        endIcon={
                          fieldState.invalid && (
                            <IconNode src={Images.alertError} alt="error icon" />
                          )
                        }
                      />
                    )}
                  />
                  <Controller
                    name={`custom_items.${index}.rate`}
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        type="text"
                        label={`Rate (${currency})`}
                        disabled={!customItemEditable}
                        placeholder="Enter Rate"
                        rootClassName={`${css.fieldWrapper}`}
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                        endIcon={
                          fieldState.invalid && (
                            <IconNode src={Images.alertError} alt="error icon" />
                          )
                        }
                      />
                    )}
                  />
                  <Controller
                    name={`custom_items.${index}.amount`}
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        required
                        type="text"
                        label={`Amount (${currency})`}
                        disabled={!customItemEditable}
                        placeholder="Enter Amount"
                        rootClassName={`${css.fieldWrapper}`}
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                        endIcon={
                          fieldState.invalid && (
                            <IconNode src={Images.alertError} alt="error icon" />
                          )
                        }
                      />
                    )}
                  />
                </div>
                <div className={css.rowWrapper1}>
                  <Controller
                    name={`custom_items.${index}.description`}
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextAreaLabel
                        {...field}
                        disabled={!customItemEditable}
                        label="Description"
                        placeholder="Enter description"
                        rows={5}
                        rootClassName={css.fieldSpacing}
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                        endIcon={
                          fieldState.invalid && (
                            <IconNode src={Images.alertError} alt="error icon" />
                          )
                        }
                      />
                    )}
                  />
                </div>
                {customItemFields?.length > 1 && <Divider className={css.divider} />}
              </div>
            );
          })}
          <Button
            variant="text"
            onClick={handleAddCustomItemField}
            disabled={!customItemEditable}
            startIcon={<IconNode src={Images.plusRed} alt="add icon" />}>
            Add custom item
          </Button>
        </div>
      )}
      <div className={css.itemWrapper1}>
        <TextField
          label={`Total Selling Amount (${itemDetails[0]?.selling_price_currency ?? currency})`}
          disabled={true}
          value={totalSellingAmount}
          rootClassName={css.fieldWrapper}
        />
        <TextField
          label={`Total Buying Amount (${itemDetails[0]?.buying_price_currency ?? currency})`}
          disabled={true}
          value={totalBuyingAmount}
          rootClassName={css.fieldWrapper}
        />
      </div>
    </BoxContainer>
  );
};

interface PackageFieldsProps {
  index: number;
  quantity: string;
  itemIndex: number;
  unitOfWeights: ISelect[];
  packageTypes: ISelect[];
  packageEditable: boolean;
  onValueChangeHandler: (type: any, itemId: any, value: any) => void;
  itemId: any;
  orderUnitOfWeight: string;
}

const PackageFields = (props: PackageFieldsProps) => {
  const {
    index,
    quantity,
    unitOfWeights,
    packageTypes,
    itemIndex,
    packageEditable,
    onValueChangeHandler,
    itemId,
    orderUnitOfWeight
  } = props;
  const {
    control,
    setValue,
    formState: { dirtyFields }
  } = useFormContext();

  const packageWatch = useWatch({
    name: `order_item.${index}.packages`,
    control
  });

  useEffect(() => {
    setValue(quantity, generateQuantityFromPackingDetails(packageWatch, orderUnitOfWeight));
  }, [packageWatch, orderUnitOfWeight]);

  useEffect(() => {
    if (dirtyFields?.order_item && dirtyFields?.order_item[itemIndex]?.packages) {
      onValueChangeHandler('packages', itemId, packageWatch);
    }
  }, [packageWatch]);

  const {
    fields: packageFields,
    append: packageAppend,
    remove: packageRemove
  } = useFieldArray({
    name: `order_item.${index}.packages`,
    control
  });

  const handleAddPackageField = () => {
    packageAppend({
      type: null,
      no_of_package: null,
      weight: null,
      unit: null
    });
  };

  return (
    <>
      {packageFields.map((item: any, index: number) => {
        const handleDeleteClick = () => packageRemove(index);
        return (
          <>
            <div className={css.rowWrapper}>
              <Controller
                name={`order_item.${itemIndex}.packages.${index}.no_of_package`}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required
                    type="text"
                    label="No. of Package"
                    disabled={!packageEditable}
                    placeholder="Enter number"
                    rootClassName={`${css.fieldWrapper}`}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    endIcon={
                      fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                    }
                  />
                )}
              />
              <Controller
                name={`order_item.${itemIndex}.packages.${index}.weight`}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required
                    disabled={!packageEditable}
                    inputMode="decimal"
                    type="text"
                    label="Packaging Size"
                    placeholder="Enter size"
                    rootClassName={`${css.fieldWrapper1}`}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    endIcon={
                      fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                    }
                  />
                )}
              />
              <Controller
                name={`order_item.${itemIndex}.packages.${index}.unit`}
                control={control}
                render={({ field, fieldState }) => (
                  <SelectLabel
                    {...field}
                    required
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.value}
                    options={unitOfWeights}
                    isDisabled={!packageEditable}
                    label="Packaging unit"
                    placeholder="Select unit"
                    rootClassName={css.fieldWrapper2}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    isSearchable
                  />
                )}
              />
              <Controller
                name={`order_item.${itemIndex}.packages.${index}.type`}
                control={control}
                render={({ field, fieldState }) => (
                  <SelectLabel
                    {...field}
                    required
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.value}
                    options={packageTypes}
                    isDisabled={!packageEditable}
                    label="Packaging type"
                    placeholder="Select type"
                    rootClassName={css.fieldWrapper3}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    isSearchable
                  />
                )}
              />
              {packageFields?.length > 1 && (
                <Button
                  variant="text"
                  title="Delete Item"
                  disabled={!packageEditable}
                  onClick={handleDeleteClick}
                  startIcon={<IconNode src={Images.deleteRed} alt="delete icon" />}></Button>
              )}
            </div>
          </>
        );
      })}
      <Button
        variant="text"
        onClick={handleAddPackageField}
        disabled={!packageEditable}
        startIcon={<IconNode src={Images.plusRed} alt="add icon" />}>
        Add More Packaging and size
      </Button>
    </>
  );
};

export default Item;
