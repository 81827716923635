import { Chip, Modal, ToolTip } from '@components/base';
import css from './index.module.scss';
import Typography from '@components/base/typography';
import IconNode from '@components/base/icon-node';
import Images from '@assets/images';
import Button from '@components/base/button';
import {
  calculateNetAmount,
  getCommaSeperatedCurrencyAmount,
  getFormattedDate,
  getReadablePaymentTerm,
  openDocumentSignedLink,
  sanitizeValue
} from '@helpers/utils';
import DocumentRow from '@components/common/document-row';
import { useMemo } from 'react';
import { CellProps } from 'react-table';
import { DataGrid, MultiplePlaceholder } from '@components/common';
import { CURRENCY } from '@helpers/constants';

function sortPaymentsByStatus(payments: any) {
  const statusOrder: any = {
    paid: 0,
    initiated: 1,
    requested: 2
  };

  return payments.sort((a: any, b: any) => {
    const statusA: any = a.payment_status.toLowerCase();
    const statusB: any = b.payment_status.toLowerCase();

    return statusOrder[statusA] - statusOrder[statusB];
  });
}

const DetailModal = (props: any) => {
  const { open, onClose, data } = props;
  const formattedInvoiceAmount = getCommaSeperatedCurrencyAmount(data.currency, data?.amount);
  const formattedGSTAmount = getCommaSeperatedCurrencyAmount(data.currency, data?.gst_amount);
  const formattedGrossAmount = getCommaSeperatedCurrencyAmount(
    data.currency,
    data?.gross_invoice_amount
  );
  const formattedNetAmount = getCommaSeperatedCurrencyAmount(
    data.currency,
    calculateNetAmount({
      gross_invoice_amount: parseFloat(data?.gross_invoice_amount ?? '0'),
      tds_amount: parseFloat(data?.tds_amount ?? '0'),
      tcs_amount: parseFloat(data?.tcs_amount ?? '0'),
      additional_charges_or_discounts: parseFloat(data?.additional_charges_or_discounts ?? '0')
    })
  );
  const formattedDueAmount = getCommaSeperatedCurrencyAmount(
    data.currency,
    Number(
      data?.gross_invoice_amount - data?.total_child_invoices_gross_amount - data?.total_amount_paid
    )
  );
  const formattedTDSAmount = getCommaSeperatedCurrencyAmount(data.currency, data?.tds_amount);
  const formattedTCSAmount = getCommaSeperatedCurrencyAmount(data.currency, data?.tcs_amount);
  const formattedTotalChildInvoicesGrossAmount = getCommaSeperatedCurrencyAmount(
    data.currency,
    data?.total_child_invoices_gross_amount
  );

  return (
    <Modal open={open} onClose={onClose}>
      <div className={css.modalWrapper}>
        <section className={css.modalHeader}>
          <Typography variant="h4">Invoice Details of {data?.invoice_number}</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="button"
            className={css.closeButton}
            onClick={onClose}
          />
        </section>

        <section className={css.modalContent}>
          <div className={css.contentWrapper}>
            <div className={css.rowWrapper}>
              <div className={css.dataWrapper}>
                <Typography variant="label">Invoice Date</Typography>
                <Typography variant="p">{getFormattedDate(data?.invoice_date) ?? '-'}</Typography>
              </div>
              <div className={css.dataWrapper}>
                <Typography variant="label">Invoice Number</Typography>
                <Typography variant="p">{sanitizeValue(data?.invoice_number)}</Typography>
              </div>
              <div className={css.dataWrapper}>
                <Typography variant="label">Uploaded On</Typography>
                <Typography variant="p">{getFormattedDate(data?.created_at) ?? '-'}</Typography>
              </div>
            </div>
          </div>

          <div className={css.contentWrapper}>
            <div className={css.rowWrapper}>
              <div className={css.dataWrapper}>
                <Typography variant="label">Invoice Amount</Typography>
                <Typography variant="p">{formattedInvoiceAmount}</Typography>
              </div>
              <div className={css.dataWrapper}>
                <Typography variant="label">GST Amount</Typography>
                <Typography variant="p">{formattedGSTAmount}</Typography>
              </div>
              <div className={css.dataWrapper}>
                <Typography variant="label">Gross Amount</Typography>
                <Typography variant="p">{formattedGrossAmount}</Typography>
              </div>
            </div>

            <div className={css.rowWrapper}>
              <div className={css.dataWrapper}>
                <Typography variant="label">TDS Amount</Typography>
                <Typography variant="p">{formattedTDSAmount}</Typography>
              </div>
              <div className={css.dataWrapper}>
                <Typography variant="label">TCS Amount</Typography>
                <Typography variant="p">{formattedTCSAmount}</Typography>
              </div>
              <div className={css.dataWrapper}>
                <Typography variant="label">Sum Total of other Tax Tnvoices</Typography>
                <Typography variant="p">{formattedTotalChildInvoicesGrossAmount}</Typography>
              </div>
              <div className={css.dataWrapper}>
                <Typography variant="label">Net Amount</Typography>
                <Typography variant="p">{formattedNetAmount}</Typography>
              </div>
            </div>
            <div className={css.rowWrapper}>
              <div className={css.dataWrapper}>
                <Typography variant="label">Due Amount</Typography>
                <Typography variant="p">{formattedDueAmount}</Typography>
              </div>
            </div>
          </div>
          <div className={css.contentWrapper}>
            <div className={css.rowWrapper}>
              <div className={css.dataWrapper}>
                <Typography variant="label">Document Name</Typography>
                <Typography variant="p">{sanitizeValue(data?.name)}</Typography>
              </div>
              <div className={css.dataWrapper}>
                <Typography variant="label">Document Type</Typography>
                <Typography variant="p">{sanitizeValue(data?.document_type_display)}</Typography>
              </div>
              <DocumentRow.View
                showEyeIconWhenDocumentMissing={true}
                document={data?.invoice_document}
                title=""
                openViaSlug={true}
              />
            </div>
          </div>

          {data?.parent_pi_details?.length ? (
            <div className={css.contentWrapper}>
              <Typography variant="pdoc">Attached Proforma Invoice</Typography>
              {data?.parent_pi_details?.length ? (
                <div className={css.dataWrapper}>
                  {data?.parent_pi_details?.map((item: any, index: number) => (
                    <div key={index} className={css.rowWrapper}>
                      <div className={css.dataWrapper}>
                        <Typography variant="label">Invoice Number</Typography>
                        <Typography variant="p">{sanitizeValue(item.invoice_number)}</Typography>
                      </div>
                      <DocumentRow.View
                        showEyeIconWhenDocumentMissing={true}
                        document={item?.invoice_document}
                        title=""
                        openViaSlug={true}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <>-</>
              )}
            </div>
          ) : (
            <></>
          )}

          {data?.child_ti_details?.length ? (
            <div className={css.contentWrapper}>
              <Typography variant="pdoc">Attached Tax Invoice</Typography>
              {data?.child_ti_details?.length ? (
                <div className={css.dataWrapper}>
                  {data?.child_ti_details?.map((item: any, index: number) => (
                    <div key={index} className={css.rowWrapper}>
                      <div className={css.dataWrapper}>
                        <Typography variant="label">Invoice Number</Typography>
                        <Typography variant="p">{sanitizeValue(item.invoice_number)}</Typography>
                      </div>
                      <DocumentRow.View
                        showEyeIconWhenDocumentMissing={true}
                        document={item?.invoice_document}
                        title=""
                        openViaSlug={true}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <>-</>
              )}
            </div>
          ) : (
            <></>
          )}

          <div className={css.contentWrapper}>
            <div className={css.rowWrapper}>
              <div className={css.dataWrapper}>
                <Typography variant="label">Tags</Typography>
                <div className={css.chipGroup}>
                  {data?.internal_tags?.length ? (
                    <div className={css.chipWrapper}>
                      {data?.internal_tags?.map((tag: any) => (
                        <Chip key={tag} label={tag} className={css.chip} />
                      ))}
                    </div>
                  ) : (
                    <>-</>
                  )}
                </div>
              </div>
              <div className={css.dataWrapper}>
                <Typography variant="label">Order Ids</Typography>
                <div className={css.chipGroup}>
                  {data?.order_invoice_payables?.length ? (
                    <div className={css.chipWrapper}>
                      {data?.order_invoice_payables?.map((tag: any) => (
                        <Chip key={tag.id} label={tag.readable_order_id} className={css.chip} />
                      ))}
                    </div>
                  ) : (
                    <>-</>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={css.contentWrapper}>
            <div className={css.rowWrapper}>
              <div className={css.dataWrapper}>
                <Typography variant="label">Payment Term</Typography>
                <Typography variant="p">{data?.payment_terms_display}</Typography>
              </div>
              <div className={css.dataWrapper}>
                <Typography variant="label">Supplier</Typography>
                <Typography variant="p">{sanitizeValue(data?.supplier_name)}</Typography>
              </div>
              <div className={css.dataWrapper}>
                <Typography variant="label">Multiple Tax Invoices?</Typography>
                <Typography variant="p">{data?.has_multiple_tax_invoice ? 'Yes' : 'No'}</Typography>
              </div>

              <div className={css.dataWrapper}>
                <Typography variant="label">Products</Typography>
                <Typography variant="p">
                  {
                    <MultiplePlaceholder
                      names={data?.products?.map((item: any) => item.product_name)}
                    />
                  }
                </Typography>
              </div>
            </div>
          </div>
          <div>
            <PaymentTable data={data?.payment_infos} />
          </div>
        </section>
      </div>
    </Modal>
  );
};

const PaymentTable = (props: any) => {
  const { data } = props;
  const [columnData, rowData] = useMemo(() => {
    const column: Array<any> = [
      {
        Header: 'Payment ID',
        accessor: 'internal_payment_info_id'
      },
      {
        Header: () => (
          <div className={css.sortTableHeader}>
            <ToolTip
              title={`Requested Amount - TDS Amount + TCS Amount + Additional Charges/Discounts`}>
              <div>
                <IconNode src={Images.alertGrey} alt="eye icon" />
              </div>
            </ToolTip>
            <span>Amount</span>
          </div>
        ),
        accessor: 'amount_to_pay',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          const formattedAmountToPay = getCommaSeperatedCurrencyAmount(
            value.currency,
            value?.amount_to_pay
          );

          return (
            <>
              <div className={css.sortTableHeader}>
                <ToolTip
                  title={`${parseFloat(value?.adjusted_amount)} - ${parseFloat(
                    value?.tds_amount
                  )} + ${parseFloat(value?.tcs_amount)} + ${parseFloat(
                    value?.additional_charges_or_discounts
                  )}`}>
                  <div>
                    <IconNode src={Images.alertGrey} alt="eye icon" />
                  </div>
                </ToolTip>
                {formattedAmountToPay}
              </div>
            </>
          );
        }
      },
      {
        Header: 'Due Date',
        accessor: 'due_date'
      },
      {
        Header: 'Payment Status',
        accessor: 'payment_status',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          return (
            <>
              <Chip label={value?.value} variant={value?.chipVariant} />
            </>
          );
        }
      }
    ];

    const row: Array<any> = data?.length
      ? data?.map((item: any) => ({
          internal_payment_info_id: sanitizeValue(item?.internal_payment_info_id),
          amount_to_pay: item,
          due_date: sanitizeValue(getFormattedDate(item?.due_date)),
          supplier_name: sanitizeValue(item?.supplier_name),
          payment_status: {
            value: sanitizeValue(item?.payment_status),
            chipVariant:
              item?.payment_status === 'paid'
                ? 'filled-green'
                : item?.payment_stauts === 'initiated'
                ? 'filled-blue'
                : 'filled-yellow'
          }
        }))
      : [];

    return [column, row];
  }, [data]);
  if (data.length < 1) return null;
  return (
    <>
      <DataGrid columns={columnData} data={rowData} tableStyle={{ width: '100%' }} />
    </>
  );
};

export default DetailModal;
