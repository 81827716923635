import Typography from '@components/base/typography';
import {
  AutoComplete,
  DataGrid,
  DeletePrompt,
  InputDatePicker,
  PaginationScroller,
  PopOverButton,
  SearchBar,
  SelectLabel,
  TableSkeleton
} from '@components/common';
import DocumentRow from '@components/common/document-row';
import ListPlaceholder from '@components/common/list-placeholder';
import notify from '@helpers/toastify-helper';
import {
  calculateNetAmount,
  getCommaSeperatedCurrencyAmount,
  getFormattedDate
} from '@helpers/utils';
import { deleteInvoice, fetchInvoiceList } from '@services/finance.service';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { CellProps } from 'react-table';
import css from './index.module.scss';
import Button from '@components/base/button';
import DetailModal from './detail-modal';
import { TAG_LIMIT_VIEW } from '@helpers/constants';
import { Chip, ToolTip, IconNode, Divider } from '@components/base';
import { useSearchParams } from 'react-router-dom';
import { IInvoiceKeys, PAYMENT_ORDER_BY } from '@pages/finance/home';
import Images from '@assets/images';
import { getSupplierNames } from '@services/supplier.service';
import { fetchEntityList, getReadableOrderIdList } from '@services/order.service';
import { debounce } from 'lodash';
import moment from 'moment';
import UploadOrderDocument from '@components/common/upload-order-document';

interface IOrderList {
  order_id: string;
  readable_order_id: string;
}

interface ISupplierIdName {
  supplier_id: string;
  supplier_name: string;
}

interface IEntity {
  entity_id: string;
  name: string;
}

interface IInvoiceState {
  isLoading: boolean;
  tableData: any;
  detailModalOpen: boolean;
  detailModalData: any;
  currentPageNumber: number;
  itemLimit: number;
  invoiceCount: number;
  currentStatus: number;
  invoice_order_by: string;
  supplierList: ISupplierIdName[];
  orderIdList: IOrderList[];
  entityList: IEntity[];
  editModalToggle: boolean;
  openDeletePrompt: boolean;
  delete_invoice_info_id: string | null;
}

interface IInvoiceProps {
  order_id?: string;
  setInvoiceKeys?: React.Dispatch<React.SetStateAction<IInvoiceKeys>>;
}

interface IFilterState {
  supplier: ISupplierIdName | null;
  orderId: IOrderList | null;
  startDate: Date | null;
  endDate: Date | null;
  searchText: string | null;
  entity: IEntity | null;
  isSearchLoading: boolean;
}

interface IInvoiceTableParams {
  lower_bound_date: string | null;
  order_by: string;
  order_id: string | null;
  page: number;
  search_text: string | null;
  supplier_id: string | null;
  upper_bound_date: string | null;
  entity_id: string | null;
}

const InvoicesTab = (props: IInvoiceProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activePage = searchParams.get('page') || '1';
  const debounceSearch = useRef<any>();

  const [invoicesState, setInvoicesState] = useState<IInvoiceState>({
    isLoading: false,
    tableData: null,
    detailModalOpen: false,
    detailModalData: null,
    currentPageNumber: parseInt(activePage),
    itemLimit: 10,
    invoiceCount: 0,
    currentStatus: 0,
    invoice_order_by: PAYMENT_ORDER_BY.desInvoiceDate,
    supplierList: [],
    orderIdList: [],
    entityList: [],
    editModalToggle: false,
    openDeletePrompt: false,
    delete_invoice_info_id: null
  });

  const [filterState, setFilterState] = useState<IFilterState>({
    supplier: null,
    orderId: null,
    startDate: null,
    endDate: null,
    entity: null,
    searchText: null,
    isSearchLoading: false
  });

  const { supplier, orderId, startDate, endDate, searchText, isSearchLoading, entity } =
    filterState;

  const {
    isLoading,
    tableData,
    detailModalOpen,
    detailModalData,
    currentPageNumber,
    itemLimit,
    invoiceCount,
    currentStatus,
    invoice_order_by,
    supplierList,
    orderIdList,
    entityList,
    editModalToggle,
    openDeletePrompt,
    delete_invoice_info_id
  } = invoicesState;

  const fetchSupplierNames = async () => {
    setInvoicesState((prev: any) => ({ ...prev, isLoading: true }));
    const response = await getSupplierNames();
    if (response?.success) {
      setInvoicesState((prevState: any) => ({
        ...prevState,
        supplierList: response?.data ?? [],
        isLoading: false
      }));
    } else {
      notify({
        severity: 'error',
        message: response?.message
      });
      setInvoicesState((prev: any) => ({ ...prev, isLoading: false }));
    }
  };

  const fetchOrderList = async (event?: any, param?: string) => {
    setInvoicesState((prev: any) => ({ ...prev, isLoading: true }));
    const response = await getReadableOrderIdList(param);
    if (response?.success) {
      setInvoicesState((prevState: any) => ({
        ...prevState,
        orderIdList: response?.data?.results ?? [],
        isLoading: false
      }));
    } else {
      notify({
        severity: 'error',
        message: response?.message
      });
      setInvoicesState((prev: any) => ({ ...prev, isLoading: false }));
    }
  };

  const getEntityList = async () => {
    const response = await fetchEntityList();
    if (response?.success) {
      setInvoicesState((prevState: IInvoiceState) => ({
        ...prevState,
        entityList: response?.data?.results ?? []
      }));
    } else {
      notify({
        severity: 'error',
        message: response?.message
      });
    }
  };

  const fetchInvoiceTableData = async (params: IInvoiceTableParams, order_id?: string) => {
    setInvoicesState((prev: any) => ({ ...prev, isLoading: true }));
    const response = await fetchInvoiceList(params);
    if (response?.success) {
      setInvoicesState((prev: any) => ({
        ...prev,
        tableData: response?.data?.results ?? [],
        invoiceCount: response?.data?.count ?? 0,
        isLoading: false
      }));
      props.setInvoiceKeys &&
        props.setInvoiceKeys((prevState: IInvoiceKeys) => {
          return {
            ...prevState,
            totalAdditionalChargesOrDiscounts:
              response?.data?.total_additional_charges_or_discounts_paid,
            totalTcsPaid: response?.data?.total_tcs_paid,
            totalTdsPaid: response?.data?.total_tds_paid,
            totalGrossAmount: response?.data?.gross_amount,
            totalNetAmount: response?.data?.net_payable,
            totalAmountPaid: response?.data?.total_amount_paid
          };
        });
    } else {
      notify({
        severity: 'error',
        message: response?.message
      });
      setInvoicesState((prev: any) => ({ ...prev, isLoading: false }));
    }
  };

  useEffect(() => {
    fetchSupplierNames();
    fetchOrderList();
    getEntityList();
  }, []);

  useEffect(() => {
    fetchInvoiceTableData({
      page: currentPageNumber,
      order_by: invoice_order_by,
      order_id: orderId?.order_id ? orderId?.order_id : props?.order_id ?? null,
      supplier_id: supplier?.supplier_id ?? null,
      search_text: searchText,
      lower_bound_date: startDate ? `${moment(startDate).format('YYYY-MM-DD')}` : null,
      upper_bound_date: endDate ? `${moment(endDate).format('YYYY-MM-DD')}` : null,
      entity_id: entity?.entity_id ?? null
    });
  }, [
    currentPageNumber,
    currentStatus,
    invoice_order_by,
    supplier,
    orderId,
    startDate,
    endDate,
    entity
  ]);

  const sortDocument = (status: number) => {
    switch (status) {
      case 0:
        setInvoicesState((prev: any) => ({
          ...prev,
          invoice_order_by: null,
          currentPageNumber: 1
        }));
        break;
      case 1:
        setInvoicesState((prev: any) => ({
          ...prev,
          invoice_order_by: PAYMENT_ORDER_BY.ascInvoiceDate,
          currentPageNumber: 1
        }));
        break;
      case 2:
        setInvoicesState((prev: any) => ({
          ...prev,
          invoice_order_by: PAYMENT_ORDER_BY.desInvoiceDate,
          currentPageNumber: 1
        }));
        break;
      default:
        setInvoicesState((prev: any) => ({
          ...prev,
          invoice_order_by: null,
          currentPageNumber: 1
        }));
    }

    setInvoicesState((prevState: any) => ({
      ...prevState,
      currentStatus: status
    }));
  };

  const [columnData, rowData] = useMemo(() => {
    const column: Array<any> = [
      {
        Header: () => (
          <div
            className={css.sortTableHeader}
            onClick={() => {
              const newStatus = currentStatus === 0 ? 1 : currentStatus === 1 ? 2 : 0;
              sortDocument(newStatus);
            }}>
            <ToolTip
              title={
                <div>
                  <div>Invoice Number</div>
                  <div>Invoice Date</div>
                </div>
              }>
              <div>
                <IconNode src={Images.alertGrey} alt="eye icon" />
              </div>
            </ToolTip>
            <span>Invoice Details</span>
            <IconNode src={Images.sort} alt="document" width={16} height={16} />
          </div>
        ),
        accessor: 'invoice',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          return (
            <>
              <Typography variant="p">{value?.invoice_number}</Typography>
              <Typography variant="p">{getFormattedDate(value?.invoice_date ?? '')}</Typography>
            </>
          );
        }
      },
      {
        Header: 'Document Type',
        accessor: 'document_type_display'
      },
      {
        Header: 'Supplier',
        accessor: 'supplier'
      },
      {
        Header: 'Document Name',
        accessor: 'name'
      },
      {
        Header: 'Gross Amount',
        accessor: 'gross_amount'
      },
      {
        Header: 'Due Amount',
        accessor: 'due_amount'
      },
      // {
      //   Header: 'Tags',
      //   accessor: 'internal_tags',
      //   Cell: (props: CellProps<any>) => {
      //     const { value } = props;
      //     return (
      //       <div className={css.chipGroup}>
      //         {value?.length ? (
      //           value?.length > TAG_LIMIT_VIEW ? (
      //             <>
      //               {value.slice(0, TAG_LIMIT_VIEW)?.map((tag: any) => (
      //                 <Chip key={tag} label={tag} className={css.chip} />
      //               ))}
      //               <ToolTip title={value.slice(TAG_LIMIT_VIEW).join(', ')} placement="right">
      //                 <div>
      //                   <Chip label={`+ ${value.length - TAG_LIMIT_VIEW}`} className={css.chip} />
      //                 </div>
      //               </ToolTip>
      //             </>
      //           ) : (
      //             value?.map((tag: any) => <Chip key={tag} label={tag} className={css.chip} />)
      //           )
      //         ) : (
      //           <>-</>
      //         )}
      //       </div>
      //     );
      //   }
      // },
      {
        Header: '',
        accessor: 'action',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          return (
            <>
              <DocumentRow.View
                showEyeIconWhenDocumentMissing={true}
                document={value.invoice_document}
                title=""
                openViaSlug
              />
            </>
          );
        }
      },
      {
        Header: '',
        accessor: 'view',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          const { is_editable } = value;
          return (
            <div className={css.viewEditDeleteButton}>
              <Button
                variant="text"
                onClick={() =>
                  setInvoicesState((prevState: any) => ({
                    ...prevState,
                    detailModalOpen: true,
                    detailModalData: value
                  }))
                }>
                View
              </Button>
              <Divider className={css.divider} />
              <IconNode
                src={is_editable ? Images.editRed : Images.editGrey}
                alt="edit icon"
                onClick={() =>
                  is_editable
                    ? setInvoicesState((prevState: any) => ({
                        ...prevState,
                        editModalToggle: true,
                        detailModalData: value
                      }))
                    : null
                }
                className={css.editIcon}
              />
              <IconNode
                src={is_editable ? Images.deleteRed : Images.delete}
                alt="Delete icon"
                onClick={() =>
                  is_editable
                    ? setInvoicesState((prevState: IInvoiceState) => ({
                        ...prevState,
                        openDeletePrompt: true,
                        delete_invoice_info_id: value?.invoice_info_id
                      }))
                    : null
                }
                className={css.deleteIcon}
              />
            </div>
          );
        }
      }
    ];

    const row: Array<any> = tableData?.length
      ? tableData?.map((item: any) => {
          const dueAmount =
            (item?.gross_invoice_amount ?? 0) -
            (item?.total_amount_paid ?? 0) -
            (item?.total_child_invoices_gross_amount ?? 0);

          const formattedGrossAmount = getCommaSeperatedCurrencyAmount(
            item.currency,
            item?.gross_invoice_amount ?? 0
          );
          const formattedDueAmount = getCommaSeperatedCurrencyAmount(item.currency, dueAmount);

          return {
            invoice: item ?? null,
            document_type_display: item?.document_type_display ?? '-',
            name: item?.name ?? '-',
            net_amount_payable: `${item.currency} ${calculateNetAmount({
              gross_invoice_amount: item?.gross_invoice_amount ?? 0,
              tds_amount: item?.tds_amount ?? 0,
              tcs_amount: item?.tcs_amount ?? 0,
              additional_charges_or_discounts: item?.additional_charges_or_discounts ?? 0
            })}`,
            gross_amount: formattedGrossAmount,
            due_amount: formattedDueAmount,
            internal_tags: item.internal_tags?.length ? item.internal_tags : '-',
            action: item ?? null,
            view: item ?? null,
            supplier: item?.supplier_name ?? '-'
          };
        })
      : [];

    return [column, row];
  }, [tableData]);

  const changePage = (newPage: number) => {
    setInvoicesState((prev: any) => ({ ...prev, currentPageNumber: newPage }));
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: newPage.toString()
    });
  };

  const validatePageChange = (event: any, value: number) => {
    if (isNaN(value)) return;
    if (value === 0 || value > Math.ceil(invoiceCount / itemLimit)) return;
    changePage(value);
  };

  const fetchSearchResult = async (searchText: string) => {
    fetchInvoiceTableData({
      page: currentPageNumber,
      order_by: invoice_order_by,
      order_id: orderId?.order_id ? orderId?.order_id : props?.order_id ?? null,
      supplier_id: supplier?.supplier_id ?? null,
      search_text: searchText,
      lower_bound_date: startDate ? `${moment(startDate).format('YYYY-MM-DD')}` : null,
      upper_bound_date: endDate ? `${moment(endDate).format('YYYY-MM-DD')}` : null,
      entity_id: entity?.entity_id ?? null
    });
  };

  const handleOrderSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setFilterState((prevState: any) => ({
      ...prevState,
      searchText: value.trim(),
      isSearchLoading: true
    }));
    debounceSearch.current?.cancel();
    debounceSearch.current = debounce(fetchSearchResult, 800);
    debounceSearch.current(value);
  };

  const deleteInvoiceDocument = async () => {
    const response = await deleteInvoice(delete_invoice_info_id ?? '');
    if (response.success) {
      notify({
        severity: 'success',
        message: response.data
      });
      fetchInvoiceTableData({
        page: currentPageNumber,
        order_by: invoice_order_by,
        order_id: orderId?.order_id ? orderId?.order_id : props?.order_id ?? null,
        supplier_id: supplier?.supplier_id ?? null,
        search_text: searchText,
        lower_bound_date: startDate ? `${moment(startDate).format('YYYY-MM-DD')}` : null,
        upper_bound_date: endDate ? `${moment(endDate).format('YYYY-MM-DD')}` : null,
        entity_id: entity?.entity_id ?? null
      });
    } else if (response.error) {
      notify({
        severity: 'error',
        message: response.error
      });
    }
    setInvoicesState((prevState: any) => ({
      ...prevState,
      openDeletePrompt: false,
      delete_invoice_info_id: null
    }));
  };

  const onClose = () => {
    setInvoicesState((prevState: IInvoiceState) => ({
      ...prevState,
      editModalToggle: false,
      detailModalData: null
    }));
  };

  return (
    <div className={css.tableWrapper}>
      <div className={css.filterWrapper}>
        <SelectLabel
          rootClassName={css.selectLabel}
          label="Entity"
          placeholder="Select Entity"
          isSearchable
          value={entity}
          options={entityList}
          isClearable
          getOptionLabel={(options: any) => options?.name}
          getOptionValue={(options: any) => options?.entity_id_id}
          onChange={(value: any) => {
            setFilterState((prevState: any) => ({
              ...prevState,
              entity: value
            }));
          }}
        />
        <SelectLabel
          rootClassName={css.selectLabel}
          label="Supplier"
          placeholder="Select Supplier"
          isSearchable
          value={supplier}
          options={supplierList}
          isClearable
          getOptionLabel={(options: any) => options?.supplier_name}
          getOptionValue={(options: any) => options?.supplier_id}
          onChange={(value: any) => {
            setFilterState((prevState: any) => ({
              ...prevState,
              supplier: value
            }));
          }}
        />

        {!props?.order_id && (
          <AutoComplete
            label="Order ID"
            options={orderIdList}
            onInputChange={(event: any, param: string) => fetchOrderList(event, param)}
            onInputSelection={(event: any, param: string) =>
              setFilterState((prevState: any) => ({
                ...prevState,
                orderId: param
              }))
            }
            keyOption="readable_order_id"
            getOptionValue={(option: any) => option.readable_order_id}
            placeholder="Search Order"
            width="270px"
          />
        )}
        <div className={css.searchBar}>
          <SearchBar
            isLoading={isLoading}
            placeholder="Search"
            className={css.search}
            onChange={handleOrderSearch}
          />
          <div>
            <ToolTip title={`Search By: Invoice Number`} placement="right">
              <div>
                <IconNode src={Images.alertGrey} alt="eye icon" />
              </div>
            </ToolTip>
          </div>
        </div>
        <InputDatePicker
          label="Start Date"
          value={startDate}
          onSelect={(day: Date | undefined) =>
            setFilterState((prevState: any) => ({
              ...prevState,
              startDate: day
            }))
          }
          rootClassName={css.fieldSpacing}
        />
        <InputDatePicker
          label="End Date"
          value={endDate}
          onSelect={(day: Date | undefined) =>
            setFilterState((prevState: any) => ({
              ...prevState,
              endDate: day
            }))
          }
          rootClassName={css.fieldSpacing}
        />
      </div>
      {isLoading ? (
        <TableSkeleton colsNum={9} rowsNum={10} />
      ) : rowData.length ? (
        <DataGrid columns={columnData} data={rowData} tableStyle={{ width: '100%' }} />
      ) : (
        <ListPlaceholder
          title="No Invoice available"
          supportingText="You have not created any invoice yet."
        />
      )}
      {detailModalOpen && (
        <DetailModal
          data={detailModalData}
          open={detailModalOpen}
          onClose={() =>
            setInvoicesState((prevState: any) => ({
              ...prevState,
              detailModalOpen: false,
              detailModalData: null
            }))
          }
        />
      )}

      {!isLoading && (
        <PaginationScroller
          variant="text"
          defaultPage={1}
          count={invoiceCount}
          pageLimit={itemLimit}
          page={currentPageNumber}
          onChange={validatePageChange}
        />
      )}
      {editModalToggle && (
        <UploadOrderDocument
          open={editModalToggle}
          onClose={onClose}
          orderId={props?.order_id}
          editMode={'USER_GENERATED'}
          documentData={detailModalData}
          getDocumentData={fetchInvoiceTableData}
          uploadType="finance"
        />
      )}
      <DeletePrompt
        open={openDeletePrompt}
        onClose={() =>
          setInvoicesState((prevState: IInvoiceState) => ({
            ...prevState,
            openDeletePrompt: false
          }))
        }
        onConfirm={deleteInvoiceDocument}
      />
    </div>
  );
};

export default InvoicesTab;
