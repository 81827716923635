import { useEffect, useMemo, useState } from 'react';
import css from './index.module.scss';
import { Typography, Modal, IconNode, Button } from '@components/base';
import { useForm, FormProvider } from 'react-hook-form';
import Images from '@assets/images';
import { ISelect, ISelectBoolean } from '@helpers/types';
import {
  getAddDocFilterOptions,
  getOrderItemFilterOptions,
  getPaymentTermsFilterOptions,
  getProformaInvoiceDetails,
  getReadableOrderIdList,
  getSupplierFilterOptions,
  updateOrderOtherDoc,
  uploadOrderOtherDoc
} from '@services/order.service';
import notify from '@helpers/toastify-helper';
import {
  addOtherDocument,
  invoiceSchema,
  orderInvoiceMappingSchema
} from '@helpers/yup/add-document.schema';
import { yupResolver } from '@hookform/resolvers/yup';
import DocumentUpload from './components/document-upload';
import InvoiceUpload from './components/invoice-upload';
import moment from 'moment';
import { paymentTermsValidation } from '@helpers/utils';
import { IPaymentTermsForm } from '@helpers/types/add-order';
import PropTypes from 'prop-types';
import { getSupplierBankDetails } from '@services/supplier.service';
import OrderInvoiceShare from './components/order-invoice-share-mapping';
import {
  getUploadInvoiceFilterOptions,
  updateInvoice,
  uploadInvoice
} from '@services/finance.service';
import DeletePrompt from '../delete-prompt';
import { DOCUMENT_TYPES } from '@helpers/constants';

interface ISupplierIdName {
  supplier_id: string;
  supplier_name: string;
}

interface IReadableOrderId {
  order_id: string;
  readable_order_id: string;
}

interface IDocumentTypeFilterOptions {
  value: number;
  label: string;
  data_requirements: any;
}

interface IInternalTag {
  label: string;
  value: string;
  is_system_defined?: boolean;
}

interface IOrderItem {
  order_item_id: string;
  display_name: string;
  readable_order_id: string;
  order_id: string;
  supplier_id: string;
  supplier_name: string;
}

interface formType {
  type: IDocumentTypeFilterOptions | null;
  internal_tags: Array<IInternalTag>;
  name: string | null;
  document_type: string;
  remarks: string | null;
  document_object: File | null;
  supplier: ISupplierIdName | null;
  order_item: IOrderItem[];
  order_document_id: string | null;
  product_required: boolean;
  supplier_required: boolean;
  edit_mode: boolean;
  reason_for_update: string | null;
  selected_order_ids: IReadableOrderId[] | null;
  uploadType: string;
}

interface UploadDocumentProps {
  open: boolean;
  onClose: () => void;
  editMode?: string | null;
  availableDocumentType?: ISelect[];
  orderId?: string;
  documentData?: IDocumentData;
  getDocumentData?: (filters?: any) => Promise<void>;
  uploadType: string;
}

interface IPaymentTerm {
  days: boolean;
  payment_term: string;
  percentage: boolean;
}

export interface IModalState {
  isLoading: boolean;
  activeStep: number;
  formNumber: number;
  isTaxInvoice: boolean;
  documentUploadData: formType | null;
  paymentTermsOptions: IPaymentTerm[];
  piInvoiceDetails: IPIPaymentInfo[];
  isProductRequired: boolean;
  isSupplierRequired: boolean;
  invoiceFormData: IInvoiceForm | null;
  orderInvoiceMapping: any;
  openDisclaimerPrompt: boolean;
  isSuccessful: boolean;
}

export interface IPIPaymentInfo {
  id: number | null;
  payment_display_id: string | null;
  payment_date: Date | null;
  payment_reference_number: string | null;
  amount_paid: number | null;
  amount_adjusted: number | null;
  is_checked: boolean;
}

interface IPIInvoice {
  pi_document: any;
  pi_invoice_number: string | null;
  pi_invoice_date: Date | null;
  pi_invoice_amount: number | null;
  pi_invoice_payment_terms: string;
  pi_invoice_payment_info: IPIPaymentInfo[];
  pi_child_invoice_gross_amount: number | null;
  pi_remaining_invoice_amount: number | null;
}

export interface ISupplierBankDetail {
  account_number: string;
  bank_account_holder_name: string;
  bank_name: string;
  bank_id: string;
}

export interface IInvoiceForm {
  is_payment_mode: ISelectBoolean | null;
  pi_invoice_details: IPIInvoice;
  invoice_number: string;
  invoice_date: Date | null;
  invoice_currency: ISelect | null;
  invoice_amount: number | null;
  invoice_gst: number | null;
  total_invoice_amount: number | null;
  amount_paid: number | null;
  tds: number | null;
  tcs: number | null;
  amount_remaining: string;
  payment_terms: IPaymentTermsForm[];
  multiple_tax_invoice: boolean;
  supplier_bank_detail: ISupplierBankDetail | null;
}

interface IOrderShare {
  readable_order_id: string;
  share: number;
  order_id: string;
}

interface IOrderAmountShareForm {
  order_ids_to_invoice_share_map: IOrderShare[];
}

export interface IDocumentData {
  document_type_display_value: string;
  type: number;
  internal_tags: IInternalTag[];
  name: string;
  remarks: string;
  supplier: string;
  supplier_name: string;
  products: IOrderItem[];
  order_document_id: string;
  display_name: string;
  order_item: IOrderItem[];
  order_invoice_payables: any;
  document_object: any;
  invoice_info_id: string;
  invoice_number: string;
  invoice_date: Date;
  currency: string;
  amount: number;
  gst_amount: number;
  payment_terms: any;
  tds_amount: number;
  tcs_amount: number;
  has_multiple_tax_invoice: boolean;
  supplier_bank_detail: string;
  internal_tags_fe_display: IInternalTag[];
  invoice_document: string;
}

export interface IUploadOrderDOcumetProps {
  documentTypeFilterOptions: IDocumentTypeFilterOptions[];
  orderItemFilterOptions: IOrderItem[];
  supplierFilterOptions: ISupplierIdName[];
  tagsFilterOptions: ISelect[];
  defaultSupplierOptions: ISupplierIdName[];
  readableOrderIdList: IReadableOrderId[];
  supplierBankOptions: ISupplierBankDetail[];
}

/**
 * Document Name is commented for now in types and in view, but functionality is kept because it might be brought back
 */
const UploadOrderDocument = (props: UploadDocumentProps) => {
  const {
    open,
    onClose,
    editMode,
    orderId = '',
    documentData,
    getDocumentData,
    uploadType
  } = props;

  const [uploadOrderDocumentState, setUploadOrderDocumentState] =
    useState<IUploadOrderDOcumetProps>({
      documentTypeFilterOptions: [],
      orderItemFilterOptions: [],
      supplierFilterOptions: [],
      tagsFilterOptions: [],
      defaultSupplierOptions: [],
      readableOrderIdList: [],
      supplierBankOptions: []
    });

  const {
    documentTypeFilterOptions,
    orderItemFilterOptions,
    supplierFilterOptions,
    tagsFilterOptions,
    supplierBankOptions
  } = uploadOrderDocumentState;

  const [modalState, setModalState] = useState<IModalState>({
    isLoading: false,
    activeStep: 0,
    formNumber: 1,
    isTaxInvoice: false,
    documentUploadData: null,
    invoiceFormData: null,
    orderInvoiceMapping: null,
    paymentTermsOptions: [],
    piInvoiceDetails: [],
    isProductRequired: false,
    isSupplierRequired: false,
    openDisclaimerPrompt: false,
    isSuccessful: false
  });

  const {
    isLoading,
    activeStep,
    formNumber,
    isTaxInvoice,
    paymentTermsOptions,
    piInvoiceDetails,
    documentUploadData,
    invoiceFormData,
    orderInvoiceMapping,
    openDisclaimerPrompt,
    isSuccessful
  } = modalState;

  const uploadFinanceFlow = useMemo(() => uploadType === 'finance', [uploadType]);

  const formMethods = useForm<formType>({
    resolver: yupResolver(addOtherDocument),
    defaultValues: {
      type: null,
      internal_tags: [],
      name: null,
      document_type: 'OTHERS', //This will be deprecated
      remarks: null,
      document_object: null,
      supplier: null,
      order_item: [],
      order_document_id: null,
      product_required: false,
      supplier_required: false,
      edit_mode: false,
      reason_for_update: null,
      selected_order_ids: [],
      uploadType: ''
    },
    shouldUnregister: false
  });

  const invoiceForm = useForm<IInvoiceForm>({
    resolver: yupResolver(invoiceSchema),
    defaultValues: {
      is_payment_mode: null,
      pi_invoice_details: {
        pi_document: null,
        pi_invoice_number: null,
        pi_invoice_date: null,
        pi_invoice_amount: null,
        pi_invoice_payment_terms: '',
        pi_invoice_payment_info: [],
        pi_child_invoice_gross_amount: null,
        pi_remaining_invoice_amount: null
      },
      invoice_number: '',
      invoice_date: null,
      invoice_currency: null,
      invoice_amount: null,
      invoice_gst: null,
      total_invoice_amount: null,
      amount_paid: null,
      tds: null,
      tcs: null,
      amount_remaining: '',
      payment_terms: [
        {
          payment_term: null,
          percentage: null,
          days: null
        }
      ],
      multiple_tax_invoice: false,
      supplier_bank_detail: null
    }
  });

  const orderAmountShareForm = useForm<IOrderAmountShareForm>({
    resolver: yupResolver(orderInvoiceMappingSchema),
    defaultValues: {
      order_ids_to_invoice_share_map: []
    }
  });

  const { setValue: setInvoiceValue } = invoiceForm;
  const { setValue: setFirstFormValue } = formMethods;
  const supplierWatch = formMethods.watch('supplier');
  const orderIdWatch = formMethods.watch('selected_order_ids');
  const docTypeWatch = formMethods.watch('type');

  const handleDocTypeChange = (
    product: boolean,
    supplier: boolean,
    formNumber: number,
    isTaxInvoice: boolean
  ) => {
    setModalState((prevState: IModalState) => ({
      ...prevState,
      isProductRequired: product,
      isSupplierRequired: supplier,
      formNumber: formNumber,
      isTaxInvoice: isTaxInvoice
    }));
  };

  const getPaymentTermsOptions = async () => {
    setModalState((prevState: IModalState) => ({ ...prevState, isLoading: true }));
    const response = await getPaymentTermsFilterOptions();
    if (response?.success) {
      setModalState((prevState: IModalState) => ({
        ...prevState,
        paymentTermsOptions: response.data?.payment_terms_options,
        isLoading: false
      }));
    } else {
      notify({
        severity: 'error',
        title: 'Error',
        message: response.message ?? 'Unable to fetch payment terms'
      });
      setModalState((prevState: IModalState) => ({ ...prevState, isLoading: false }));
    }
  };

  useEffect(() => {
    getFilterOptions();
    getPaymentTermsOptions();
    getOrderItemList();
    uploadFinanceFlow && fetchOrderList();
    uploadType && setFirstFormValue('uploadType', uploadType);
  }, []);

  useEffect(() => {
    if (supplierWatch) {
      fetchSupplierBankDetails();
      getOrderItemList();
    }
  }, [supplierWatch]);

  useEffect(() => {
    if (orderIdWatch?.length && uploadFinanceFlow) {
      getFilterOptions();
      getOrderItemList();
      if (docTypeWatch?.value !== undefined) {
        getSupplierDetails(docTypeWatch.value);
      }
    }
  }, [orderIdWatch]);

  const getSupplierDetails = async (docType: number) => {
    setModalState((prevState: IModalState) => ({ ...prevState, isLoading: true }));
    const response = await getSupplierFilterOptions(
      {
        doc_type: docType
      },
      orderIdWatch?.length
        ? orderIdWatch
        : [
            {
              order_id: orderId
            }
          ]
    );

    if (response?.success) {
      const { data } = response;
      setUploadOrderDocumentState((prevState: IUploadOrderDOcumetProps) => ({
        ...prevState,
        supplierFilterOptions: data,
        defaultSupplierOptions: data
      }));
      setModalState((prevState: IModalState) => ({ ...prevState, isLoading: false }));
    } else if (response?.error) {
      notify({ message: response?.error, severity: 'error' });
      setModalState((prevState: IModalState) => ({ ...prevState, isLoading: false }));
    }
  };

  const getOrderItemList = async () => {
    setModalState((prevState: IModalState) => ({ ...prevState, isLoading: true }));
    const response = await getOrderItemFilterOptions(
      {
        supplier_id: supplierWatch?.supplier_id,
        page_size: 100
      },
      orderIdWatch?.length
        ? orderIdWatch
        : [
            {
              order_id: orderId
            }
          ]
    );

    if (response?.success) {
      const { data } = response;
      setUploadOrderDocumentState((prevState: IUploadOrderDOcumetProps) => ({
        ...prevState,
        orderItemFilterOptions: data.results
        // data.order_item_filter_options
      }));
      setModalState((prevState: IModalState) => ({ ...prevState, isLoading: false }));
    } else if (response?.error) {
      notify({ message: response?.error, severity: 'error' });
      setModalState((prevState: IModalState) => ({ ...prevState, isLoading: false }));
    }
  };

  const getFilterOptions = async () => {
    let response;
    if (uploadFinanceFlow && !orderId) {
      response = await getUploadInvoiceFilterOptions(
        {
          only_invoices: true
        },
        orderIdWatch
      );
    } else if (orderId) {
      response = await getAddDocFilterOptions(orderId);
    } else {
      response = null;
    }
    setModalState((prevState: IModalState) => ({ ...prevState, isLoading: true }));
    if (response?.success) {
      const { data } = response;
      setUploadOrderDocumentState((prevState: IUploadOrderDOcumetProps) => ({
        ...prevState,
        documentTypeFilterOptions: data.document_type_filter_options,
        // orderItemFilterOptions: data.order_item_filter_options,
        tagsFilterOptions: data.internal_tags_filter_options
      }));
      if (documentData) {
        const docType = data.document_type_filter_options.find(
          (item: any) => item.value === documentData?.type
        );
        setFirstFormValue('type', docType || null);
      }
    } else if (response?.error) {
      notify({ message: response?.error ?? 'Unable to fetch filter options', severity: 'error' });
    }
    setModalState((prevState: IModalState) => ({ ...prevState, isLoading: false }));
  };

  const amountAdjustedValidation = (data: IInvoiceForm) => {
    const adjustedPaymentDetails = data?.pi_invoice_details?.pi_invoice_payment_info
      ?.filter((Item: IPIPaymentInfo) => Item.is_checked)
      .map((item: IPIPaymentInfo) => {
        const obj: any = {};
        obj['parent_payment_info_id'] = item.id;
        obj['amount_to_pay'] = item.amount_adjusted;
        return obj;
      });

    const validateAmountAdjusted = adjustedPaymentDetails?.reduce((acc: number, item: any) => {
      return acc + parseFloat(item.amount_to_pay);
    }, 0);
    if (
      data?.pi_invoice_details?.pi_invoice_payment_info &&
      data?.pi_invoice_details?.pi_invoice_payment_info.length > 0 &&
      validateAmountAdjusted !== data?.amount_paid
    ) {
      return {
        error: true,
        message: `Amount adjusted should be equal to amount paid`
      };
    }
  };

  const ConstructFirstFormPayload = (formData: any, data: formType) => {
    if (!formData || !data) return null;
    if (data.document_object) {
      formData.append('document_object', data.document_object as Blob);
    } else if (!editMode) {
      notify({
        title: 'We have little problem',
        message: `Document is required`,
        severity: 'error'
      });
      return;
    }
    if (data?.type) {
      formData.append('type', data.type.value);
    }
    if (data?.name) {
      formData.append('name', data.name);
    }
    if (data?.supplier) {
      formData.append('supplier', data.supplier.supplier_id);
    }
    if (data?.remarks) {
      formData.append('remarks', data.remarks);
    }
    if (data?.order_item) {
      data?.order_item.forEach((item: IOrderItem) => {
        formData.append('products', item.order_item_id);
      });
    }
    if (data?.internal_tags?.length) {
      data?.internal_tags.forEach((tag: IInternalTag) => {
        formData.append('internal_tags', tag.value);
      });
    }
    formData.append('document_type', 'OTHERS');
    if (data?.reason_for_update) {
      formData.append('reason_for_update', data.reason_for_update);
    }

    if (!editMode) {
      formData.append('uploaded_outside_workflow', 'true');
    }
    return formData;
  };

  const ConstructSecondFormPayload = (formData: any, data: IInvoiceForm) => {
    if (!formData || !data) return null;
    if (data.tds != null && data.tds > 0 && data.tcs != null && data?.tcs > 0) {
      notify({
        message: `TDS and TCS cannot be applied together`,
        severity: 'error'
      });
      return null;
    }
    const paymentTermValidation = paymentTermsValidation(data?.payment_terms);
    if (paymentTermValidation?.error) {
      setModalState((prevState: IModalState) => ({ ...prevState, isLoading: false }));
      notify({
        message: paymentTermValidation?.message,
        severity: 'error'
      });
      return null;
    }
    if (data?.multiple_tax_invoice) {
      const amountAdjusted = amountAdjustedValidation(data);
      if (amountAdjusted?.error) {
        setModalState((prevState: IModalState) => ({ ...prevState, isLoading: false }));
        notify({
          message: amountAdjusted?.message,
          severity: 'error'
        });
        return null;
      }
    }

    const adjustedPaymentDetails = data?.pi_invoice_details?.pi_invoice_payment_info
      ?.filter((Item: IPIPaymentInfo) => Item.is_checked)
      .map((item: IPIPaymentInfo) => {
        const obj: any = {};
        const percentage =
          ((item.amount_adjusted ?? 0) / ((data?.invoice_amount ?? 1) + (data?.invoice_gst ?? 0))) *
          100;
        obj['parent_payment_info_id'] = item.id;
        obj['amount_to_pay'] = item.amount_adjusted;
        if (data.tds != null && data.tds > 0) {
          obj['tds_amount'] = ((data.tds * percentage) / 100).toFixed(2);
        } else if (data.tcs != null && data.tcs > 0) {
          obj['tcs_amount'] = ((data.tcs * percentage) / 100).toFixed(2);
        }
        return obj;
      });

    formData.append('invoice_number', data?.invoice_number);
    formData.append('amount', data?.invoice_amount);
    if (data?.invoice_date) {
      const date = `${moment(data?.invoice_date).format('YYYY-MM-DD')}`;
      formData.append('invoice_date', date);
    }
    if (data?.invoice_currency) {
      formData.append('currency', data?.invoice_currency.value);
    }
    if (data?.invoice_gst) {
      formData.append('gst_amount', data?.invoice_gst);
    }
    if (data?.payment_terms) {
      formData.append(
        'payment_terms',
        JSON.stringify(
          data?.payment_terms?.map((item: IPaymentTermsForm) => {
            const obj: any = {};
            obj['payment_term'] = item.payment_term?.payment_term;
            obj['percentage'] = item.percentage;
            if (item.days) {
              obj['days'] = item.days;
            }
            return obj;
          })
        )
      );
    }
    if (data?.pi_invoice_details?.pi_document?.invoice_info_id) {
      formData.append('parent_invoice', data?.pi_invoice_details?.pi_document.invoice_info_id);
    }
    if (data?.multiple_tax_invoice) {
      formData.append('has_multiple_tax_invoice', data?.multiple_tax_invoice);
    }
    if (data.tds != null && data.tds > 0) {
      formData.append('tds_amount', data.tds);
    } else if (data.tcs != null && data.tcs > 0) {
      formData.append('tcs_amount', data.tcs);
    }

    if (
      data?.multiple_tax_invoice &&
      data?.amount_paid !== null &&
      data?.amount_paid > 0 &&
      data?.pi_invoice_details?.pi_invoice_payment_info
    ) {
      formData.append('adjusted_payment_details', JSON.stringify(adjustedPaymentDetails));
    }
    if (data?.supplier_bank_detail) {
      formData.append('supplier_bank_detail', data?.supplier_bank_detail?.bank_id);
    }
    if (orderIdWatch && orderIdWatch.length === 1 && uploadFinanceFlow) {
      const orderId: any = orderIdWatch[0];
      if (orderId) {
        formData.append(
          'order_ids_to_invoice_share_map',
          JSON.stringify({
            [orderId?.order_id]: 100
          })
        );
      }
    }
    return formData;
  };

  const ConstructThirdFormPayload = (formData: any, data: IOrderAmountShareForm) => {
    if (!formData || !data) return null;
    const formattedMappingData: any = {};
    const totalShare = data?.order_ids_to_invoice_share_map?.reduce(
      (acc: number, item: IOrderShare) => acc + item?.share,
      0
    );
    if (totalShare !== 100) {
      notify({
        message: `Total share should be 100`,
        severity: 'error'
      });
      setModalState((prevState: IModalState) => ({ ...prevState, isLoading: false }));
      return null;
    }
    data?.order_ids_to_invoice_share_map?.forEach((item: IOrderShare) => {
      formattedMappingData[`${item.order_id}`] = item?.share;
    });
    formData.append('order_ids_to_invoice_share_map', JSON.stringify(formattedMappingData));
    return formData;
  };

  const handleNext = (data: any) => {
    const modalStateCopy = { ...modalState };
    switch (activeStep) {
      case 0:
        modalStateCopy.documentUploadData = data;
        modalStateCopy.activeStep = activeStep + 1;
        if (data?.selected_order_ids?.length > 1) {
          modalStateCopy.formNumber = 3;
        }
        !isTaxInvoice
          ? //Need to shift this to invoice form
            setInvoiceValue('is_payment_mode', {
              label: 'No',
              value: false
            })
          : null;
        break;
      case 1:
        modalStateCopy.invoiceFormData = data;
        modalStateCopy.activeStep = activeStep + 1;
        {
          const paymentTermValidation = paymentTermsValidation(data?.payment_terms);
          if (paymentTermValidation?.error) {
            notify({
              message: paymentTermValidation?.message,
              severity: 'error'
            });
            return null;
          }
          const amountAdjusted = amountAdjustedValidation(data);
          if (amountAdjusted?.error) {
            notify({
              message: amountAdjusted?.message,
              severity: 'error'
            });
            return;
          }
        }
        break;
      case 2:
        modalStateCopy.orderInvoiceMapping = data;
        modalStateCopy.activeStep = activeStep + 1;
        break;
      default:
        break;
    }

    setModalState(modalStateCopy);
  };

  const onFormSubmit = async (data: any) => {
    setModalState((prevState: IModalState) => ({ ...prevState, isLoading: true }));
    const formData = new FormData();
    let response, firstFormOutput, secondFromOutput, thirdFormOutput;
    let documentTypeFinance = false;
    switch (formNumber) {
      case 1:
        firstFormOutput = ConstructFirstFormPayload(formData, data);
        if (!firstFormOutput) return;
        if (data.order_document_id) {
          response = await updateOrderOtherDoc(orderId, formData, data.order_document_id);
        } else {
          response = await uploadOrderOtherDoc(orderId, formData);
        }
        break;
      case 2:
        if (documentUploadData) {
          firstFormOutput = ConstructFirstFormPayload(formData, documentUploadData);
        }
        secondFromOutput = ConstructSecondFormPayload(formData, data);
        documentTypeFinance = DOCUMENT_TYPES.includes(
          documentUploadData?.type?.data_requirements.doc_type
        );
        if (!firstFormOutput || !secondFromOutput) return;
        if (uploadFinanceFlow) {
          documentData?.invoice_info_id
            ? (response = await updateInvoice(documentData?.invoice_info_id, formData))
            : (response = await uploadInvoice(formData));
        } else {
          response = await uploadOrderOtherDoc(orderId, formData);
        }
        break;
      case 3:
        if (documentUploadData) {
          firstFormOutput = ConstructFirstFormPayload(formData, documentUploadData);
        }
        if (invoiceFormData) {
          secondFromOutput = ConstructSecondFormPayload(formData, invoiceFormData);
        }
        thirdFormOutput = ConstructThirdFormPayload(formData, data);
        documentTypeFinance = DOCUMENT_TYPES.includes(
          documentUploadData?.type?.data_requirements.doc_type
        );
        if (!firstFormOutput || !secondFromOutput || !thirdFormOutput) return;

        if (uploadFinanceFlow) {
          documentData?.invoice_info_id
            ? (response = await updateInvoice(documentData?.invoice_info_id, formData))
            : (response = await uploadInvoice(formData));
        } else {
          response = await uploadOrderOtherDoc(orderId, formData);
        }
        break;
      default:
        break;
    }
    if (response?.success) {
      if (documentTypeFinance && !uploadFinanceFlow) {
        setModalState((prevState: IModalState) => ({ ...prevState, isSuccessful: true }));
      } else {
        notify({
          title: 'Success',
          message: `Document uploaded successfully`,
          severity: 'success'
        });
        onClose();
        getDocumentData && getDocumentData();
      }
    } else {
      if (typeof response?.error != 'string') {
        response.error = `There was an error uploading document, Contact Tech Team`;
      }
      notify({
        title: 'We have little problem',
        message: response?.error,
        severity: 'error'
      });
    }
    setModalState((prevState: IModalState) => ({ ...prevState, isLoading: false }));
  };

  const handleBackClick = () => {
    setModalState((prevState: IModalState) => ({
      ...prevState,
      activeStep: activeStep - 1
    }));
  };
  const getPIDetails = async () => {
    const orderIds =
      documentUploadData?.selected_order_ids && documentUploadData?.selected_order_ids.length > 0
        ? documentUploadData?.selected_order_ids.map((item: IReadableOrderId) => {
            return item.order_id;
          })
        : [orderId];
    setModalState((prevState: IModalState) => ({ ...prevState, isLoading: true }));
    const response = await getProformaInvoiceDetails(
      orderIds,
      documentUploadData?.type?.value ?? 0,
      documentUploadData?.supplier?.supplier_id ?? ''
    );
    if (response?.success) {
      setModalState((prevState: IModalState) => ({
        ...prevState,
        piInvoiceDetails: response.data?.results,
        isLoading: false
      }));
    } else if (response?.error) {
      notify({
        severity: 'error',
        title: 'Error',
        message: response.message ?? 'Unable to fetch proforma invoice details'
      });
      setModalState((prevState: IModalState) => ({ ...prevState, isLoading: false }));
    }
  };

  const handleCancel = () => {
    onClose();
    formMethods.reset();
    invoiceForm.reset();
    orderAmountShareForm.reset();
  };

  const fetchOrderList = async (event?: any, param?: any) => {
    const response = await getReadableOrderIdList(param);
    if (response?.success) {
      setUploadOrderDocumentState((prevState: IUploadOrderDOcumetProps) => ({
        ...prevState,
        readableOrderIdList: response?.data?.results ?? []
      }));
    } else {
      notify({
        severity: 'error',
        message: response?.message
      });
    }
  };

  const fetchSupplierBankDetails = async (showNotification?: boolean) => {
    const response = await getSupplierBankDetails(supplierWatch?.supplier_id ?? '');
    if (response?.success) {
      setUploadOrderDocumentState((prevState: IUploadOrderDOcumetProps) => ({
        ...prevState,
        supplierBankOptions: response?.data ?? []
      }));
      if (documentData) {
        const supplierBankDetails = response?.data.find(
          (item: ISupplierBankDetail) => item.bank_id === documentData?.supplier_bank_detail
        );
        setInvoiceValue('supplier_bank_detail', supplierBankDetails || null);
      }
      {
        showNotification &&
          notify({ message: 'Supplier bank details fetched successfully', severity: 'success' });
      }
      if (documentData) {
        const supplierBankDetails = response?.data.find(
          (item: ISupplierBankDetail) => item.bank_id === documentData?.supplier_bank_detail
        );
        setInvoiceValue('supplier_bank_detail', supplierBankDetails || null);
      }
      {
        showNotification &&
          notify({ message: 'Supplier bank details fetched successfully', severity: 'success' });
      }
    } else {
      notify({
        severity: 'error',
        message: response?.message
      });
    }
  };

  useEffect(() => {
    if (documentData) {
      const resetValues = {
        type:
          documentData?.document_type_display_value && documentData?.type
            ? {
                label: documentData?.document_type_display_value,
                value: documentData?.type
              }
            : null,
        internal_tags:
          (documentData?.internal_tags_fe_display ?? documentData?.internal_tags) || [],
        name: (documentData?.display_name ?? documentData?.name) || null,
        remarks: documentData?.remarks || null,
        supplier:
          documentData?.supplier && documentData?.supplier_name
            ? {
                supplier_id: documentData?.supplier,
                supplier_name: documentData?.supplier_name
              }
            : null,
        order_item:
          documentData?.products?.map((item: any) => ({
            display_name: item.product_name,
            order_item_id: item.order_item_id
          })) || [],
        order_document_id: documentData?.order_document_id || null,
        product_required: false,
        supplier_required: false,
        edit_mode: true,
        reason_for_update: null,
        uploadType: uploadType
      };
      if (uploadType === 'finance') {
        (resetValues as any).selected_order_ids =
          documentData?.order_invoice_payables?.map((item: any) => ({
            order_id: item.order,
            readable_order_id: item.readable_order_id
          })) || null;
      }
      formMethods.reset(resetValues);

      const totalInvoiceAmount = documentData?.amount ? Number(documentData.amount) : 0;
      const invoiceGST = documentData?.gst_amount ? Number(documentData?.gst_amount) : 0;
      const totalAmount = totalInvoiceAmount + invoiceGST;
      invoiceForm.reset({
        is_payment_mode: null,
        pi_invoice_details: {
          pi_document: null,
          pi_invoice_number: null,
          pi_invoice_date: null,
          pi_invoice_amount: null,
          pi_invoice_payment_terms: '',
          pi_invoice_payment_info: [],
          pi_child_invoice_gross_amount: null,
          pi_remaining_invoice_amount: null
        },
        invoice_number: documentData?.invoice_number,
        invoice_date: documentData?.invoice_date,
        invoice_currency: {
          label: documentData?.currency,
          value: documentData?.currency
        },
        invoice_amount: totalInvoiceAmount,
        invoice_gst: invoiceGST,
        total_invoice_amount: totalAmount,
        tds: documentData?.tds_amount,
        tcs: documentData?.tcs_amount,
        multiple_tax_invoice: documentData?.has_multiple_tax_invoice,
        payment_terms: documentData?.payment_terms?.map((item: any) => {
          return {
            payment_term: {
              payment_term: item.payment_term,
              days: item.days ? true : false,
              percentage: item.percentage ? true : false
            },
            percentage: item.percentage,
            days: item.days ? item.days : null
          };
        })
      });

      orderAmountShareForm.reset({
        order_ids_to_invoice_share_map:
          documentData?.order_invoice_payables?.map((item: any) => {
            return {
              order_id: item.order,
              readable_order_id: item.readable_order_id,
              share: item.invoice_share
            };
          }) || []
      });
    }
  }, [documentData]);

  useEffect(() => {
    if (isSuccessful) {
      setTimeout(() => {
        setModalState((prev) => {
          return { ...prev, isSuccessful: false };
        });
        if (onClose) {
          onClose();
          getDocumentData && getDocumentData();
        }
      }, 5000);
    }
  }, [isSuccessful]);

  return (
    <Modal open={open} onClose={handleCancel}>
      <div className={css.modalWrapper}>
        <section className={css.modalHeader}>
          <Typography variant="h4">{editMode ? `Edit` : `Upload`} Document</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="button"
            className={css.closeButton}
            onClick={onClose}
          />
        </section>
        {isSuccessful == false ? (
          <>
            {activeStep === 0 && (
              <FormProvider {...formMethods}>
                <DocumentUpload
                  onFormSubmit={onFormSubmit}
                  editMode={editMode}
                  onClose={onClose}
                  getSupplierDetails={getSupplierDetails}
                  uploadOrderDocumentState={uploadOrderDocumentState}
                  formNumber={formNumber}
                  handleNext={handleNext}
                  modalState={modalState}
                  handleDocTypeChange={handleDocTypeChange}
                  uploadType={uploadType}
                  fetchOrderList={fetchOrderList}
                  uploadFinanceFlow={uploadFinanceFlow}
                  setInvoiceValue={setInvoiceValue}
                  documentData={documentData}
                />
              </FormProvider>
            )}
            {activeStep === 1 && (
              <FormProvider {...invoiceForm}>
                <InvoiceUpload
                  onFormSubmit={onFormSubmit}
                  onClose={onClose}
                  editMode={editMode}
                  paymentTermsOptions={paymentTermsOptions}
                  handleBackClick={handleBackClick}
                  piInvoiceDetails={piInvoiceDetails}
                  isTaxInvoice={isTaxInvoice}
                  handleNext={handleNext}
                  getPIDetails={getPIDetails}
                  showNextForm={(documentUploadData?.selected_order_ids ?? []).length > 1}
                  supplierBankOptions={uploadOrderDocumentState.supplierBankOptions}
                  uploadFinanceFlow={uploadFinanceFlow}
                  supplierId={supplierWatch?.supplier_id}
                  fetchSupplierBankDetails={fetchSupplierBankDetails}
                />
              </FormProvider>
            )}
            {activeStep === 2 && (
              <FormProvider {...orderAmountShareForm}>
                <OrderInvoiceShare
                  onClose={onClose}
                  orderId={orderId}
                  selectedOrderIds={documentUploadData?.selected_order_ids}
                  handleBackClick={handleBackClick}
                  onFormSubmit={onFormSubmit}
                  editMode={editMode}
                />
              </FormProvider>
            )}
          </>
        ) : (
          <div className={css.successMessageContainer}>
            <div className={css.successMessageImageWrapper}>
              <IconNode
                src={Images.solidGreenChecked}
                alt="Success"
                className={css.successMessageImage}
              />
            </div>
            <p className={css.successMessageText}>Document uploaded successfully.</p>
            <Button
              variant="contained"
              color="primary"
              onClick={() => (window.location.href = '/finance?requestPayment=true')}
              className={css.successMessageButton}>
              Request Payment
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

UploadOrderDocument.propTypes = {
  uploadType: PropTypes.oneOf(['finance', 'order_document'])
};

UploadOrderDocument.defaultProps = {
  variant: 'order_document'
};

export default UploadOrderDocument;
