import { useEffect, useState } from 'react';
import css from './index.module.scss';
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  useFieldArray,
  useFormContext,
  UseFormGetValues,
  UseFormReset,
  UseFormSetValue,
  useWatch
} from 'react-hook-form';
import { Button, Chip, IconNode, Typography } from '@components/base';
import { InputDatePicker, SelectLabel, TextField } from '@components/common';
import Images from '@assets/images';
import { IInvoice, IMakePaymentState, IPaymentInfo } from '../..';
import { getFormattedDate, openDocumentSignedLink } from '@helpers/utils';
import { useBeforeUnloadAndNavigate } from '@helpers/hooks';

interface IInvoiceDetailsProps {
  onFormSubmit: () => void;
  onCancelClick: () => void;
  invoiceData: IInvoice[] | null;
  setMakePaymentState: React.Dispatch<React.SetStateAction<IMakePaymentState>>;
}

const InvoiceDetails = (props: IInvoiceDetailsProps) => {
  const { onFormSubmit, onCancelClick, invoiceData, setMakePaymentState } = props;

  const {
    control,
    handleSubmit,
    getValues,
    setError,
    reset,
    setValue,
    clearErrors,
    formState: { errors }
  } = useFormContext();
  useBeforeUnloadAndNavigate();

  const { fields: invoiceFields } = useFieldArray({
    control,
    name: 'invoice'
  });

  const validateInvoice = (itemIndex: number) => {
    const invoiceValues = getValues(`invoice[${itemIndex}]`);
    const { tcs_amount, tds_amount } = invoiceValues;

    if (parseFloat(tds_amount ?? '0') > 0 && parseFloat(tcs_amount ?? '0') > 0) {
      setError(`invoice[${itemIndex}].invoice_info_id`, {
        type: 'manual',
        message: 'Please enter either TDS or TCS, not both.'
      });
      return false;
    } else {
      clearErrors(`invoice[${itemIndex}].invoice_info_id`);
      setMakePaymentState((prevState: IMakePaymentState) => ({
        ...prevState,
        invoiceData:
          prevState?.invoiceData &&
          prevState?.invoiceData?.map((invoice: IInvoice, index: number) => {
            if (index === itemIndex) {
              return invoiceValues;
            }
            return invoice;
          })
      }));
    }

    return true;
  };

  const validateInvoices = () => {
    let isValid = true;
    invoiceFields.forEach((invoice: any, index: number) => {
      if (!validateInvoice(index)) {
        return (isValid = false);
      }
    });
    return isValid;
  };

  return (
    <form
      noValidate
      onSubmit={handleSubmit((data) => {
        if (validateInvoices()) {
          onFormSubmit();
        }
      })}
      className={css.partiesFormWrapper}>
      {invoiceFields.map((invoice: any, index: number) => {
        return (
          <InvoiceComponent
            key={index}
            invoice={invoice}
            itemIndex={index}
            control={control}
            invoiceData={invoiceData}
            getValues={getValues}
            errors={errors}
            validateInvoice={validateInvoice}
            setValue={setValue}
            setMakePaymentState={setMakePaymentState}
            reset={reset}
            saveInvoice={validateInvoice}
          />
        );
      })}
      <div className={css.formActionWrapper}>
        <Button onClick={onCancelClick} variant="text">
          Cancel
        </Button>
        <Button type="submit" variant="outlined-secondary" className={css.saveAndProceedButton}>
          Add Payment Details
        </Button>
      </div>
    </form>
  );
};

export default InvoiceDetails;

interface InvoiceComponentProps {
  invoice: any;
  itemIndex: number;
  control: Control<FieldValues, any>;
  invoiceData: IInvoice[] | null;
  getValues: UseFormGetValues<FieldValues>;
  errors: FieldErrors<FieldValues>;
  validateInvoice: (itemIndex: number) => boolean;
  setValue: UseFormSetValue<FieldValues>;
  setMakePaymentState: React.Dispatch<React.SetStateAction<IMakePaymentState>>;
  reset: UseFormReset<FieldValues>;
  saveInvoice: (itemIndex: number) => void;
}

const InvoiceComponent = (props: InvoiceComponentProps) => {
  const {
    invoice,
    itemIndex,
    control,
    invoiceData,
    getValues,
    errors,
    validateInvoice,
    setValue,
    setMakePaymentState,
    reset,
    saveInvoice
  } = props;

  const watchFields = useWatch({
    control,
    name: [
      `invoice[${itemIndex}].invoice_date`,
      `invoice[${itemIndex}].currency`,
      `invoice[${itemIndex}].amount`,
      `invoice[${itemIndex}].gst_amount`,
      `invoice[${itemIndex}].tds_amount`,
      `invoice[${itemIndex}].tcs_amount`,
      `invoice[${itemIndex}].additional_charges_or_discounts`
    ]
  });

  const invoiceDateWatch = watchFields[0];
  const currencyWatch = watchFields[1];
  const amountWatch = watchFields[2];
  const gstAmountWatch = watchFields[3];
  const tdsAmountWatch = watchFields[4];
  const tcsAmountWatch = watchFields[5];
  const additionalChargesOrDiscountsWatch = watchFields[6];

  useEffect(() => {
    const netAmount =
      parseFloat(amountWatch || '0') +
      parseFloat(gstAmountWatch || '0') +
      parseFloat(additionalChargesOrDiscountsWatch || '0') -
      parseFloat(tdsAmountWatch || '0') +
      parseFloat(tcsAmountWatch || '0');
    setValue(`invoice[${itemIndex}].total_amount_eligible_for_payment`, netAmount);
  }, [
    amountWatch,
    gstAmountWatch,
    tdsAmountWatch,
    tcsAmountWatch,
    additionalChargesOrDiscountsWatch
  ]);
  const [isExpanded, setIsExpanded] = useState(itemIndex === 0);
  const invoiceNumberWatch = useWatch({
    control,
    name: `invoice[${itemIndex}].invoice_number`
  });
  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const invoiceCancel = () => {
    const currentFormData = getValues(`invoice`);
    currentFormData[itemIndex] = invoiceData && invoiceData[itemIndex];
    reset({ invoice: currentFormData });
    handleExpand();
  };

  const handleSaveInvoice = () => {
    saveInvoice(itemIndex);
    handleExpand();
  };

  useEffect(() => {
    const invoiceValue = getValues(`invoice[${itemIndex}]`);
    validateInvoice(itemIndex);
  }, [itemIndex]);

  return (
    <div
      className={(errors?.invoice as any)?.[itemIndex] ? css.accordianError : css.accordian}
      key={itemIndex}>
      <div className={css.accordianHeader} onClick={handleExpand}>
        <div className={css.accordianHeaderDetails}>
          <div className={css.invoiceNumber}>
            {(errors?.invoice as any)?.[itemIndex] && (
              <IconNode src={Images.exclamation} alt="invoice icon" />
            )}
            <Typography
              variant="h4"
              className={
                (errors?.invoice as any)?.[itemIndex] ? css.error : css.invoiceNumberHeader
              }>
              Invoice Number : {invoiceNumberWatch}
            </Typography>
            <IconNode
              src={Images.colouredPdf}
              alt="invoice icon"
              onClick={() => openDocumentSignedLink(invoiceData?.[itemIndex]?.document ?? '')}
            />
          </div>
        </div>

        <div className={css.dateButton}>
          <Chip variant="filled-blue" label={getFormattedDate(invoiceDateWatch)} />
          <Button variant="text" onClick={handleExpand}>
            <IconNode src={isExpanded ? Images.arrowUpBig : Images.arrowDownBig} alt="arrow down" />
          </Button>
        </div>
      </div>
      {isExpanded && (
        <>
          <Controller
            name={`invoice[${itemIndex}].order_ids`}
            control={control}
            render={({ field }) => (
              <SelectLabel
                label="Order Ids"
                options={[]}
                value={field.value}
                isDisabled={true}
                isMulti
                rootClassName={css.selectWrapper}
                placeholder="Select Tag(s)"
              />
            )}
          />
          <div className={css.rowWrapper}>
            <Controller
              name={`invoice[${itemIndex}].document_type_display`}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  disabled
                  enterKeyHint="next"
                  autoComplete="off"
                  label="Document Type"
                  placeholder="Start typing"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                  }
                />
              )}
            />
            <Controller
              name={`invoice[${itemIndex}].supplier_name`}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  disabled
                  enterKeyHint="next"
                  autoComplete="off"
                  label="Supplier Name"
                  placeholder="Start typing"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                  }
                />
              )}
            />
          </div>
          <div className={css.rowWrapper}>
            <Controller
              name={`invoice[${itemIndex}].currency`}
              control={control}
              render={({ field, fieldState }) => (
                <SelectLabel
                  {...field}
                  label="Currency"
                  isDisabled
                  placeholder="Select"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <Controller
              name={`invoice[${itemIndex}].amount`}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  enterKeyHint="next"
                  autoComplete="off"
                  label="Invoice Amount"
                  placeholder="Start typing"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                  }
                />
              )}
            />
            <Controller
              name={`invoice[${itemIndex}].gst_amount`}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  enterKeyHint="next"
                  autoComplete="off"
                  label="GST Amount"
                  placeholder="Start typing"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                  }
                />
              )}
            />
          </div>
          <div className={css.rowWrapper}>
            <Controller
              name={`invoice[${itemIndex}].tds_amount`}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  enterKeyHint="next"
                  required
                  autoComplete="off"
                  label="TDS Amount"
                  placeholder="Start typing"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                  }
                />
              )}
            />
            <Controller
              name={`invoice[${itemIndex}].tcs_amount`}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  enterKeyHint="next"
                  required
                  autoComplete="off"
                  label="TCS Amount"
                  placeholder="Start typing"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                  }
                />
              )}
            />
          </div>
          <div className={css.rowWrapper}>
            <Controller
              name={`invoice[${itemIndex}].total_amount_eligible_for_payment`}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  disabled
                  enterKeyHint="next"
                  autoComplete="off"
                  label="Net Amount"
                  placeholder="Start typing"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                  }
                />
              )}
            />
            <Controller
              name={`invoice[${itemIndex}].payment_terms`}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  disabled
                  enterKeyHint="next"
                  autoComplete="off"
                  label="Payment Terms"
                  placeholder="Start typing"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                  }
                />
              )}
            />
          </div>
          <div className={css.itemErrors}>
            {(errors?.invoice as any)?.[itemIndex]?.invoice_info_id?.message && (
              <IconNode src={Images.exclamation} alt="invoice icon" />
            )}
            <span className={css.helperTextClass}>
              {/* @ts-ignore: Unreachable code error */}
              {errors?.invoice?.[itemIndex]?.invoice_info_id?.message}
            </span>
          </div>

          <div className={css.formActionWrapper}>
            <Button onClick={invoiceCancel} variant="text">
              Cancel
            </Button>
            <Button onClick={handleSaveInvoice}>Save Invoice</Button>
          </div>
        </>
      )}
    </div>
  );
};
