import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import css from './index.module.scss';
import { Tab, Tabs } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { Chip, Typography, Button, IconNode, ToolTip, Divider } from '@components/base';
import PaymentRequested from './components/paymentRequested';
import PaymentIntitated from './components/paymentInitiated';
import PaymentDone from './components/paymentDone';
import { fetchPaymentList } from '@services/finance.service';
import notify from '@helpers/toastify-helper';

import {
  AutoComplete,
  InputDatePicker,
  PaginationScroller,
  SelectLabel,
  Seo
} from '@components/common';
import InvoicesTab from '@components/common/finance/invoice-table';
import { getSupplierNames } from '@services/supplier.service';
import { fetchEntityList, getReadableOrderIdList } from '@services/order.service';
import moment from 'moment';
import RequestPayment from '../request-payment';
import UploadOrderDocument from '@components/common/upload-order-document';
import AccessWrapper from '@authorization/access-wrapper';
import { IPayment } from '@helpers/types/finance';
import {
  getCommaSeperatedCurrencyAmount,
  getCommaSepratedValue,
  sortByUploadedOnNewToOld
} from '@helpers/utils';
import Images from '@assets/images';

export interface IInvoiceKeys {
  totalGrossAmount: any;
  totalNetAmount: any;
  totalNetPayable: any;
  totalAmountPaid: any;
  totalTcsPaid: any;
  totalTdsPaid: any;
  totalAdditionalChargesOrDiscounts: any;
}

const MAIN_STATE_MAPPING = [
  {
    key: 'payments',
    value: 0,
    default: true
  },
  {
    key: 'invoices',
    value: 1
  }
];

export const PAYMENT_ORDER_BY = {
  ascDueDate: 'due_date',
  ascInvoiceDate: 'invoice_date',
  ascPaymentDate: 'payment_date',
  desDueDate: '-due_date',
  desInvoiceDate: '-invoice_date',
  desPaymentDate: '-payment_date',
  desCreatedOn: '-created_at'
};

export const PAYMENT_TAB_STATE_MAPPING = [
  {
    key: 'requested',
    value: 0,
    default: true,
    initialSort: PAYMENT_ORDER_BY.desDueDate
  },
  {
    key: 'initiated',
    value: 1
  },
  {
    key: 'paid',
    value: 2,
    initialSort: PAYMENT_ORDER_BY.desPaymentDate
  }
];

const groupPaymentsByCart = (payments: IPayment[]) => {
  if (!payments) return null;
  const groupedPayments = payments.reduce((acc: any, payment: IPayment) => {
    const cartId = payment?.payment_cart?.internal_payment_cart_id;
    if (!acc[cartId]) {
      acc[cartId] = {
        internal_payment_cart_id: cartId,
        payment_cart_id: payment?.payment_cart?.id,
        created_at: payment?.payment_cart?.created_at,
        created_by: payment?.payment_cart?.created_by,
        currency: payment?.currency,
        total_amount: 0,
        suppliers: new Set(),
        invoice_details: []
      };
    }

    const amountToPay =
      typeof payment.amount_to_pay === 'string'
        ? parseFloat(payment.amount_to_pay)
        : payment.amount_to_pay;

    acc[cartId].total_amount += parseFloat(payment.amount_to_pay);
    acc[cartId].suppliers.add(payment.supplier_name);
    acc[cartId].invoice_details.push({
      ...payment,
      invoice_number: payment.invoice_number,
      invoice_info: payment.invoice_info,
      invoice_document: payment.invoice_document,
      invoice_date: payment.invoice_date,
      supplier_name: payment.supplier_name,
      supplier_bank_detail: payment.supplier_bank_detail,
      payment_id: payment?.id,
      amount_to_pay: amountToPay,
      readable_payment_id: payment?.internal_payment_info_id,
      due_date: payment.due_date
    });

    return acc;
  }, {});

  // Convert the grouped payments object to an array and format the data
  return Object.values(groupedPayments).map((group: any) => ({
    ...group,
    total_amount: group.total_amount.toFixed(2),
    suppliers: Array.from(group.suppliers).join(', ')
  }));
};

const Home = (props: any) => {
  const { actions } = props.modulePermissions;
  const [searchParams, setSearchParams] = useSearchParams();
  const activePage = searchParams.get('page') || '1';
  const activeTabParam = searchParams.get('activeTab');
  const requestPaymentParam = searchParams.get('requestPayment');

  const defaultTab = useMemo(
    () => MAIN_STATE_MAPPING.find((item: any) => item.default) ?? MAIN_STATE_MAPPING[0],
    []
  );

  const [invoiceKeys, setInvoiceKeys] = useState<IInvoiceKeys>({
    totalGrossAmount: 0,
    totalNetAmount: 0,
    totalNetPayable: 0,
    totalAmountPaid: 0,
    totalTcsPaid: 0,
    totalTdsPaid: 0,
    totalAdditionalChargesOrDiscounts: 0
  });

  const {
    totalGrossAmount,
    totalNetAmount,
    totalNetPayable,
    totalAmountPaid,
    totalTcsPaid,
    totalTdsPaid,
    totalAdditionalChargesOrDiscounts
  } = invoiceKeys;

  const [fetchPaymentData, setFetchPaymentData] = useState<((status?: string) => void) | null>(
    null
  );
  const [PaymentChipClick, setPaymentChipClick] = useState<((value: number) => void) | null>(null);

  const handlePaymentChipClick = useCallback(
    (value: number) => {
      if (PaymentChipClick) {
        PaymentChipClick(value);
      }
    },
    [PaymentChipClick]
  );

  const handleFetchPaymentData = useCallback(
    (status?: string) => {
      if (fetchPaymentData) {
        fetchPaymentData(status);
      }
    },
    [fetchPaymentData]
  );

  const getValueByKey = (key: string) => {
    const state = MAIN_STATE_MAPPING.find((item: any) => item.key === key);
    return state ? state?.value : defaultTab.value;
  };

  const initialOrderState = useMemo(() => {
    return getValueByKey(activeTabParam || defaultTab.key);
  }, [activeTabParam]);

  const [homeState, setHomeState] = useState({
    isLoading: false,
    activeTab: getValueByKey(activeTabParam || defaultTab.key),
    requestPaymentModalOpen: requestPaymentParam === 'true',
    addDocumentModalOpen: false,
    currentPage: activePage
  });

  useEffect(() => {
    setHomeState((prev) => ({
      ...prev,
      activeTab: getValueByKey(activeTabParam || defaultTab.key)
    }));
  }, [activeTabParam]);

  const { activeTab, requestPaymentModalOpen, addDocumentModalOpen, currentPage } = homeState;

  const handleTabChange = (_event: SyntheticEvent, newValue: number) => {
    const queryKey = MAIN_STATE_MAPPING.find((state) => state.value === newValue);
    setHomeState({
      ...homeState,
      activeTab: newValue
    });
    setSearchParams({
      ...Object.fromEntries(searchParams),
      activeTab: queryKey?.key ?? defaultTab.key,
      page: currentPage
    });
  };
  return (
    <AccessWrapper show={actions?.read} showUnauthorised>
      <main className={css.mainWrapper}>
        <Seo title="Payables" />
        <div className={css.header}>
          <div className={css.textWrapper}>
            <Typography variant="h2">Payables</Typography>
          </div>
          {activeTab === 1 && (
            <div className={css.invoiceAggregates}>
              <div className={css.invoiceAggregatorWrapper}>
                <div className={css.invoiceAggregatorText}>
                  Total Gross amount
                  <ToolTip title={'Invoice Amount + GST Amount'} placement="right">
                    <div>
                      <IconNode src={Images.alertError} alt="error icon" />
                    </div>
                  </ToolTip>
                </div>
                {Object.entries(totalGrossAmount).length > 0 ? (
                  Object.entries(totalGrossAmount).map(([currency, amount]) => (
                    <div key={currency}>
                      {getCommaSeperatedCurrencyAmount(currency, amount as any)}
                    </div>
                  ))
                ) : (
                  <div>No Data Available</div>
                )}
              </div>
              <Divider className={css.divider} />
              <div className={css.invoiceAggregatorWrapper}>
                <div className={css.invoiceAggregatorText}>
                  Net Amount
                  <ToolTip
                    title={'Gross Amount - TDS + TCS + Additional Charges'}
                    placement="right">
                    <div>
                      <IconNode src={Images.alertError} alt="error icon" />
                    </div>
                  </ToolTip>
                </div>
                {Object.entries(totalNetAmount).length > 0 ? (
                  Object.entries(totalNetAmount).map(([currency, amount]) => (
                    <div key={currency}>
                      {getCommaSeperatedCurrencyAmount(currency, amount as any)}
                    </div>
                  ))
                ) : (
                  <div>No Data Available</div>
                )}
              </div>
              <Divider className={css.divider} />
              <div className={css.invoiceAggregatorWrapper}>
                <ToolTip
                  title={
                    <div className={css.tooltipContent}>
                      <div>
                        Total TDS Paid:{' '}
                        {Object.entries(totalTdsPaid)
                          .filter(([, amount]) => amount !== 0)
                          .map(
                            ([currency, amount]) =>
                              `${getCommaSeperatedCurrencyAmount(currency, amount as any)}`
                          )
                          .join(' + ') || '0'}
                      </div>
                      <div>
                        Total TCS Paid:{' '}
                        {Object.entries(totalTcsPaid)
                          .filter(([, amount]) => amount !== 0)
                          .map(
                            ([currency, amount]) =>
                              `${getCommaSeperatedCurrencyAmount(currency, amount as any)}`
                          )
                          .join(' + ') || '0'}
                      </div>
                      <div>
                        Total Additional Charges/Discounts:{' '}
                        {Object.entries(totalAdditionalChargesOrDiscounts)
                          .filter(([, amount]) => amount !== 0)
                          .map(
                            ([currency, amount]) =>
                              `${getCommaSeperatedCurrencyAmount(currency, amount as any)}`
                          )
                          .join(' + ') || '0'}
                      </div>
                    </div>
                  }
                  placement="right">
                  <div className={css.invoiceAggregatorText}>
                    Amount Paid
                    <IconNode src={Images.alertError} alt="error icon" />
                  </div>
                </ToolTip>
                {Object.entries(totalAmountPaid).length > 0 ? (
                  Object.entries(totalAmountPaid).map(([currency, amount]) => (
                    <div key={currency}>
                      {getCommaSeperatedCurrencyAmount(currency, amount as any)}
                    </div>
                  ))
                ) : (
                  <div>No Data Available</div>
                )}
              </div>
            </div>
          )}
          <div className={css.buttonWrapper}>
            <Button
              onClick={() => {
                setHomeState({
                  ...homeState,
                  addDocumentModalOpen: true
                });
              }}>
              Add Document
            </Button>
            <AccessWrapper show={actions?.create}>
              <Button
                variant="outlined-secondary"
                className={css.requestPaymentButton}
                onClick={() => {
                  setHomeState({
                    ...homeState,
                    requestPaymentModalOpen: true
                  });
                }}>
                Request Payment
              </Button>
            </AccessWrapper>
          </div>
        </div>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Payments" value={0} />
          <Tab label="Invoices" value={1} />
        </Tabs>

        {activeTab === 0 && (
          <PaymentsTab
            setFetchPaymentData={setFetchPaymentData}
            setPaymentChipClick={setPaymentChipClick}
          />
        )}
        {activeTab === 1 && <InvoicesTab setInvoiceKeys={setInvoiceKeys} />}
        {requestPaymentModalOpen && (
          <RequestPayment
            open={requestPaymentModalOpen}
            handleFetchPaymentData={handleFetchPaymentData}
            handlePaymentChipClick={handlePaymentChipClick}
            handleTabChange={handleTabChange}
            onClose={() => {
              setHomeState({
                ...homeState,
                requestPaymentModalOpen: false
              });
            }}
          />
        )}
        {addDocumentModalOpen && (
          <UploadOrderDocument
            open={addDocumentModalOpen}
            onClose={() => {
              setHomeState({
                ...homeState,
                addDocumentModalOpen: false
              });
            }}
            uploadType="finance"
          />
        )}
      </main>
    </AccessWrapper>
  );
};

interface PaymentsTabProps {
  setFetchPaymentData: (value: (status?: string) => void) => void;
  setPaymentChipClick: (value: (value: number) => void) => void;
}

export interface PaymentsTabStateProps {
  isLoading: boolean;
  activeSubTab: number;
  tableData: any;
  currentPageNumber: number;
  itemLimit: number;
  paymentCount: number;
  payment_order_by: string | null;
}

interface FilterStatesProps {
  supplierList: any[];
  orderList: any[];
  selectedSupplier: any;
  selectedOrder: any;
  selectedStartDate: any;
  selectedEndDate: any;
}

interface IEntity {
  entity_id: string;
  name: string;
}

const PaymentsTab = (props: PaymentsTabProps) => {
  const { setFetchPaymentData, setPaymentChipClick } = props;
  const [searchParams, setSearchParams] = useSearchParams();

  const activePage = searchParams.get('page') || '1';
  const activeSubTabParam = searchParams.get('activeSubTab');
  const defaultSubTab = useMemo(
    () =>
      PAYMENT_TAB_STATE_MAPPING.find((item: any) => item.default) ?? PAYMENT_TAB_STATE_MAPPING[0],
    []
  );
  const getValueBySubKey = (key: string) => {
    const state = PAYMENT_TAB_STATE_MAPPING.find((item: any) => item.key === key);
    return state ? state?.value : defaultSubTab.value;
  };
  const initialSubTabState = useMemo(() => {
    return getValueBySubKey(activeSubTabParam || defaultSubTab.key);
  }, [activeSubTabParam]);

  const [paymentsTabState, setPaymentsTabState] = useState<PaymentsTabStateProps>({
    isLoading: false,
    activeSubTab: initialSubTabState,
    tableData: null,
    currentPageNumber: parseInt(activePage),
    itemLimit: 10,
    paymentCount: 0,
    payment_order_by: null
  });

  const [filterStates, setFilterStates] = useState<any>({
    supplierList: [],
    orderList: [],
    entityList: [],
    selectedSupplier: null,
    selectedOrder: null,
    selectedStartDate: null,
    selectedEndDate: null,
    selectedEntity: null
  });

  const {
    isLoading,
    activeSubTab,
    tableData,
    itemLimit,
    paymentCount,
    currentPageNumber,
    payment_order_by
  } = paymentsTabState;

  const {
    supplierList,
    orderList,
    selectedSupplier,
    selectedOrder,
    selectedStartDate,
    selectedEndDate,
    selectedEntity,
    entityList
  } = filterStates;

  const handleChipClick = (newValue: number) => {
    const queryKey = PAYMENT_TAB_STATE_MAPPING.find((state) => state.value === newValue);
    setPaymentsTabState((prev: any) => ({
      ...prev,
      activeSubTab: newValue,
      currentPageNumber: 1,
      payment_order_by: null
    }));
    setSearchParams({
      ...Object.fromEntries(searchParams),
      activeSubTab: queryKey?.key ?? defaultSubTab.key,
      page: '1'
    });
  };

  useEffect(() => {
    fetchPaymentTableData();
  }, [
    activeSubTab,
    selectedSupplier,
    selectedOrder,
    selectedStartDate,
    selectedEndDate,
    currentPageNumber,
    payment_order_by,
    selectedEntity
  ]);

  useEffect(() => {
    fetchSupplierNames();
    fetchOrderList();
    getEntityList();
  }, []);

  const getEntityList = async () => {
    const response = await fetchEntityList();
    if (response?.success) {
      setFilterStates((prevState: any) => ({
        ...prevState,
        entityList: response?.data?.results ?? []
      }));
    } else {
      notify({
        severity: 'error',
        message: response?.message
      });
    }
  };

  const fetchPaymentTableData = async (status?: string) => {
    setPaymentsTabState((prev) => ({ ...prev, isLoading: true }));
    const response = await fetchPaymentList({
      status: status ?? PAYMENT_TAB_STATE_MAPPING?.find((item) => item.value === activeSubTab)?.key,
      supplier: selectedSupplier?.supplier_id ?? null,
      order: selectedOrder?.order_id ?? null,
      entity_id: selectedEntity?.entity_id ?? null,
      lower_bound_date: selectedStartDate
        ? `${moment(selectedStartDate).format('YYYY-MM-DD')}`
        : null,
      upper_bound_date: selectedEndDate ? `${moment(selectedEndDate).format('YYYY-MM-DD')}` : null,
      ...(activeSubTab !== 1 && { page: currentPageNumber }),
      order_by: payment_order_by
        ? payment_order_by
        : PAYMENT_TAB_STATE_MAPPING.find((item) =>
            status ? item.key === status : item.value === activeSubTab
          )?.initialSort
    });

    if (response?.success) {
      setPaymentsTabState({
        ...paymentsTabState,
        tableData: response?.data?.results ?? null,
        isLoading: false,
        paymentCount: response?.data?.count
      });
    } else {
      notify({
        severity: 'error',
        message: response?.message
      });
      setPaymentsTabState((prev) => ({ ...prev, isLoading: false }));
    }
  };

  const fetchSupplierNames = async () => {
    setPaymentsTabState((prev) => ({ ...prev, isLoading: true }));
    const response = await getSupplierNames();
    if (response?.success) {
      setFilterStates((prevState: any) => ({
        ...prevState,
        supplierList: response?.data ?? []
      }));
    } else {
      notify({
        severity: 'error',
        message: response?.message
      });
    }
    setPaymentsTabState((prev) => ({ ...prev, isLoading: false }));
  };

  const fetchOrderList = async (event?: any, param?: any) => {
    setPaymentsTabState((prev) => ({ ...prev, isLoading: true }));
    const response = await getReadableOrderIdList(param);
    if (response?.success) {
      setFilterStates((prevState: any) => ({
        ...prevState,
        orderList: response?.data?.results ?? []
      }));
    } else {
      notify({
        severity: 'error',
        message: response?.message
      });
    }
    setPaymentsTabState((prev) => ({ ...prev, isLoading: false }));
  };

  const changePage = (newPage: number) => {
    setPaymentsTabState((prev) => ({ ...prev, currentPageNumber: newPage }));
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: newPage.toString()
    });
  };

  const validatePageChange = (event: any, value: number) => {
    if (isNaN(value)) return;
    if (value === 0 || value > Math.ceil(paymentCount / itemLimit)) return;
    changePage(value);
  };

  useEffect(() => {
    setFetchPaymentData(() => fetchPaymentTableData);
    setPaymentChipClick(() => handleChipClick);
  }, []);

  return (
    <div className={css.paymentsTabWrapper}>
      <div className={css.chipWrapper}>
        <Chip
          label="Payment Requested"
          variant={activeSubTab == 0 ? `filled` : `outlined`}
          onClick={() => handleChipClick(0)}
        />
        <Chip
          label="Payment Initiated"
          variant={activeSubTab == 1 ? `filled` : `outlined`}
          onClick={() => handleChipClick(1)}
        />
        <Chip
          label="Payment Completed"
          variant={activeSubTab == 2 ? `filled` : `outlined`}
          onClick={() => handleChipClick(2)}
        />
      </div>
      {activeSubTab != 1 && (
        <div className={css.filterWrapper}>
          <SelectLabel
            rootClassName={css.selectLabel}
            label="Entity"
            placeholder="Select Entity"
            isSearchable
            value={selectedEntity}
            options={entityList}
            isClearable
            getOptionLabel={(options: any) => options?.name}
            getOptionValue={(options: any) => options?.entity_id}
            onChange={(value: any) => {
              setFilterStates((prevState: any) => ({
                ...prevState,
                selectedEntity: value
              }));
            }}
          />
          <SelectLabel
            rootClassName={css.selectLabel}
            label="Supplier"
            placeholder="Select Supplier"
            isSearchable
            value={selectedSupplier}
            options={supplierList}
            isClearable
            getOptionLabel={(options: any) => options?.supplier_name}
            getOptionValue={(options: any) => options?.supplier_id}
            onChange={(value: any) => {
              setFilterStates((prevState: any) => ({
                ...prevState,
                selectedSupplier: value
              }));
            }}
          />
          <AutoComplete
            label="Order ID"
            options={orderList}
            onInputChange={(event: any, param: string) => fetchOrderList(event, param)}
            onInputSelection={(event: any, param: string) =>
              setFilterStates((prevState: any) => ({
                ...prevState,
                selectedOrder: param
              }))
            }
            keyOption="readable_order_id"
            getOptionValue={(option: any) => option.readable_order_id}
            placeholder="Search Order"
            width="350px"
          />
          <InputDatePicker
            label="Start Date"
            value={selectedStartDate}
            onSelect={(day: Date | undefined) =>
              setFilterStates((prevState: any) => ({
                ...prevState,
                selectedStartDate: day
              }))
            }
            rootClassName={css.fieldSpacing}
          />
          <InputDatePicker
            label="End Date"
            value={selectedEndDate}
            onSelect={(day: Date | undefined) =>
              setFilterStates((prevState: any) => ({
                ...prevState,
                selectedEndDate: day
              }))
            }
            rootClassName={css.fieldSpacing}
          />
        </div>
      )}

      {activeSubTab === 0 && (
        <PaymentRequested
          data={tableData}
          dataLoading={isLoading}
          setPaymentsTabState={setPaymentsTabState}
          fetchPaymentTableData={fetchPaymentTableData}
          handleChipClick={handleChipClick}
        />
      )}
      {activeSubTab === 1 && (
        <PaymentIntitated
          fetchPaymentTableData={fetchPaymentTableData}
          data={(groupPaymentsByCart(tableData ?? []) ?? []).sort(sortByUploadedOnNewToOld)}
          dataLoading={isLoading}
          handleChipClick={handleChipClick}
        />
      )}
      {activeSubTab === 2 && (
        <PaymentDone
          data={tableData}
          dataLoading={isLoading}
          setPaymentsTabState={setPaymentsTabState}
        />
      )}

      {!isLoading && activeSubTab !== 1 && (
        <PaginationScroller
          variant="text"
          defaultPage={1}
          count={paymentCount}
          pageLimit={itemLimit}
          page={currentPageNumber}
          onChange={validatePageChange}
        />
      )}
    </div>
  );
};

export default Home;
