import { Controller, useFormContext, UseFormSetValue, useWatch } from 'react-hook-form';
import css from './index.module.scss';
import { AutoComplete, DragDropUpload, SelectLabel, TextField } from '@components/common';
import { Button, IconNode, Typography } from '@components/base';
import { useCallback, useEffect } from 'react';
import { Chip, createFilterOptions } from '@mui/material';
import Images from '@assets/images';
import { DOCUMENT_TYPES } from '@helpers/constants';
import { IDocumentData, IInvoiceForm, IModalState, IUploadOrderDOcumetProps } from '../..';

const filter = createFilterOptions<any>();

interface IDocumentUploadProps {
  onFormSubmit: (data: any) => void;
  editMode: string | null | undefined;
  onClose: () => void;
  getSupplierDetails: (docType: number) => void;
  uploadOrderDocumentState: IUploadOrderDOcumetProps;
  formNumber: number;
  handleNext: (data: any) => void;
  modalState: IModalState;
  uploadType: string;
  handleDocTypeChange: (
    isProductRequired: boolean,
    isSupplierRequired: boolean,
    formNumber: number,
    isTaxInvoice: boolean
  ) => void;
  fetchOrderList: (event?: any, param?: any) => Promise<void>;
  uploadFinanceFlow: boolean;
  setInvoiceValue?: UseFormSetValue<IInvoiceForm>;
  documentData?: IDocumentData;
}

const DocumentUpload = (props: IDocumentUploadProps) => {
  const {
    onFormSubmit,
    editMode,
    onClose,
    getSupplierDetails,
    uploadOrderDocumentState,
    formNumber,
    handleNext,
    modalState,
    handleDocTypeChange,
    uploadType,
    fetchOrderList,
    uploadFinanceFlow
  } = props;
  const {
    documentTypeFilterOptions,
    orderItemFilterOptions,
    supplierFilterOptions,
    tagsFilterOptions,
    readableOrderIdList
  } = uploadOrderDocumentState;

  const { isSupplierRequired, isProductRequired } = modalState;

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting }
  } = useFormContext();
  const typeWatch = useWatch({
    name: 'type',
    control: control
  });

  const watch = useWatch({
    name: ['document_object', 'type', 'order_item', 'internal_tags'],
    control: control
  });

  const otherDocumentType = watch[1] && watch[1]?.label?.split(' ')[0] === 'Other';

  const handleFileDrop = useCallback((file: any[]) => {
    if (file.length) {
      setValue('document_object', file[0]);
    }
  }, []);

  const handleDeleteFile = useCallback(() => {
    setValue('document_object', null);
  }, []);

  useEffect(() => {
    if (typeWatch?.data_requirements) {
      const updatedInternalTags = typeWatch?.data_requirements?.tags.map((item: any) => ({
        ...item,
        is_system_defined: true
      }));
      setValue('internal_tags', updatedInternalTags ?? []);
      setValue('product_required', typeWatch?.data_requirements?.order_item ?? false);
      setValue('supplier_required', typeWatch?.data_requirements?.supplier ?? false);
    }
    typeWatch && getSupplierDetails(typeWatch?.value);
    handleDocTypeChange(
      typeWatch?.data_requirements?.order_item,
      typeWatch?.data_requirements?.supplier,
      DOCUMENT_TYPES.includes(typeWatch?.data_requirements?.doc_type) ? 2 : 1,
      typeWatch?.data_requirements?.doc_type === 'Tax Invoice'
    );
  }, [typeWatch]);

  const onInputSelection = (e: any, p: any) => {
    setValue('internal_tags', p);
  };

  const onReadableIdInputSelection = (e: any, p: any) => {
    setValue('selected_order_ids', p);
  };
  return (
    <form
      noValidate
      className={css.formWrapper}
      onSubmit={handleSubmit(formNumber == 1 ? onFormSubmit : handleNext)}>
      <section className={css.modalContent}>
        <div className={css.rowWrapper}>
          {editMode != 'SYSTEM_GENERATED' && (
            <Controller
              name="type"
              control={control}
              render={({ field, fieldState }) => (
                <SelectLabel
                  {...field}
                  required
                  label="Document Type"
                  placeholder="Select one"
                  isSearchable
                  isDisabled={editMode ? true : false}
                  options={documentTypeFilterOptions}
                  rootClassName={css.typeField}
                  getOptionLabel={(option: any) => `${option.label}`}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          )}
          {editMode != 'SYSTEM_GENERATED' && (
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  inputMode="text"
                  required={otherDocumentType}
                  rootClassName={css.typeField}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  placeholder="Start typing"
                  label="Document Name"
                />
              )}
            />
          )}
        </div>
        {uploadFinanceFlow && (
          <Controller
            name="selected_order_ids"
            control={control}
            render={({ field, fieldState }) => (
              <AutoComplete
                {...field}
                required
                multiple
                id="tags-outlined"
                label="Select Order IDs"
                placeholder="Select Multiple"
                options={readableOrderIdList}
                disabled={editMode ? true : false}
                onInputChange={(event: any, param: string) => fetchOrderList(event, param)}
                onInputSelection={onReadableIdInputSelection}
                getOptionLabel={(option: any) => option.readable_order_id}
                getOptionValue={(option: any) => option.order_id}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                rootClassName={css.AutoCompletetypeField}
                keyOption="readable_order_id"
                width="auto"
                renderTags={(tagValue: any, getTagProps: any) =>
                  tagValue.map((option: any, index: any) => (
                    <Chip label={option.readable_order_id} key={index} />
                  ))
                }
              />
            )}
          />
        )}
        <div className={css.rowWrapper}>
          {editMode != 'SYSTEM_GENERATED' && (
            <Controller
              name="order_item"
              control={control}
              render={({ field, fieldState }) => (
                <SelectLabel
                  {...field}
                  label="Select Product"
                  placeholder="Select one"
                  isSearchable
                  isMulti
                  required={isProductRequired}
                  options={orderItemFilterOptions}
                  rootClassName={css.typeField}
                  getOptionLabel={(option: any) => `${option.display_name}`}
                  getOptionValue={(option: any) => option.order_item_id}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          )}
          {editMode != 'SYSTEM_GENERATED' && (
            <Controller
              name="supplier"
              control={control}
              render={({ field, fieldState }) => (
                <SelectLabel
                  {...field}
                  label="Select Supplier"
                  placeholder="Select one"
                  isSearchable
                  required={isSupplierRequired}
                  options={supplierFilterOptions}
                  rootClassName={css.typeField}
                  getOptionLabel={(option: any) => `${option.supplier_name}`}
                  getOptionValue={(option: any) => option.supplier_id}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  onChange={(e: any) => {
                    field.onChange(e);
                    props.setInvoiceValue && props.setInvoiceValue('supplier_bank_detail', null);
                  }}
                />
              )}
            />
          )}
        </div>
        <Controller
          name="internal_tags"
          control={control}
          render={({ field, fieldState }) => (
            <AutoComplete
              {...field}
              multiple
              id="tags-outlined"
              disableClearable
              options={tagsFilterOptions}
              filterOptions={(options: any, params: any) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                const isExisting = options.some((option: any) => inputValue === option.title);
                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    value: inputValue,
                    label: inputValue,
                    is_system_defined: false
                  });
                }
                return filtered;
              }}
              keyOption="label"
              onInputSelection={onInputSelection}
              label="Add Tags"
              width="auto"
              placeholder="Select multiple"
              required={true}
              rootClassName={css.AutoCompletetypeField}
              freeSolo
              renderTags={(tagValue: any, getTagProps: any) =>
                tagValue.map((option: any, index: any) => (
                  <Chip label={option.label} key={index} disabled={option?.is_system_defined} />
                ))
              }
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
            />
          )}
        />

        <Controller
          name="remarks"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              inputMode="text"
              rootClassName={css.typeField}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              placeholder="Start typing"
              label="Remarks"
            />
          )}
        />
        <Controller
          name="document_object"
          control={control}
          render={({ fieldState }) => (
            <DragDropUpload
              onDrop={handleFileDrop}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              reupload={editMode == 'USER_GENERATED' || watch[0]}
            />
          )}
        />

        {(watch[0] || props.documentData) && (
          <div className={css.fileUploadState}>
            <Typography variant="p" className={css.fileName}>
              {watch[0] ? watch[0].name : props.documentData?.invoice_document?.split('/').pop()}
            </Typography>
            <IconNode
              alt="delete icon"
              component="button"
              src={Images.deleteDark}
              className={css.deleteButton}
              onClick={handleDeleteFile}
            />
          </div>
        )}
        {editMode && watch[0] && (
          <Controller
            name="reason_for_update"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required={true}
                label="Reason for Re-Upload"
                placeholder="Please provide a reason for re-uploading the document"
                error={fieldState.invalid}
                rootClassName={css.typeField}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                }
                helperText={fieldState.error?.message}
              />
            )}
          />
        )}
      </section>
      <section className={css.modalFooter}>
        <Button variant="outlined-secondary" onClick={onClose} disabled={isSubmitting}>
          Cancel
        </Button>
        <Button type="submit" disabled={isSubmitting}>
          {formNumber === 1
            ? editMode
              ? isSubmitting
                ? 'Updating'
                : 'Update'
              : isSubmitting
              ? 'Saving'
              : 'Save'
            : 'Next'}
        </Button>
      </section>
    </form>
  );
};

export default DocumentUpload;
